import React from 'react'
import '../../assets/stylesheets/components/shared/tile.scss'

function Tile ({
  tile,
  classname = ''
}) {
  return (
    <div className={`tile-container shadow-box ${classname && classname}`}>
      {tile.image && (
        <div className='tile-icon-container d-flex justify-center'>
          <span className='tile-icon tl-text28 flex-center'>
            <img src={tile.image.src} height='30px' />
          </span>
        </div>
      )}
      {tile.num && (
        <div className='tile-icon-container d-flex justify-center'>
          <span className='tile-text tl-text24 tl-f500 flex-center tl-00feef'>
            {tile.num}
          </span>
        </div>
      )}
      {tile.boldHeading && <div className={`${tile.image ? 'tl-mt28' : ''} tl-text22 tl-fsn tl-f500 tl-lh140 tl-00feef monstreat-heading gradient-text tl-mlr-auto`}>{tile.boldHeading}</div>}
      <div className={`${tile.boldHeading ? 'tl-mt20' : (tile.image ? 'tl-mt40' : 'tl-mt20')} flex-center tl-text16 tile-heading tl-fsn tl-f500 tl-lh140 tl-001233 monstreat-heading`}>{tile.heading}</div>
      <div className='tile-desc tl-mt12 tl-text14 tl-fsn tl-fnormal tl-lh170 tl-69778E'>{tile.description} </div>
      {tile.priceCta && (
        <div className='price-cta-wrapper'>
          <a href={tile.priceCta.link}>
            <span className='tl-58b1e4 tl-f500'>{tile.priceCta.text}</span>
          </a>
        </div>
      )}
      {tile.linkCta && (
        <div className='link-cta tl-mt40 gradient-text'>
          <a href={tile.linkCta.link}>
            <span>{tile.linkCta.text}</span>
            <img src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/careers/know-more.png' />
          </a>
        </div>
      )}
    </div>
  )
}
export default Tile
