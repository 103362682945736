import React, { useState } from 'react'
import Tile from './Tile'
import '../../assets/stylesheets/components/shared/tabs.scss'

function Tabs ({
  heading,
  content
}) {
  const [selectedTabIndex, setSelectedtabIndex] = useState(0)
  const [dataLength, setDataLength] = useState(null)

  const renderTabHeaders = content.map((tab, i) => (
    <div className={`tab-header ${selectedTabIndex === i ? 'active' : ''}`} key={i} onClick={() => { setSelectedtabIndex(i); setDataLength(tab.content.length) }}>
      <img src={tab.image.src} />
      <span className='tl-f500 tl-text16'>{`${tab.heading} (${tab.content.length})`}</span>
    </div>
  ))

  const renderTabContent = content[selectedTabIndex].content.map((tabContent, i) => (
    <Tile key={i} tile={tabContent} />
  ))

  return (
    <div className='tabs-container section connector-container'>
      <div className='container'>
        <div className='tc'>
          <span className='tl-f500 tl-001233 tl-lh140 section-heading'>{heading}</span>
        </div>
        <div className='tabs-wrapper'>
          <div className='tab-header-wrapper'>
            {renderTabHeaders}
          </div>
          <div className='tab-content-wrapper'>
            {dataLength !== 0 ? renderTabContent : <span className='no-jobs'>No Jobs Available!!</span>}
          </div>
        </div>
      </div>
      <div className='right-connector-image'>
        <img height='800px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/left-connector-dark.png' />
      </div>
    </div>
  )
}
export default Tabs
