import React from 'react'
import { useTranslation } from 'react-i18next'
import Footer from './shared/Footer'
import ContactUs from './shared/ContactUs'
import PageHeader from './shared/PageHeader'
import Features from './shared/Features'
import '../assets/stylesheets/components/AboutUs.scss'
import TeamCard from './shared/TeamCard'
import ContentTeamCard from './shared/ContentTeamCard'

function AboutUs () {
  const { t } = useTranslation(['pages'])
  const { pageHeader, features, teamcard } = JSON.parse(t('about-us'))
  return (
    <div className='about-us overflow-hidden'>
      <PageHeader
        heading={pageHeader.heading}
        description={pageHeader.description}
        hideBrands
        shortDesc
      />
      <TeamCard content={teamcard.content} marginClass='tl-mt40' />
      <Features
        heading={features.heading}
        content={features.content}
        squaresWithoutColor
        showBoxShadow
        noSectionHeadingHeight
        updateWidth
      />
      <TeamCard
        heading={teamcard.heading}
        centerHeading
      />
      <ContentTeamCard contentTeam={teamcard.contentTeam} />
      <ContactUs />
      <Footer />
    </div>
  )
}
export default AboutUs
