import React from 'react'
import '../../assets/stylesheets/static-components/privacy-policy.scss'
function TermsOfService () {
  return (
    <div className='main-container'>
      <div className='main-container'>
        <div className='header-container tl-pt12'>
          <a href='https://trustspace.io/'><img height='38px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/TS-Text-Logo.png' alt='TrustSpace' border='0' />
          </a>
        </div>
        <div className='floater-container'>
          <div className='trustspace-terms-section tc gbb gp-text18 fwl'>
            <div className='main-heading fsbr fwr gbs'>
              <h1>Terms of Service</h1>
            </div>
            <div className='term-points-container tl terms-container'>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>1. Vertragsgegenstand; Geltungsbereich</h3>
                <ul>
                  <li className='sub-list'><p><b>1.1</b> Die TrustLane Solutions GmbH bietet Unternehmern i. S. d. § 14 BGB (nachfolgend „Kunden“) den Zugriff und die Nutzung der Plattform, nachfolgend „Security- Plattform“) als Software-as-a-Service-Lösung über das Internet sowie die Möglichkeit zur Speicherung von Daten der Kunden auf Servern, die im Auftrag des Anbieters betrieben werden, an.</p></li>
                  <li className='sub-list'><p><b>1.2</b> Auf der Security-Plattform erhält der Kunde die Möglichkeit, die IT-Sicherheit seines Unternehmens im Rahmen eines Informationssicherheits-Managementsystem (ISMS) dem aktuellen Stand der Technik entsprechend systematisch und strukturiert zu erfassen, zu verwalten und zu überwachen. Darüber hinaus bietet der Anbieter dem Kunden Beratung in der Anwendung der Security-Plattform und der Ausgestaltung der Maßnahmen zur Datensicherheit. Kunden, ihre Mitarbeiter und Mitarbeiterinnen erhalten außerdem die Möglichkeit zum Zugriff auf (kostenpflichtige) Schulungsinhalte.</p> </li>
                  <li className='sub-list'><p><b>1.3</b> Die Leistungen des Anbieters bestehen insbesondere aus den in den Leistungsbeschreibungen aufgelisteten Funktionen, die unter 2.2 aufgeführt sind und von Kunden modular gebucht werden können.</p></li>
                  <li className='sub-list'><p><b>1.4</b> Diese Allgemeinen Geschäfts- und Nutzungsbedingungen („AGB“) regeln das Verhältnis zwischen dem Anbieter und den Kunden, die Leistungen des Anbieters in Anspruch nehmen. Diese AGB werden durch den Datenschutzhinweis und die Leistungsbeschreibungen inkl. Preisliste ergänzt.</p></li>
                </ul>
              </div>
            </div>

            <div className='term-points-container tl terms-container'>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>2. Leistungs- und Funktionsumfang der Security-Plattform</h3>
                <ul>
                  <li className='sub-list'><p><b>2.1</b> Der konkrete Leistungsumfang ergibt sich aus dem Angebot, das der Anbieter dem Kunden nach Maßgabe des in Ziffer 3 beschriebenen Verfahrens zum Vertragsschluss zusendet (nachfolgend „Angebot“). Aus dem Angebot ergibt sich der vom Kunden ausgewählte Leistungsumgang, die Preise sowie die Zahlungsmodalitäten, Vertragslaufzeit und Kündigungsfristen. Alle Angebote sind freibleibend und unverbindlich, sofern sie nicht ausdrücklich als verbindlich gekennzeichnet sind oder eine ausdrücklich bestimmte Annahmefrist enthalten.</p></li>
                  <li className='sub-list'><p><b>2.2</b> Die durch den Anbieter angebotenen Leistungen bestehen insbesondere aus</p>
                    <ul>
                      <li>Dokumentenmanagement</li>
                      <li>Überwachung von Cloud-Diensten</li>
                      <li>Verwaltung des Risk Managements</li>
                      <li>Erstellung und Verwaltung des Berechtigungskonzepts</li>
                      <li>Dashboard zur Mitarbeiter-Compliance</li>
                      <li>Sicherung der in der Security-Plattform gespeicherten Daten</li>
                    </ul>
                  </li>
                  <li className='sub-list'><p><b>2.3</b> Soweit nicht abweichend vereinbart, werden sämtliche Beratungsleistungen, Schulungsinhalte und gelieferte Dokumente in deutscher Sprache erbracht. Inhalte und Anweisungen auf der Security-Plattform werden ebenfalls ausschließlich in deutscher Sprache angeboten.</p></li>
                  <li className='sub-list'><p><b>2.4</b> Der Anbieter bietet neben dem Zugriff auf die Security-Plattform auch Beratung in der Anwendung und Umsetzung der in der Security-Plattform bereitgehaltenen Funktionen an. Die Anwendung und Umsetzung dieser Funktionen gemäß den Empfehlungen des Anbieters obliegt dem Kunden.</p></li>
                  <li className='sub-list'><p><b>2.5</b> Soweit der Anbieter Dokumente und Informationen des Kunden in die Security- Plattform einfügt oder selbst kundenindividuelle Dokumente erstellt, erfolgt dies ausschließlich auf Grundlage der Angaben des Kunden sowie seiner Mitarbeiter und Mitarbeiterinnen. Weitere, individuelle Beratungsleistungen oder die Entwicklung von individuellen Zusatzfunktionen erbringt der Anbieter ausschließlich auf Anfrage und gegen zusätzliche Vergütung.</p></li>
                  <li className='sub-list'><p><b>2.6</b> DerAnbieteristberechtigt,dieFunktionenderSecurity-Plattformzuaktualisierenund zu ergänzen. Der Anbieter wird den Kunden über entsprechende Aktualisierungen und Ergänzungen sowie über entsprechende Nutzungshinweise auf elektronischem Weg informieren. Der Anbieter behält sich vor, bestimmte Funktionalitäten nur gegen Zahlung einer zusätzlichen Vergütung bereitzustellen.</p></li>
                </ul>
              </div>
            </div>

            <div className='term-points-container tl terms-container'>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>3. Vertragsschluss; Onboarding</h3>
                <ul>
                  <li className='sub-list'><p><b>3.1</b> Voraussetzung für die Nutzung der Security-Plattform ist der Abschluss eines Nutzungsvertrages zwischen dem Kunden und dem Anbieter, an den sich ein Kick- Off-Termin sowie die Einrichtung des kundenindividuellen Zugangs des Kunden auf der Plattform („Onboarding“) anschließen. Zum Abschluss eines Nutzungsvertrags sind ausschließlich Unternehmer i.S.d. § 14 BGB berechtigt.</p></li>
                  <li className='sub-list'><p>Um einen Nutzungsvertrag zu schließen, wählt der Kunde die gewünschten Funktionalitäten aus und stellt per E-Mail, postalisch oder über das auf <a target='_blank' href='/'>www.trustspace.io</a> bereitgehaltene Kontaktformular eine Anfrage an den Anbieter, der ihm daraufhin ein individuelles Angebot zusendet. Der Kunde kann das durch den Anbieter erstellte Angebot annehmen, indem er dieses unterzeichnet und auf dem Postweg oder per E-Mail an den Anbieter zurücksendet.</p></li>
                  <li className='sub-list'><p><b>3.2</b> Nach Vertragsabschluss vereinbaren der Anbieter und der Kunde einen Kick-Off- Termin, in dem die aktuelle IT-Infrastruktur und bestehende Risiken im Unternehmen des Kunden gesichtet, die erforderlichen Zugriffsrechte festgelegt und das weitere Vorgehen abgestimmt werden. Der Kick-Off-Termin soll in der Regel innerhalb von 4 Wochen nach Vertragsschluss in Gestalt eines Online-Meetings in Microsoft Teams oder Zoom erfolgen.</p> </li>
                  <li className='sub-list'><p><b>3.3</b> Der Anbieter und der Kunde setzen die im Kick-Off-Meeting jeweils definierten Aufgaben eigenständig um. Der Anbieter informiert den Kunden, wenn das Onboarding abgeschlossen und der Zugriff des Kunden auf die Security-Plattform vollständig eingerichtet ist. Der Zugriff auf die Security-Plattform und die Nutzung der vertraglich vereinbarten Inhalte ist erst nach vollständigem Abschluss des Onboardings möglich.</p></li>
                </ul>
              </div>
            </div>

            <div className='term-points-container tl terms-container'>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>4. Zugriffsrechte des Kunden</h3>
                <ul>
                  <li className='sub-list'><p><b>4.1</b> EinephysischeÜberlassungderinderSecurity-PlattformbereitgestelltenSoftwarean den Kunden erfolgt nicht. Der Kunde ist berechtigt, die Security-Plattform lediglich im Rahmen seiner eigenen geschäftlichen Tätigkeit durch eigene (auch freie) Mitarbeiterinnen und Mitarbeiter während der Dauer des Vertrages bestimmungsgemäß zu nutzen.</p></li>
                  <li className='sub-list'><p><b>4.2</b> Nach erfolgreichem Onboarding erhält der im Kick-Off-Meeting bestimmte Administrator Zugriff auf die auf der Security-Plattform bereitgehalten, vertraglich vereinbarten Inhalte. Mitarbeiter und Mitarbeiterinnen des Kunden erhalten darüber hinaus Zugriff auf die in der Security-Plattform bereitgehaltenen Schulungsinhalte und Richtlinien. Darüber hinaus besteht die Möglichkeit, in der Security-Plattform einzelne Zugriffsrechte für Mitarbeiter und Mitarbeiterinnen mit besonderer Funktion (z.B. HR- Manager) sowie für externe Dienstleister des Kunden freizuschalten. Gleiches gilt für Zugriffsrechte für sonstige Dritte, die in besonderer Weise in die IT-Infrastruktur des Unternehmens des Kunden eingebunden sind.</p> </li>
                  <li className='sub-list'><p><b>4.3</b> Der Anbieter behält sich vor, bei einer übermäßigen Nutzung der Security-Plattform durch den Kunden, die zu einer System- oder Netzwerkbelastung der Serverkapazitäten des Anbieters führen, die Nutzung der Security-Plattform durch den Kunden zu beschränken. Der Anbieter wird den Kunden auf eine solche Überlastung hinweisen und sich gemeinsam mit den Kunden bemühen, geeignete Abhilfemaßnahmen zu treffen.</p></li>
                </ul>
              </div>
            </div>
            <div className='term-points-container tl terms-container'>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>5. Vergütung</h3>
                <ul>
                  <li className='sub-list'><p><b>5.1</b> Der Kunde ist verpflichtet, die vereinbarte Vergütung für die Bereitstellung der Security-Plattform und die Nutzung ihrer Inhalte zzgl. gesetzlicher MwSt. zu zahlen. Die vereinbarte Vergütung setzt sich zusammen aus den gewählten Modulen und Funktionen sowie der Anzahl der Mitarbeiter und Mitarbeiterinnen des Kunden. Preise und Zahlungsbedingungen ergeben sich aus dem Angebot.</p></li>
                  <li className='sub-list'><p><b>5.2</b> DiePflichtzurZahlungderVergütungfürdenZugriffaufdieSecurity-Plattformbeginnt mit dem Abschluss des Onboardings durch den Anbieter. Die auf die Bereitstellung der Security-Plattform entfallenen Entgelte sind im Voraus, bei zeitanteiliger Nutzung eines Monats entsprechend anteilig, zu entrichten.</p> </li>
                  <li className='sub-list'><p><b>5.3</b> Während der Vertragslaufzeit hat der Kunde die Möglichkeit, die Freischaltung weiterer Funktionen gegen Zahlung einer gesonderten Vergütung anzufragen. Der Anbieter wird dem Kunden auf Grundlage des gewünschten Umfangs der Änderungen, der noch verbleibenden Restlaufzeit des Vertrages und der bereits gezahlten Vergütung ein neues Angebot übermitteln. In diesem Fall gilt das in Ziffer 3 beschriebene Verfahren entsprechend.</p></li>
                  <li className='sub-list'><p> Soweit der Kunde während eines Vertragszeitraums die Anzahl vertraglich vereinbarter Zugänge für Mitarbeiter und Mitarbeiterinnen übersteigt, erfolgt die Anpassung des Vertragsumfangs mit der auf die Überschreitung folgenden Vertragsverlängerung, sofern der Vertrag nicht zuvor gekündigt wird. Maßgeblich für die Anpassung ist die Anzahl der Mitarbeiterzugänge im Zeitpunkt der Vertragsverlängerung. Der Anbieter wird den Kunden auf die Überschreitung sowie das neue Preismodul hinweisen.</p></li>
                  <li className='sub-list'><p><b>5.4</b> Der Anbieter behält sich vor, Preisanpassungen vorzunehmen. Diese erfolgen nach billigem Ermessen und sind beschränkt auf die Anpassung an die Entwicklung von Kosten, die für die Preisberechnung maßgeblich sind, z.B. die Kosten für die Bereitstellung der Serverkapazitäten. Insbesondere wird der Anbieter keine Preisanpassungen vornehmen, die das Äquivalenzinteresse zwischen Leistung und Gegenleistung zu seinen Gunsten verändern. Preiserhöhungen sind auf maximal fünf (5) Prozent pro Jahr begrenzt.</p></li>
                  <li className='sub-list'><p><b>5.5</b> Soweit Beratungsleistungen, Schulungen oder Termine zum Kick-Off und während des Onboardings im Unternehmen des Kunden vor Ort stattfinden, trägt der Kunde die für die Reise und ggf. Unterkunft erforderlichen, angemessenen Kosten.</p></li>
                </ul>
              </div>
            </div>
            <div className='term-points-container tl terms-container'>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>6. Pflichten des Kunden</h3>
                <ul>
                  <li className='sub-list'><p><b>6.1</b> Der Kunde ist selbst für die Einhaltung geltender Gesetze, Verordnungen und Richtlinien und die ordnungsgemäße Verarbeitung von Daten verantwortlich; der Anbieter unterstützt ihn hierbei lediglich. Durch die Nutzung der Security-Plattform wird der Kunde nicht von der gesetzlichen, behördlichen oder organisatorischen Verpflichtung befreit, die Anforderungen an ein ordnungsgemäßes Informationssicherheitsmanagement zu erfüllen.</p></li>
                  <li className='sub-list'><p><b>6.2</b> Der Kunde ist verpflichtet, seine Daten und Informationen vor der Eingabe oder dem Upload auf Viren oder sonstige schädliche Komponenten zu prüfen und hierzu dem Stand der Technik entsprechende Virenschutzprogramme einzusetzen. Gleiches gilt für sämtliche Systeme des Kunden, die durch APIs an die Security-Plattform angebunden werden.</p> </li>
                  <li className='sub-list'><p><b>6.3</b> Der Kunde verpflichtet sich, auf dem zur Verfügung gestellten Speicherplatz keine rechtswidrigen, die Gesetze, behördlichen Auflagen oder Rechte Dritter verletzenden Inhalte abzulegen. Der Kunde wird den Anbieter von Ansprüchen, die Dritte aufgrund eines Verstoßes gegen diese Ziffer geltend machen, auf erstes Anfordern freistellen.</p></li>
                  <li className='sub-list'><p><b>6.4</b> Der Kunde wird,</p>
                    <ul>
                      <li>einen festen Ansprechpartner und Verantwortlichen für den Onboarding-Prozess bestimmen;</li>
                      <li>Mitarbeiter und Mitarbeiterinnen benennen, die über die erforderlichen Kenntnisse über Risiken sowie bereits bestehende IT-Sicherheitsmaßnahmen im Unternehmen des Kunden sowie über umfassende Zugriffsrechte verfügen und den Onboarding-Prozess aktiv unterstützen werden;</li>
                      <li>dem Anbieter die erforderlichen Zugriffe auf seine Cloud-Systeme gewähren und Zustimmungen Dritter zum Zugriff durch den Anbieter einholen, soweit erforderlich;</li>
                      <li>die zum Zwecke des Onboardings und während der Vertragslaufzeit vorgenommenen Einstellungen und Änderungen an der Security-Plattform auf inhaltliche Richtigkeit hin überprüfen, insbesondere darauf, ob sie die organisatorischen Risiken und Maßnahmen in seinem Unternehmen zutreffend wiedergeben;</li>
                      <li>die in der Security-Plattform angezeigten Anweisungen, Hinweise und Erläuterungen berücksichtigen und bei Zweifeln und Problemen in der Anwendung der Security-Plattform den Support des Anbieters in Anspruch nehmen:</li>
                      <li>seine Mitarbeiter und Mitarbeiterinnen zur Teilnahme an den durch den Anbieter durchgeführten Schulungen verpflichten;</li>
                      <li>die in der Security-Plattform berücksichtigten Risiken aktuell halten und neue Risiken hinzufügen, wenn sie entstehen;</li>
                      <li>bestehende Dokumente mit Relevanz für die IT-Sicherheit (z.B. Verträge mit IT-Dienstleistern) in die Security-Plattform hochladen und ggf. aktualisieren und neue Dokumente hinzufügen;</li>
                      <li>die in der Security-Plattform enthaltenen Listen (insb. zu Dienstleistern, Assets, Inventar) auf dem aktuellen Stand halten und aktualisieren, soweit erforderlich;</li>
                      <li>die ihm sowie seinen Mitarbeitern und Mitarbeiterinnen überlassenen Zugangsdaten dem Stand der Technik entsprechend vor Zugriffen Dritter schützen, verwahren und dem Anbieter unverzüglich melden, wenn der Kunde einen unbefugten Zugriff auf die Security-Plattform für möglich hält;</li>
                    </ul>
                  </li>
                  <li className='sub-list'><p><b>6.5</b> DerKunderäumtdemAnbieterdasRechtein,dieinderSecurity-Plattformerzeugten und darin gespeicherten Daten zum Zwecke der Erfüllung der Vertragspflichten zu vervielfältigen. Der Anbieter ist berechtigt, notwendige Änderungen, beispielsweise an den Formaten der bereitgestellten Dokumente, vorzunehmen.</p></li>
                </ul>
              </div>
            </div>

            <div className='term-points-container tl terms-container'>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>7. Speicherung und Herausgabe von Daten</h3>
                <ul>
                  <li className='sub-list'><p><b>7.1</b> Der Anbieter erstellt in automatisierten Prozessen in regelmäßigen Abständen Sicherungen der Daten, die in der Security-Plattform gespeichert sind und von der Security-Plattform erzeugt werden. Übersichten und vom Anbieter bereitgestellte Dokumente kann der Kunde jederzeit eigenständig herunterladen und sichern. Hierfür stehen dem Kunden die Formate PDF, Word und CSV zur Verfügung. Darüber hinaus kann der Kunde vollständige Exporte oder einzelne Auszüge seiner in der Security- Plattform hinterlegten Daten anfordern. Der Anbieter behält sich vor, für die Bereitstellung umfassender Exporte ein gesondertes Entgelt zu verlangen.</p></li>
                  <li className='sub-list'><p><b>7.2</b>  Vom vertraglichen Leistungsumfang nicht erfasst ist die Einhaltung von Archivierungspflichten z.B. handelsrechtlicher oder steuerlicher Art. Diese unterliegen längerfristiger Datensicherung, für die der Kunde selbst verantwortlich ist.</p> </li>
                </ul>
              </div>
            </div>
            <div className='term-points-container tl terms-container'>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>8. Verfügbarkeit; Support</h3>
                <ul>
                  <li className='sub-list'><p><b>8.1</b>  Die Security-Plattform wird grundsätzlich mit einer Beschaffenheit, einschließlich der Funktionalität, der Kompatibilität, der Zugänglichkeit, der Kontinuität und Sicherheit zur Verfügung gestellt, die bei digitalen Produkten derselben Art üblich ist und die der Kunde unter Berücksichtigung der Art des digitalen Produkts erwarten kann.</p> </li>
                  <li className='sub-list'><p><b>8.2</b>  Der Anbieter gewährt eine Erreichbarkeit der Security-Plattform (d.h. der Möglichkeit zur Nutzung der Funktionen der Security-Plattform im vereinbarten Umfang) von mindestens 99,5 % im Monat am Übergabepunkt. Der Übergabepunkt ist der Routerausgang des Rechenzentrums des Anbieters.</p> </li>
                  <li className='sub-list'><p><b>8.3</b>  Die Erreichbarkeit berechnet sich auf der Grundlage der in der Vertragslaufzeit auf den jeweiligen Kalendermonat entfallenden Zeit abzüglich der planmäßigen Wartungszeiten und der Ausfallzeiten, die nicht im Einflussbereich des Anbieters (höhere Gewalt, Verschulden Dritter, etc.) liegen. Während der Wartungsarbeiten kann es vorkommen, dass der Zugriff auf die Security-Plattform kurzfristig nicht zur Verfügung steht. Die planmäßigen Wartungsarbeiten finden außerhalb der Kernarbeitszeiten (Montag bis Freitag, 8:00 Uhr bis 18:00 Uhr MEZ) statt.</p> </li>
                  <li className='sub-list'><p><b>8.4</b>  AnfragenoderProblemedesKundeninBezugaufdieNutzungderSecurity-Plattform sowie zur Kontaktaufnahme mit den Beratern des Anbieters kann der Kunde per E- Mail an masih.sediquian@trustspace.io oder per Ticketsystem an den Support richten. Anfragen während der Servicezeiten (Montag bis Freitag, 10:00 Uhr bis 18:00 Uhr, außer an den bundeseinheitlichen und gesetzlichen Feiertagen in Berlin) wird der Anbieter in der Regel innerhalb von 24 Stunden bearbeiten.</p></li>
                  <li className='sub-list'><p><b>8.5</b>  DerKundeistverpflichtet,demAnbieterunverzüglichanzeigen,wennerMängeloder fehlende Erreichbarkeiten der Security-Plattform feststellt. Unterlässt der Kunde die rechtzeitige Anzeige aus Gründen, die er zu vertreten hat, stellt dies ein Mitverschulden dar. Soweit der Anbieter durch die Unterlassung oder Verspätung der Anzeige keine Abhilfe schaffen konnte, ist der Kunde nicht berechtigt, die Nutzungsgebühr des Vertrages ganz oder teilweise zu mindern, den Ersatz des durch den Mangel entstandenen Schadens zu verlangen oder den Vertrag wegen des Mangels außerordentlich zu kündigen.</p> </li>
                </ul>
              </div>
            </div>
            <div className='term-points-container tl terms-container'>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>9. Gewährleistung; Rechte Dritter</h3>
                <ul>
                  <li className='sub-list'><p><b>9.1</b> Der Anbieter gewährleistet, dass die der Security-Plattform zugrundeliegende Software keine Rechte Dritter verletzt. Dies gilt nicht, soweit die Rechtsverletzung darauf beruht, dass der Kunde den Anbieter mit der Anbindung von Software von Dritten beauftragt hat, deren Einverständnis hierzu nicht vorliegt.</p></li>
                  <li className='sub-list'>Der Kunde wird den Anbieter unverzüglich über Ansprüche von Dritten, die diese aufgrund der vertragsgemäßen Nutzung der Security-Plattform gegen ihn geltend machen, informieren.</li>
                  <li className='sub-list'><p><b>9.2</b>  Im Falle eines Mangels an der Security-Plattform ist der Anbieter berechtigt, den Mangel nach eigener Wahl durch Beseitigung, Umgehung oder Neugliederung zu beheben. Die Mängelbeseitigung durch den Anbieter kann darüber hinaus auch durch telefonische, schriftliche oder elektronische Handlungsanweisungen an den Kunden erfolgen.</p> </li>
                  <li className='sub-list'><p><b>9.3</b>   Die Gewährleistung für nur unerhebliche Minderungen der Tauglichkeit der Leistung wird ausgeschlossen. Die verschuldensunabhängige Haftung gem. § 536a Abs. 1 BGB für Mängel, die bereits bei Vertragsschluss vorlagen, ist ebenfalls ausgeschlossen.</p> </li>
                </ul>
              </div>
            </div>
            <div className='term-points-container tl terms-container'>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>10. Haftung</h3>
                <ul>
                  <li className='sub-list'><p><b>10.1</b>  Der Anbieter haftet, gleich aus welchem Rechtsgrund, auf Schadenersatz oder Ersatz vergeblicher Aufwendungen in voller Höhe nur für Schäden des Kunden durch vorsätzliches oder grob fahrlässiges Verhalten, im Fall der Übernahme ausdrücklicher Garantien sowie zugesicherten Eigenschaften, bei Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, für Ansprüche aus Produkthaftung sowie im Fall zwingender gesetzlicher Regelungen.</p> </li>
                  <li className='sub-list'><p><b>10.2</b>  Bei der Verletzung wesentlicher Vertragspflichten (Kardinalpflichten) haftet der Anbieter unbenommen des vorstehenden Absatzes begrenzt auf den vertragstypischen, bei Vertragsschluss vernünftigerweise vorhersehbaren Schaden. Bei Kardinalpflichten handelt es sich um Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertragsverhältnisses überhaupt erst ermöglichen und auf deren Einhaltung der Kunde regelmäßig vertrauen darf.</p> </li>
                  <li className='sub-list'><p><b>10.3</b>  Im Übrigen haftet der Anbieter p.a. bis zu einem Betrag in Höhe des Jahresumsatzes des Kunden mit dem Anbieter (sollte die Vertragsbeziehung kürzer sein, ist dieser anhand der monatlichen 1/12 – ggf. taggleichen 1/30 – Umsatzkennwerte zu ermitteln). Soweit anwendbar bleiben gesetzlich zwingende Haftungsregelungen hiervon unberührt.</p> </li>
                  <li className='sub-list'><p><b>10.4</b>  Zugesicherte Eigenschaften bzw. Garantien sind nur diejenigen, die als solche ausdrücklich durch den Anbieter bezeichnet sind. Sind die zugesicherten Eigenschaften nicht oder nur teilweise erfüllt, hat der Kunde zunächst Anspruch auf Nacherfüllung durch den Anbieter. Gelingt die Nacherfüllung nicht oder nur teilweise, kann der Kunde eine angemessene Herabsetzung der Vergütung verlangen.</p></li>
                  <li className='sub-list'><p><b>10.5</b>  Der Anbieter haftet, unbeschadet der in den Ziffer 10.1 bis 10.4 genannten Fälle, nicht für entgangenen Gewinn, mittelbare Schäden, Mangelfolgeschäden und Ansprüche Dritter mit Ausnahme von Ansprüchen aus der Verletzung von Schutzrechten Dritter sowie nicht für auftretende Mängel, die im Zusammenhang mit einer durch den Kunden vorgenommenen oder sonst veranlassten Änderung der Leistungen durch den Anbieter oder sonstigen Fremdeinflüssen stehen, und die aus dem Risikobereich des Kunden stammen. Es obliegt dem Kunden nachzuweisen, dass auftretende Mängel nicht kausal auf einer Änderung der Systemumgebung oder sonstigen Fremdeinflüssen beruhen.</p> </li>
                  <li className='sub-list'><p><b>10.6</b>  Soweit die Haftung des Anbieters gegenüber dem Kunden beschränkt oder ausgeschlossen ist, gilt dies entsprechend für gesetzliche Vertreter, Arbeitnehmer, freie Mitarbeiter und sonstige Erfüllungs- bzw. Verrichtungsgehilfen des Anbieters.</p> </li>
                  <li className='sub-list'><p><b>10.7</b>  Für den Fall, dass Leistungen des Anbieters von unberechtigten Dritten unter Verwendung der Zugangsdaten des Kunden in Anspruch genommen werden, haftet der Kunde für dadurch anfallende Entgelte im Rahmen der zivilrechtlichen Haftung bis zum Eingang des Kundenauftrages zur Änderung der Zugangsdaten oder der Meldung des Verlusts oder Diebstahls, sofern den Kunden am Zugriff des unberechtigten Dritten ein Verschulden trifft.</p> </li>
                  <li className='sub-list'><p><b>10.8</b>  Der Kunde stellt den Anbieter von sämtlichen Ansprüchen Dritter aufgrund von Rechtsverletzungen frei, die auf einer rechtswidrigen Verwendung der Security- Plattform durch ihn beruhen oder mit seiner Billigung erfolgen. Erkennt der Kunde oder muss er erkennen, dass ein solcher Verstoß droht, besteht die Pflicht zur unverzüglichen Unterrichtung des Anbieters.</p> </li>
                </ul>
              </div>
            </div>
            <div className='term-points-container tl terms-container'>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>11. Datenverlust</h3>
                <ul>
                  <li className='sub-list'><p><b>11.1</b> Der Anbieter und der Kunde stimmen darüber überein, dass der Kunde alleiniger Herr der eigenen Daten und Informationen ist. Der Kunde ist daher allein dafür verantwortlich,</p>
                    <ul>
                      <li>geeignete und angemessene organisatorische und technische Maßnahmen zu ergreifen, um die Integrität seiner Daten zu gewährleisten und Datenverluste durch angemessene Sicherungsmaßnahmen zu vermeiden, damit im Falle eines Datenverlustes – gleich aus welchem Grund – mit möglichst geringem Aufwand die Daten wiederhergestellt werden können;</li>
                      <li>eine Versicherung abzuschließen und aufrecht zu erhalten, die alle etwaig erlittenen Schäden und/oder Kosten aufgrund eines Datenverlustes – gleich aus welchem Grund – abdecken.</li>
                    </ul>
                  </li>
                  <li className='sub-list'><p><b>11.2</b>  Für den Verlust von Daten haftet der Anbieter insoweit nicht, als der Schaden darauf beruht, dass es der Kunde unterlassen hat, Datensicherungen durchzuführen und dadurch sicherzustellen, dass verloren gegangene Daten mit vertretbarem Aufwand wiederhergestellt werden können.</p> </li>
                  <li className='sub-list'><p><b>11.3</b>  Dem Anbieter bleibt der Einwand des Mitverschuldens des Kunden unbenommen.</p> </li>
                </ul>
              </div>
            </div>
            <div className='term-points-container tl terms-container'>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>12. Höhere Gewalt</h3>
                <ul>
                  <li className='sub-list'><p> Wird der Anbieter an der Erfüllung der Verpflichtungen durch den Eintritt von unvorhersehbaren, außergewöhnlichen Umständen gehindert, die der Anbieter trotz zumutbarer Sorgfalt nicht abwenden kann, z.B. Betriebsstörungen, behördliche Eingriffe und/oder Energieversorgungsschwierigkeiten, sei es, dass diese Umstände im Bereich des Anbieters, sei es, dass sie im Bereich der Lieferanten eintreten, verlängert sich, wenn die Leistung nicht unmöglich wird, die Leistungsfrist in angemessenem Umfang. Wird durch die oben genannten Umstände die Leistung unmöglich, so wird der Anbieter von der eigenen Leistungsverpflichtung befreit. Der Kunde muss keinerlei Gegenleistungen erbringen.</p></li>
                </ul>
              </div>
            </div>
            <div className='term-points-container tl terms-container'>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>13. Verjährung</h3>
                <ul>
                  <li className='sub-list'><p><b>13.1</b> Nach den gesetzlichen Vorschriften verjähren Ansprüche beruhend auf einem vorsätzlichen oder grob fahrlässigen Handeln des Anbieters oder einer seiner gesetzlichen Vertreter oder Erfüllungsgehilfen sowie Ansprüche für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, die auf einer vorsätzlichen oder fahrlässigen Pflichtverletzung des Anbieters oder einer seiner gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen.</p></li>
                  <li className='sub-list'><p><b>13.2</b>  Für alle übrigen vertraglichen und gesetzlichen Ansprüche gegenüber dem Anbieter beträgt die Verjährungsfrist ein Jahr.</p> </li>
                </ul>
              </div>
            </div>
            <div className='term-points-container tl terms-container'>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>14. . Laufzeit; Kündigung</h3>
                <ul>
                  <li className='sub-list'><p><b>14.1</b> Soweit nicht im Angebot abweichend geregelt, verlängert sich der Vertrag jeweils um ein weiteres Jahr, wenn er nicht mindestens drei (3) Monate vor Ablauf der jeweiligen Vertragslaufzeit durch den Kunden gekündigt wird.</p></li>
                  <li className='sub-list'><p><b>14.2</b>  Die Kündigung bedarf der Schriftform.</p> </li>
                  <li className='sub-list'><p><b>14.3</b>  Das Recht zur fristlosen Kündigung aus wichtigem Grund bleibt unberührt.Ein wichtiger Grund ist für den Anbieter insbesondere:</p>
                    <ul>
                      <li>der schwerwiegende Verstoß eines Kunden gegen die Bestimmungen dieser AGB;</li>
                      <li>die Nichterbringung einer erforderlichen Mitwirkungshandlung durch den Kunden, sofern der Anbieter dem Kunden eine angemessene Frist zur Erbringung dieser bestimmten Mitwirkungshandlung gesetzt und erklärt hat, dass er den Vertrag außerordentlich kündige wenn die Handlung nicht bis zum Ablauf der Frist vorgenommen wird;</li>
                      <li>die deliktische Handlung eines Kunden oder der Versuch einer solchen;</li>
                      <li>Zahlungsverzug von mehr als 3 Wochen trotz Mahnung</li>
                      <li>die Eröffnung des Insolvenzverfahrens über das Vermögen eines Kunden oder die Abweisung des entsprechenden Eröffnungsantrages mangels Masse.</li>
                    </ul>
                  </li>
                  <li className='sub-list'><p><b>14.4</b>  Nach Beendigung des Vertrages zwischen dem Anbieter und dem Kunden ist der weitere Zugriff des Kunden und seiner Mitarbeiter und Mitarbeiterinnen auf die Security-Plattform ausgeschlossen. Es obliegt dem Kunden, vor Ende des Vertrages entsprechende Sicherungen der Inhalte anzufertigen oder anzufordern. Der Anbieter kann den Kunden aufgrund gesonderter Vereinbarung und gegen Zahlung eines gesonderten Entgelts bei der Überführung der in der Security-Plattform gespeicherten Inhalte an einen Drittanbieter unterstützen.</p> </li>
                  <li className='sub-list'>Der Anbieter wird sämtliche vom Kunden in der Security-Plattform gespeicherten Dokumente, Listen und sonstige Inhalte spätestens 3 Monate nach Ende des Vertrages unwiderruflich löschen.</li>
                </ul>
              </div>
            </div>
            <div className='term-points-container tl terms-container'>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>15. Vertraulichkeit</h3>
                <ul>
                  <li className='sub-list'><p><b>15.1</b>  Der Anbieter und der Kunde verpflichten sich sowohl über den Inhalt des Vertrages und alle damit im Zusammenhang stehenden Informationen, Unterlagen und Daten, die nicht öffentlich bekannt sind, sowie die bei der gemeinsamen Zusammenarbeit unter dem Vertrag bekannt gewordenen sonstigen Geschäfts- und Betriebsgeheimnisse, Stillschweigen bewahren und sie nicht Dritten zur Verfügung stellen. Die in diesem Absatz enthaltenen Geheimhaltungsvereinbarung ist nicht auf solche Informationen anwendbar, die der Anbieter und der Kunde in gesetzlich zulässiger Weise von dritter Seite erhalten haben.</p></li>
                  <li className='sub-list'><p><b>15.2</b>  Der Anbieter und der Kunde werden dafür sorgen, dass ihre Mitarbeiter, Vertreter oder sonstigen Personen, die Zugang zu vorstehenden Informationen, Unterlagen und Daten haben derselben Geheimhaltungsverpflichtung unterfallen, wie in vorstehendem Absatz geregelt.</p> </li>
                  <li className='sub-list'><p><b>15.3</b>  Dem Anbieter und dem Kunden ist es nicht gestattet, ohne vorherige schriftliche Zustimmung des jeweils anderen, die diese Entscheidung nach eigenem Ermessen trifft, den Inhalt des Vertrages oder dieser Vereinbarung ganz oder teilweise an Dritte weiterzugeben. Die Geheimhaltungsverpflichtung besteht hingegen nicht, sofern der Anbieter oder der Kunde gesetzlich verpflichtet ist, diese in gerichtlichen, behördlichen oder sonstigen Verfahren zu offenbaren.</p> </li>
                </ul>
              </div>
            </div>
            <div className='term-points-container tl terms-container'>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>16. Datenschutz</h3>
                <ul>
                  <li className='sub-list'><p><b>16.1</b>  Der Anbieter stellt sicher, dass alle mit der Leistungserbringung betrauten Personen die gesetzlichen Bestimmungen zum Datenschutz beachten.</p></li>
                  <li className='sub-list'><p><b>16.2</b>  Verarbeitet der Kunde im Rahmen der Nutzung der Security-Plattform personenbezogene Daten, so ist er für die Einhaltung der datenschutzrechtlichen Vorschriften verantwortlich. Der Kunde sichert zu, dass er zur Verarbeitung der personenbezogenen Daten, insbesondere solcher seiner Mitarbeiter und Mitarbeiterinnen, berechtigt ist. Der Kunde ist insbesondere verpflichtet, seine Mitarbeiter und Mitarbeiterinnen von der Übermittlung ihrer personenbezogenen Daten in Kenntnis zu setzen und ggf. erforderliche Einwilligungen einzuholen.</p> </li>
                  <li className='sub-list'><p><b>16.3</b>  Dem Kunden ist bekannt, dass es sich bei der Verarbeitung von Daten im Rahmen der Nutzung der Security-Plattform und des Speicherplatzes des Anbieters um eine Auftragsverarbeitung gem. Art. 28 DS-GVO handelt und er demgemäß für die Einhaltung der Vorschriften der DS-GVO und anderer Vorschriften über den Datenschutz „Verantwortlicher“ (vgl. Art. 4 Nr. 7 DS-GVO) ist. Gleichfalls erklärt der Anbieter, dass die technischen und organisatorischen Maßnahmen nach Art. 28 sowie 32 der DS-GVO dem Grunde nach eingehalten werden. Im Übrigen werden der Anbieter und der Kunde einen Auftragsverarbeitungsvertrag nach Maßgabe des Art. 28 DS-GVO schließen.</p> </li>
                  <li className='sub-list'><p><b>16.4</b>  Erhebt, verarbeitet oder nutzt der Kunde personenbezogene Daten, so steht er dafür ein, dass dies entsprechend den datenschutzrechtlichen Bedingungen geschieht und stellt den Anbieter im Fall eines Verstoßes von Ansprüchen Dritter frei. Beschwerden sowie Auskunfts-, Berichtigungs-, Löschungs- und Sperrungsansprüche wird der Anbieter an den Kunden weiterleiten. Stellt der Kunde fest, dass bei ihm gespeicherte personenbezogener Daten unrechtmäßig übermittelt oder auf sonstige Weise Dritten unrechtmäßig zur Kenntnis gelangt sind, und drohen schwerwiegende Beeinträchtigungen für die Rechte oder schutzwürdigen Interessen der Betroffenen, hat er dies nach Kenntniserlangung umgehend bzw. unverzüglich der zuständigen Aufsichtsbehörde, dem Anbieter sowie den Betroffenen mitzuteilen (vgl. Art. 33 DS- GVO).</p></li>
                </ul>
              </div>
            </div>
            <div className='term-points-container tl terms-container'>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>17. Allgemeine Bestimmungen</h3>
                <ul>
                  <li className='sub-list'><p><b>17.1</b>  Änderungen, Ergänzungen und Kündigungen bedürfen der Schriftform, wobei zur Wahrung der Schriftform die elektronische oder sonstige telekommunikative Übermittlung, insbes. per Telefax, per E-Mail oder per sonstiger webseitenbasierter Internetkommunikation genügt.</p></li>
                  <li className='sub-list'><p><b>17.2</b>  Diese AGB treten an die Stelle aller früheren AGB und ersetzen diese. Weitere Änderungen dieser AGB werden dem Kunden vorab per E-Mail mitgeteilt. Widerspricht der Kunde solchen Änderungen nicht innerhalb von 14 Tagen nach Zugang der Mitteilung, gelten die Änderungen als vereinbart, sofern der Kunde den geänderten Bedingungen per Buttonbetätigung explizit zugestimmt hat oder die Security-Plattform weiterhin in Anspruch nimmt. Auf das Widerspruchsrecht und die Rechtsfolgen des Schweigens wird der Kunde im Falle der Änderung dieser AGB gesondert hingewiesen.</p> </li>
                  <li className='sub-list'><p><b>17.3</b>  Sollte eine Bestimmung oder ein Teil einer Bestimmung dieser AGB oder ihrer Anlagen unwirksam oder nicht durchsetzbar sein oder werden, so wird dadurch die Gültigkeit der AGB im Übrigen nicht berührt. Der Anbieter und der Kunde sind im Rahmen des Zumutbaren nach Treu und Glauben verpflichtet, die unwirksame oder nicht durchsetzbare Bestimmung durch eine ihr im wirtschaftlichen Erfolg gleichkommende, wirksame und durchsetzbare Regelung zu ersetzen, sofern dadurch keine wesentliche Änderung des Inhalts dieser AGB oder ihrer Anlagen herbeigefügt wird.</p> </li>
                  <li className='sub-list'><p><b>17.4</b>  Es gilt ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss der Verweisungsnormen des internationalen Privatrechts (IPR) und des Übereinkommens der Vereinten Nationen über Verträge über den internationalen Warenkauf (CISG).</p></li>
                  <li className='sub-list'><p><b>17.5</b>  Erfüllungsort und ausschließlicher Gerichtsstand für alle Streitigkeiten, die sich aus oder im Zusammenhang mit der Leistungserbringung ergeben, ist Berlin in der Bundesrepublik Deutschland.</p></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TermsOfService
