import React from 'react'
import '../../assets/stylesheets/static-components/impressum.scss'
import { openCalendlyView } from '../../helpers/Utils'

function Impressum () {
  return (
    <div className='main-container'>
      <div className='header-container tl-pt12'>
        <a href='https://trustspace.io/'><img height='38px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/TS-Text-Logo.png' alt='TrustSpace' border='0' />
        </a>
      </div>
      <div className='floater-container'>
        <div className='trustspace-imprint-section tc gtm gbb tl-text18 fwl'>
          <div className='main-heading tl-text36 tl-f400 gbm tl-mb20'>Impressum</div>
          <div className='term-points-container tl'>
            <div className='tl-text20 tl-mb32 tc'>
              Dieses Impressum gilt für sämtliche Dienste der TrustLane Solutions GmbH
            </div>
            <div className='tl-text18 tl-f500'>
              Standort Berlin
            </div>
            <div className='term-content tl-f300fsm'>
              <p className='tl-929DB0'>TrustLane Solutions GmbH</p>
            </div>
            <div className='term-content tl-f300fsm'>
              <p className='tl-929DB0'>Chausseestraße 99 </p>
            </div>
            <div className='term-content tl-f300fsm'>
              <p className='tl-929DB0'>10115 Berlin  </p>
            </div>
            <div className='tl-text18 tl-f500 tl-mt32'>
              Standort Karlsruhe
            </div>
            <div className='term-content tl-f300fsm'>
              <p className='tl-929DB0'>TrustLane Solutions GmbH</p>
            </div>
            <div className='term-content tl-f300fsm'>
              <p className='tl-929DB0'>Unterreut 6   </p>
            </div>
            <div className='term-content tl-f300fsm'>
              <p className='tl-929DB0'>76135 Karlsruhe</p>
            </div>
            <div className='tl-text22 tl-f500 tl-mt32'>
              Kontakt
            </div>
            <div className='term-content tl-f300fsm tl-mt8'>
              <p className='tl-929DB0'>info@trustspace.io</p>
              <p className='tl-929DB0'>www.trustspace.io </p>
            </div>
            <section className='tl-mt32'>
              <div className='tl-text18 tl-f500'>Registereintrag</div>
              <div className='term-content tl-f300fsm'>
                <p className='tl-929DB0'>TrustLane Solutions GmbH  </p>
              </div>
              <div className='term-content tl-f300fsm'>
                <p className='tl-929DB0'>vertreten durch Julius Gerhard & Masih Sediquian  </p>
              </div>
              <div className='term-content tl-f300fsm'>
                <p className='tl-929DB0'>Eintragung im Handelsregister </p>
              </div>
              <div className='term-content tl-f300fsm'>
                <p className='tl-929DB0'>Registernummer: HRB 236268  </p>
              </div>
              <div className='term-content tl-f300fsm'>
                <p className='tl-929DB0'>Registergericht: Amtsgericht Berlin Charlottenburg   </p>
              </div>
              <div className='term-content tl-f300fsm'>
                <p className='tl-929DB0'>Umsatzsteuer-ID: DE349382060    </p>
              </div>
            </section>
            <section className='tl-mt32'>
              <div className='tl-text18 tl-f500'>Haftung für Inhalte </div>
              <div className='term-content tl-f300fsm'>
                <p className='tl-929DB0'>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.  </p>
              </div>
            </section>
            <section className='tl-mt32'>
              <div className='tl-text18 tl-f500'>Haftung für Links  </div>
              <div className='term-content tl-f300fsm'>
                <p className='tl-929DB0'>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.   </p>
              </div>
            </section>
            <section className='tl-mt32'>
              <div className='tl-text18 tl-f500'>Urheberrecht   </div>
              <div className='term-content tl-f300fsm'>
                <p className='tl-929DB0'>Die durch die Seitenbetreiber erstellten bzw. verwendeten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen. </p>
              </div>
            </section>
            <section className='tl-mt32'>
              <div className='tl-text18 tl-f500'>Kontakt    </div>
              <div className='term-content tl-f300fsm'>
                <p className='tl-929DB0'>Uns vertrauen europaweit mittelständische Kunden bei Implementierung eines ISMS nach ISO27001 oder TISAX. Kontaktieren Sie uns noch heute, um zu erfahren, wie Sie effizient Informationssicherheit und Compliance in Ihre Unternehmensprozesse einbinden können. </p>
              </div>
            </section>
            <section className='tl-mt32'>
              <button className='cta-button' onClick={() => openCalendlyView()}>
                <a>
                  <span>Unverbindliches Erstgespräch</span>
                </a>
              </button>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Impressum
