import React from 'react'

import Cta from './CTA'

import '../../assets/stylesheets/components/shared/card.scss'
import * as utils from '../../helpers/Utils'

function Card ({
  heading,
  description,
  cta,
  noShadow = false,
  subHeading,
  subHeading2,
  cardHeading
}) {
  function onRequestDemoCTAClick () {
    utils.sendConversionEvent('AW-10888242046/qKbYCL2R7r0DEP7G9cco')
    utils.openCalendlyView()
  }
  return (
    <div className='card-container connector-container flex-center section'>
      <div className='left-connector-image'>
        <img height='600px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/left-connector-dark.png' />
      </div>
      <div className={`card-wrapper ${noShadow ? '' : 'shadow-box'} container`}>
        {heading && <div className={`${cardHeading ? 'card-heading' : 'section-heading tl-f500'} tl-001233 tl-lh140`}>{heading}</div>}
        {subHeading && <div className='card-heading tl-001233 tl-lh140  monstreat-heading'>{subHeading}</div>}
        {subHeading2 && <div className='tl-text20 tl-f500 tl-001233 tl-lh140  monstreat-heading card-subheading'>{subHeading2}</div>}
        {description && <div className='tl tc tl-69778E tl-f400 tl-lh140 tl-fsn tl-001233 tl-text16 p-relative description'>{description}</div>}
        {cta && <Cta cta={{ ...cta, onClick: () => onRequestDemoCTAClick() }} />}
      </div>
    </div>
  )
}

export default Card
