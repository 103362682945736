import React from 'react'
import { Link } from 'react-router-dom'

function CTAButton ({
  link = '#',
  text,
  centered = false,
  onClick = () => {},
  target = ''
}) {
  const internalLink = link.startsWith('/')
  const ctaButton = (
    <button className='cta-button' onClick={onClick}>
      {internalLink
        ? (
          <Link to={link}>
            <span>{text}</span>
          </Link>
          )
        : (
          <a href={link} target={target}>
            <span>{text}</span>
          </a>
          )}
    </button>
  )
  return (
    centered
      ? (
        <div className='tc'>
          {ctaButton}
        </div>
        )
      : ctaButton
  )
}

export default CTAButton
