import React from 'react'
import '../../assets/stylesheets/subscribe/subscribe.scss'

function SubscriptionSuccessMsg (props) {
  function redirectToTrsutLane () {
    window.location.href = '/'
  }

  return (
    <div className='content-wrapper tc tl-p20'>
      <div className='image-wrapper'>
        <img src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/email-success.png' height='120px' />
      </div>
      <div className='modal-heading monstreat-heading tl-fsn tl-f500 tl-lh170 tl-text24 tl-001233 tl-mt20'>
        We’ve sent you a verification email.
      </div>
      <div className='modal-text tl-mt8'>
        <span className='tl-text16 tl-fsn tl-fnormal tl-lh170 tl-69778E'>Check your inbox/spam and click the confirmation link to download your FREE pdf today.</span>
      </div>
      <div className='button-container tl-mt20'>
        <button onClick={() => redirectToTrsutLane()} className='cta-button'>
          <span>Know more about us</span>
        </button>
      </div>
    </div>
  )
}
export default SubscriptionSuccessMsg
