export default `{
    "pageHeader": {
        "heading": "Why TrustSpace?",
        "description": "Streamline your cybersecurity with best-in-class automations",
        "animationData": {
            "heading": "Security & Compliance",
            "subHeading": "- we offer everything from one single source",
            "description": "Regardless of whether you are a start-up or an international medium-sized company - TrustSpace is your partner in enabling individual security and compliance solutions.",
            "animationLink": "https://d11jcn2a09nppp.cloudfront.net/website/animations/cs-on-autopilot.lottie"
        },
        "tiles": {
            "heading": "Ongoing dynamics",
            "subHeading": "Rising cyberattacks dictate stricter security requirements across industries.",
            "content": [
                {
                    "heading": "€220 billion",
                    "description": "Costs due to cybercrime in 2021 (€ 103 billion in 2019) "
                },
                {
                    "heading": "358%",
                    "description": "Increase in cyberattacks between 2019 and 2020"
                },
                {
                    "heading": "88%",
                    "description": "of all German companies were victims of cyberattacks in 2020 / 2021"
                }
            ]
        }
    },
    "card": {
        "heading": "We believe IT security should not impede an organization's growth but rather accelerate it",
        "description": "TrustSpace helps you scale faster by keeping you and your customers safe. With first class automated cybersecurity, you can accelerate your sales, build trustworthy relationships, and drive meaningful growth.",
        "cta": {
            "Component": "RequestADemo"
        }
    },
    "tiles": {
        "heading": "Why founders and cybersecurity experts choose us",
        "content": [
            {
                "heading": "Identification of specific corporate risks",
                "description": "By systematically and continuously scanning for risk factors, companies can identify potential threat scenarios at an early stage and proactively address risks.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/exclamation.svg"
                }
            },
            {
                "heading": "Proactively comply with industry standards",
                "description": "Business-specific security standards as well as regulatory standards are proactively adressed instead of reactively implementing minimum requirements.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/check.svg"
                }
            },
            {
                "heading": "Reduction of liability and business risks",
                "description": "Liability risks for managers as well as company-specific risks are significantly reduced. This leads to lower insurance premiums and a higher credit rating.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/risk-mitigation.png"
                }
            },
            {
                "heading": "Trust building with prospects",
                "description": "Holistic IT security systems increase trust among potential customers and partners and accelerate sales processes through effortless and fast proof of IT security standards.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/3rdparty-monitoring.png"
                }
            },
            {
                "heading": "Improved relationship of trust with customers & partners",
                "description": "Holistic IT security systems and the associated adequate protection of your partners' and customers' data lead to an increased level of trust and a strengthening of business relationships.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/thumbs.svg"
                }
            },
            {
                "heading": "Optimisation of extensive processes and associated cost reductions",
                "description": "Managing IT security through a single tool with comprehensive workflow automations streamlines maintenance and improvement processes and reduces costs.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/star.svg"
                }
            }
        ]
    },
    "reviews": {
        "heading": "What customers say about us",
        "content": [
            {
                "name": "Maximilian",
                "designation": "CEO",
                "feedback": "TrustSpace was instrumental to help us achieve ISO 27001 compliance. The process was easy and fast, just as advertised.",
                "image": {
                    "src": "https://i.ibb.co/XscnDn8/Vector.png"
                }
            },
            {
                "name": "Carolina",
                "designation": "Security Engineer",
                "feedback": "TrustSpace helped us obtain the ISO 27001 certification in just under 4 months. Their experts know the topic inside out which was key to streamline the process.",
                "image": {
                    "src": "https://i.ibb.co/XscnDn8/Vector.png"
                }
            },
            {
                "name": "Markus",
                "designation": "Security Engineer",
                "feedback": "Using TrustSpace's platform and integrations, we achieved ISO 27001 in just 2.5 months. The audit was a smooth and easy process given the diligent preparation.",
                "image": {
                    "src": "https://i.ibb.co/XscnDn8/Vector.png"
                }
            }
        ]
    },
    "timeline": {
        "heading": "Our 5 steps process to get you certified",
        "description": "Our customers achieve the certification within 3 to 4 months on average",
        "content": [
            {
                "boldHeading": "Phase 1",
                "heading": "Individual roadmap",
                "description": "Based on the risk assessment and stock taking we develop a dedicated and tailored roadmap to ISO 27001/TISAX®.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/phase-1.png"
                }
            },
            {
                "boldHeading": "Phase 2",
                "heading": "Infosec integration",
                "description": "Integration of required security measures and set-up of your turnkey IT security platform.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/phase-2.png"
                }
            },
            {
                "boldHeading": "Phase 3",
                "heading": "Platform hand-over",
                "description": "Kick-off and introduction of platform to administrators and employees to self-manage IT security holistically.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/pase-3.png"
                }
            },
            {
                "boldHeading": "Phase 4",
                "heading": "ISO audit / TISAX® assessment",
                "description": "Dedicated preparation phase for ISO 27001 audit/TISAX® assessment and continuous support during audit.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/phase-4.png"
                }
            },
            {
                "boldHeading": "Phase 5",
                "heading": "Continuous support",
                "description": "Continuous support for annual re-audits, following integrations, and platform extensions.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/phase-5.png"
                }
            }
        ],
        "cta": {
            "Component": "RequestADemo",
            "props": {
                "text": "Get Started Today",
                "centered": true
            }
        }
    },
    "features": {
        "heading": "How It Works",
        "content": [
            {
                "heading": "Build your ISMS",
                "description": "TrustSpace helps you design an ISMS that not only meets the ISO 27001/TISAX® requirements but also aligns with your organization's needs. Browse our policy's library, select & implement the essential ones for your organization, and publish them to your employees - all in one place and at a record time.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/how-it-works.png",
                    "height": "450px"
                },
                "list": {
                    "heading": "Key features",
                    "items": [
                        "Access 50+ policies developed by our ISO/TISAX® experts and recommended by our partner auditors.",
                        "Submit evidence to ISO 27001/TISAX® auditors easily"
                    ]
                }
            },
            {
                "heading": "Scan and secure your tech stack",
                "description": "TrustSpace connects with, monitors, and suggests avenues to make your cloud infrastructure compliant. And don't worry, we only scan through “read-only” access - your data is yours, and only yours.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/scan-secure.png",
                    "height": "450px"
                },
                "list": {
                    "heading": "Key features",
                    "items": [
                        "Monitor over 120+ cloud services including AWS, Google Cloud, and JIRA.",
                        "Implement measures to reduce vulnerabilities."
                    ]
                }
            },
            {
                "heading": "Assess and manage risks",
                "description": "TrustSpace integrates with over 100+ well-known vendors. We fetch their security data on your behalf and provide you with detailed risk reports and tailored solutions to mitigate those risks.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/access-and-manage.png",
                    "height": "450px"
                },
                "list": {
                    "heading": "Key features",
                    "items": [
                        "Perform vendor risk analysis and manage your 3rd parties.",
                        "Collect vendor security certifications & reports to meet ISO 27001/TISAX® requirements."
                    ]
                }
            },
            {
                "heading": "Stay compliant with automated evidence collection",
                "description": "Obtaining the ISO 27001/TISAX® certification is one thing, but to keep it is another. Stay secure year-round 24/7 with real time alerts on non-conformities throughout your tech stack, detected automatically. Our automations will collect the evidence on your behalf while you focus on what matters most: building a successful company.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/stay-compliant.png",
                    "height": "450px"
                },
                "list": {
                    "heading": "Key features",
                    "items": [
                        "Automated evidence collection from 80+ integrations.",
                        "Submit evidence to ISO 27001/TISAX® auditors easily"
                    ]
                }
            }
        ],
        "backLogData": [
            {
                "heading": "Cybersecurity with TrustSpace",
                "description": "A holistic information security management system includes a large number of security measures, which we provide for you in addition to our security platform.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/TS-approach-4.png",
                    "height": "450px"
                },
                "list": {
                    "heading": "Key features",
                    "items": [
                        "Backup Routines",
                        "Email Security",
                        "Management Threat Response",
                        "Enpoint and Server Security", 
                        "Device Encryption", 
                        "Establish Patch Management" 
                    ]
                }
            }
        ],
        "cta": {
            "text": "Learn more",
            "link": "/cyber-security",
            "centered":true
        }
    },
    "faq": {
        "heading": "Many companies need to expand IT security measures",
        "faqList": [
            {
                "label": "Pressure from the private sector",
                "description": "Large companies are tightening the IT security requirements that their suppliers must meet (e.g. VW requires all suppliers to be TISAX certified by the end of 2023)."
            },
            {
                "label": "Pressure from regulators",
                "description": "(Inter-) national regulators launch comprehensive IT security requirements (e.g., NIS2)."
            },
            {
                "label": "Lengthy certification processes",
                "description": "The traditional way to achieve certification to international standards is characterised by manual tasks, endless Excel files and a multitude of costly consultant hours."
            }
        ]
    }
}`
