import React, { useState } from 'react'
import '../../assets/stylesheets/components/home/clients.scss'
import { clientData } from '../../helpers/Clients'
import { useTranslation } from 'react-i18next'

function Clients (props) {
  const clients = clientData()
  const [activeClient, updateActiveClient] = useState(clients[0])
  const [activeIndex, updateActiveIndex] = useState(0)
  const clientsArrayLength = clients && clients.length
  const { t } = useTranslation(['clientsFeedback'])

  function updateSelectedIndex (index) {
    const activeElement = clients[index]
    if (activeElement) {
      updateActiveClient(activeElement)
      updateActiveIndex(index)
    }
  }

  function handleNextNavigation () {
    if (activeIndex < clientsArrayLength - 1) {
      updateSelectedIndex(activeIndex + 1)
    }
    if (activeIndex === clientsArrayLength - 1) {
      updateSelectedIndex(0)
    }
  }

  function handlePreviousNavigation () {
    if (activeIndex > 0) {
      updateSelectedIndex(activeIndex - 1)
    }
    if (activeIndex === 0) {
      updateSelectedIndex(clientsArrayLength - 1)
    }
  }

  return (
    <div className='clients-testimonials tl-mt80'>
      <div className='component-heading tl-text18 tl-69778E tl-fsn tl-f300 flex-center tl-mb20'>
        {t('feedback')}
      </div>
      <div className='feature-heading tl-mb12 flex-center flex-wrap'>
        <span className='tl-mr4 tl-text40 tl-001233 tl-fnormal tl-lh140 monstreat-heading'>{t('feedbacksFrom')}</span>&nbsp;<span className='tl-f500 tl-text40 tl-001233 tl-lh140'>{t('ourClients')}</span>
      </div>
      <div className='tl-mt40'>
        <div className='slider-container'>
          <div className='slides-wrapper'>
            <div className='client-image-wrapper'>
              <img className='client-image' src={activeClient.clientImage} style={{ borderRadius: activeClient.borderRadius || '0%' }} height={activeClient.height} width={activeClient.width} alt='client' />
            </div>
            <div className='text-wrapper'>
              <div className='tl client-feedback monstreat-heading tl-f400 tl-lh140 tl-fsn tl-001233 tl-text16 p-relative'>
                <span>&ldquo;{activeClient.feedback}&rdquo;</span>
                <span className='quote-image'><img height='20px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/Quotes.png' /></span>
              </div>
              <div className='client-name tl-mt32 tl-text16 monstreat-heading tl-f600 tl-lh162 tl-fsn tl-001233'>
                {activeClient.clientName}
              </div>
              <div className='client-designation tl-mt4 tl-text14 monstreat-heading  tl-lh162 tl-fsn tl-69778E'>
                {activeClient.designation}
              </div>
              {activeClient?.link &&
                <button className='cta-button'>
                  <a href={activeClient.link} target='_blank' rel='noreferrer'>
                    <span>Mehr erfahren</span>
                  </a>
                </button>}
            </div>
            {clients && clients.length > 1 &&
              <div className='navigation-container d-flex flex-center'>
                <span className='fa fa-angle-left flex-center navigation-icon' onClick={(e) => handlePreviousNavigation(e)} />
                <span className='fa fa-angle-right flex-center navigation-icon' onClick={(e) => handleNextNavigation()} />
              </div>}
            <div className='tl-mt40'>
              {clients && clients.length > 1 && clients.map((element, index) => {
                return (
                  <span key={index} className={index === activeIndex ? 'dots tl-mr8 active-index' : 'dots tl-mr8'} onClick={() => updateSelectedIndex(index)} />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Clients
