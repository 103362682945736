import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import Wrapper from './Components/home/Wrapper'
import WhyTrustSpace from './Components/WhyTrustSpace'
import ISOCompliance from './Components/ISOCompliance'
import AboutUs from './Components/AboutUs'
import Careers from './Components/Careers'
import Impressum from './Components/static-components/Impressum'
import PrivacyPolicy from './Components/static-components/PrivacyPolicy'
import LegalTerms from './Components/static-components/LegalTerms'
import Subscribe from './Components/Subscribe/Subscribe'
import Integrations from './Components/Integrations'
import CyberSecurity from './Components/CyberSecurity'
import HelpCenter from './Components/HelpCenter'
import Partner from './Components/Partner'
import { getParameterByName } from './helpers/Utils'
import TermsOfService from './Components/static-components/TermsOfService'
import Industries from './Components/Industries'

import './assets/stylesheets/styleguide/shared.scss'
import './assets/stylesheets/styleguide/panelTypography.scss'
import './assets/stylesheets/styleguide/panelMargin.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function App () {
  const { pathname } = useLocation()
  const customScroll = getParameterByName('customScroll')
  const id = getParameterByName('id')

  useEffect(() => {
    if (!customScroll) {
      window.scrollTo(0, 0)
    }
  }, [pathname])

  useEffect(() => {
    appendScripts()
    appendStylesheets()
    customScrollHeight()
    window.addEventListener('scroll', handleScroll)
  }, [])

  function appendScripts () {
    const dynamicScripts = [
      'https://assets.calendly.com/assets/external/widget.js'
    ]
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script')
      node.src = dynamicScripts[i]
      node.type = 'text/javascript'
      node.async = false
      document.getElementsByTagName('head')[0].appendChild(node)
    }
  }

  function appendStylesheets () {
    const dynamicStylesheets = [
      'https://assets.calendly.com/assets/external/widget.css',
      'https://fonts.googleapis.com/css?family=Poppins:300,400,500,700',
      'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700',
      'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
    ]
    for (let i = 0; i < dynamicStylesheets.length; i++) {
      const linkElement = document.createElement('link')
      linkElement.setAttribute('rel', 'stylesheet')
      linkElement.setAttribute('type', 'text/css')
      linkElement.setAttribute('href', dynamicStylesheets[i])
      document.getElementsByTagName('head')[0].appendChild(linkElement)
    }
  }

  function customScrollHeight () {
    if (customScroll) {
      const ele = document.getElementById(id)
      const isMobile = window.matchMedia('only screen and (max-width: 480px)').matches
      if (isMobile) {
        const headerheight = ele.scrollHeight + 750
        window.scrollTo(0, headerheight)
      } else {
        const headerheight = ele.scrollHeight + 400
        window.scrollTo(0, headerheight)
      }
    }
  }

  function scrollToTopBtn () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  function handleScroll () {
    const windowScroll = document.body.scrollTop || document.documentElement.scrollTop
    const scrollToTop = document.querySelector('#scrollToTopButton')
    if (windowScroll > 300) {
      scrollToTop.classList.add('visible')
    } else {
      scrollToTop.classList.remove('visible')
    }
  }

  return (
    <div className='app-container' onScroll={handleScroll}>
      <Routes>
        <Route
          exact path='/' element={<Wrapper />}
        />
        <Route
          exact path='/why-trustspace' element={<WhyTrustSpace />}
        />
        <Route
          exact path='/iso-compliance' element={<ISOCompliance />}
        />
        <Route
          exact path='/about-us' element={<AboutUs />}
        />
        <Route
          exact path='/careers' element={<Careers />}
        />
        <Route
          cyber-security
          exact path='/integrations' element={<Integrations />}
        />
        <Route
          exact path='/help-center' element={<CyberSecurity />}
        />
        <Route
          exact path='/cyber-security' element={<HelpCenter />}
        />
        <Route
          exact path='/impressum' element={<Impressum />}
        />
        <Route
          exact path='/terms-of-service' element={<TermsOfService />}
        />
        <Route
          exact path='/privacy-policy' element={<PrivacyPolicy />}
        />
        <Route
          exact path='/subscribe' element={<Subscribe />}
        />
        <Route
          exact path='/partner' element={<Partner />}
        />
        <Route
          exact path='legal-terms' element={<LegalTerms />}
        />
        <Route
          exact path='/industries' element={<Industries />}
        />
      </Routes>
      <div className='scrollToTop' id='scrollToTopButton' onClick={scrollToTopBtn}>
        <svg width='32' height='32' viewBox='0 0 100 100'>
          <path fill='black' d='m50 0c-13.262 0-25.98 5.2695-35.355 14.645s-14.645 22.094-14.645 35.355 5.2695 25.98 14.645 35.355 22.094 14.645 35.355 14.645 25.98-5.2695 35.355-14.645 14.645-22.094 14.645-35.355-5.2695-25.98-14.645-35.355-22.094-14.645-35.355-14.645zm20.832 62.5-20.832-22.457-20.625 22.457c-1.207 0.74219-2.7656 0.57812-3.7891-0.39844-1.0273-0.98047-1.2695-2.5273-0.58594-3.7695l22.918-25c0.60156-0.61328 1.4297-0.96094 2.2891-0.96094 0.86328 0 1.6914 0.34766 2.293 0.96094l22.918 25c0.88672 1.2891 0.6875 3.0352-0.47266 4.0898-1.1562 1.0508-2.9141 1.0859-4.1133 0.078125z' />
        </svg>
      </div>
    </div>
  )
}

export default App
