export default `{
    "pageHeader": {
        "heading": "We are TrustSpace",
        "description": "Your all-in-one automated platform to streamline compliance"
    },
    "features": {
        "content": [
            {
                "heading": "The founding story",
                "description1": "While still studying in Barcelona, Julius and one of his professors came up with the idea for what is now TrustSpace. Even before the first Excel sheet was created or Julius even knew the intricacies of ISO 27001 certification, Masih realized the full potential of the idea of Information Security-as-a-Service. Enthusiastic, Masih promptly moved into Julius' student shared apartment in Barcelona for a few months as well, adding the necessary expertise to the initial brainstorming and late-night work sessions alongside his role as an information security consultant, and ultimately turning the once-crazy idea into reality. One year fast-forward, Masih and Julius are now no longer sitting under the Catalan sun, but in beautiful Berlin, helping startups and mid-sized companies alike implement a secure information security management system, and combining the best of the worlds of compliance monitoring, cybersecurity and ISO 27001 consulting with TrustSpace.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/founders.png"
                }
            }
        ]
    },
    "foundersFeatures": {
        "heading": "The Founding Story",
        "content": [
            {
                "description1": "While still studying in Barcelona, Julius and one of his professors came up with the idea for what is now TrustSpace. Even before the first Excel sheet was created or Julius even knew the intricacies of ISO 27001 certification, Masih realized the full potential of the idea of Information Security-as-a-Service. Enthusiastic, Masih promptly moved into Julius' student shared apartment in Barcelona for a few months as well, adding the necessary expertise to the initial brainstorming and late-night work sessions alongside his role as an information security consultant, and ultimately turning the once-crazy idea into reality. One year fast-forward, Masih and Julius are now no longer sitting under the Catalan sun, but in beautiful Berlin, helping startups and mid-sized companies alike implement a secure information security management system, and combining the best of the worlds of compliance monitoring, cybersecurity and ISO 27001 consulting with TrustSpace.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/founders.png"
                }
            },
            {
                "heading": "Mission and Approach",
                "description1": "Our mission is to make holistic information security accessible to SMEs. Until now, this has been an undertaking that most SMEs could not manage without the costly support of ISO consultants or in-house experts. Our security engine TrustSpaceOS enables companies to implement a scalable security system. As a result, companies not only protect themselves, but at the same time become an attractive partner for large companies. These now require their partners, vendors and suppliers to comply with extensive security standards, often in the form of ISO 27001 certification. Instead of companies torturing themselves with the selection and management of necessary security measures, or purchasing costly support, we can implement a customized security standard within a few days."
            }
        ]
    },
    "imageCard": {
        "heading": "Our Office",
        "image": {
            "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/why-us/our-location.png",
            "height": "450px"
        },
        "footer": "Berlin, Germany"
    },
    "card": {
        "heading": "We're growing fast, really fast. Join the team!",
        "description": "If working in a collaborative, fun, and uplifting environment sounds like the kind of place you can see yourself thrive and develop in, check our open positions. We'd be happy to have you onboard.",
        "cta": {
            "text": "View Open Positions",
            "link": "/careers"
        }
    },
    "reviews": {
        "heading": "What employees say about us",
        "content": [
            {
                "name": "Ashutosh",
                "designation": "Engineering",
                "feedback": "At TrustSpace, I get to work with an empowering team of young people who share the same values of growth and respect. I also have ownership of my work and can contribute to some of the decisions we make.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/ashutosh.jpeg"
                }
            },
            {
                "name": "Ankit",
                "designation": "Engineering",
                "feedback": "I feel aligned with TrustSpace mission to create and optimise IT compliance automation platform for our clients. As an engineer I get to lead the development effort at TrustSpace while working with a wonderful team of engineers.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/ankit-pandey.png"
                }
            }
        ]
    },
    "teamcard": {
        "heading": "Experts who support us",
        "content": [
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Julius%20Gerhard.jpg",
                    "height": "80%"
                },
                "imageCard": {
                    "name": "Julius Gerhard",
                    "position": "Founder",
                    "link": "https://www.linkedin.com/in/juliusgerhard/"
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Masih-Sediquian.png",
                    "height": "90%"
                },
                "imageCard": {
                    "name": "Masih Sediquian",
                    "position": "Founder",
                    "link": "https://www.linkedin.com/in/masih-sediquian-646830225/"
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Tutku.jpeg",
                    "height": "115%"
                },
                "imageCard": {
                    "name": "Tutku Yurdagül",
                    "position": "Accounting & Sales",
                    "link": ""
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Intissar.png",
                    "height": "90%"
                },
                "imageCard": {
                    "name": "Intissar Loudhabachi",
                    "position": "Business Development ",
                    "link": ""
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/userImagePlaceHolder.png",
                    "height": "100%"
                },
                "imageCard": {
                    "name": "Aleeza Joe Schreiner",
                    "position": "Cyber Security Analyst",
                    "link": ""
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/stefania.jpg",
                    "height": "80%"
                },
                "imageCard": {
                    "name": "Stefania Vetere",
                    "position": "Junior Consultant",
                    "link": ""
                }   
            },            
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/userImagePlaceHolder.png",
                    "height": "100%"
                },
                "imageCard": {
                    "name": "Felix Mosler",
                    "position": "Head of Information Security",
                    "link": ""
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Phuc.jpg",
                    "height": "80%"
                },
                "imageCard": {
                    "name": "Truong Hoang Phuc",
                    "position": "Business Development",
                    "link": ""
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Charlotte-Vith.jpg",
                    "height": "100%"
                },
                "imageCard": {
                    "name": "Charlotte Vith",
                    "position": "Business Development",
                    "link": "https://www.linkedin.com/in/charlotte-vith-9aa7191b5/"
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Julius%20Siebert.png",
                    "height": "140%"
                },
                "imageCard": {
                    "name": "Julius Siebert",
                    "position": "Sales Manager",
                    "link": "https://www.linkedin.com/in/julius-siebert-6ab0b322a/"
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Ankit-Gaur.png",
                    "height": "95%"
                },
                "imageCard": {
                    "name": "Ankit Gaur",
                    "position": "Head of Tech",
                    "link": "https://www.linkedin.com/in/ankit-kumar-gaur-822a798a/"
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Ashutosh-Tripathi.jpg",
                    "height": "85%"
                },
                "imageCard": {
                    "name": "Ashutosh Tripathi",
                    "position": "Developer",
                    "link": "https://www.linkedin.com/in/ashutosh2915/"
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Hamid-new.jpeg",
                    "height": "85%"
                },
                "imageCard": {
                    "name": "Hamid Ali",
                    "position": "Developer",
                    "link": "https://www.linkedin.com/in/profile-hamidali/"
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Akash.jpeg",
                    "height": "100%"
                },
                "imageCard": {
                    "name": "Akash Kumar",
                    "position": "Developer",
                    "link": "https://www.linkedin.com/in/developer-akash/"
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/maharshi.png",
                    "height": "100%"
                },
                "imageCard": {
                    "name": "Maharishi Shah",
                    "position": "Developer",
                    "link": "https://www.linkedin.com/in/maharshi-shah1996ms/"
                }   
            },
            {
                "imageCard": {
                    "name": "Du",
                    "position": "Offene Stellen",
                    "ctaText": "Karriere",
                    "cta": "https://www.trustspace.io/careers"
                }   
            }
        ],
        "contentTeam": [
            {
                "imageCard": {
                    "name": "Mario Arndt",
                    "position": "Managing Director of DEUDAT GmbH & ISO 27001 Lead Auditor",
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/Clients/Mario-DEUDAT.png"
                }   
            },
            {
                "imageCard": {
                    "name": "Robert Jeggle",
                    "position": "Co-Founder von AQUATY & Swiss CPA",
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Robert-Menzel.jpg"
                }   
            },
            {
                "imageCard": {
                    "name": "Benjamin Bachmann",
                    "position": "Vice President Group Information Security Ströer SE",
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/lxzswe.png"
                }   
            },
            {
                "imageCard": {
                    "name": "Tobias Kraus",
                    "position": "Head of Compliance & IT Assurance bei der BFMT Group",
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/tobias.png"
                }   
            }
        ]
    }
}`
