export default `{
    "pageHeader": {
        "heading": "Unsere Partner",
        "description": "Ein starkes Unternehmen braucht starke Partner: TrustSpace und unsere Services profitieren vom Know-How und der Unterstützung erfahrener Unternehmen aus den Bereichen Tech & Informationssicherheit."
    },
    "features": {
        "content": [
            {
                "heading": "DEUDAT",
                "description": "DEUDAT GmbH is an owner-managed company in the field of data protection and information security that supports companies in introducing, implementing and maintaining an appropriate level of data protection. Thus, the experts and lawyers advise organizations regardless of industry, size or orientation. With DEUDAT at our side, we as a young company ourselves, and ultimately our customers, benefit daily from concentrated knowledge and expertise from over 25 years of experience in the field of information security. The team of consultants and lawyers serves small as well as large internationally oriented medium-sized companies up to big players in the field of critical infrastructure.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/Logo-Deudat.webp",
                    "height": "200px"
                }
            },
            {
                "heading": "Lawpilots",
                "description": "Lawpilots offers online training in the legal areas of data protection, compliance, occupational health and safety, and information security. The training courses are available in more than 30 languages and countries and optimally prepare your employees for the challenges of digitalization. Thanks to the online format, the training courses can not only be flexibly integrated into everyday working life, but the learning content is also enriched with interesting examples, gamification, expert interviews and infographics. In cooperation with Lawpilots, we ensure that your employees are optimally trained in compliance topics.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/Logo-lawpilots.png",
                    "height": "75px"
                }
            },
            {
                "heading": "Techminers",
                "description": "TechMiners is a provider of tech due diligence. To complete tech transactions today, there must be a clear understanding by all parties of the underlying technology, including relevant information security factors. TechMiners enables this through proprietary software and the expertise of former CTOs with over 50 years of combined experience",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/Techminers-Logo.webp",
                    "height": "55px"
                }
            }
        ]
    }
}`
