import React from 'react'
import { useTranslation } from 'react-i18next'
import '../../assets/stylesheets/components/home/featuredArticles.scss'
function FetauredArticles (props) {
  const { articleList } = props
  const articles = articleList()
  const { t } = useTranslation(['featuredArticle'])
  return (
    <div className='featured-article-wrapper'>
      <div className='tl-mt40'>
        <div className='component-heading tl-text18 tl-69778E tl-fsn tl-f300 flex-center tl-mb20'>
          Blog
        </div>
        <div className='feature-heading tl-mb12 flex-center flex-wrap tc'>
          <span className='tl-mr4 tl-text40 tl-001233 tl-fnormal tl-lh140 monstreat-heading'>{t('heading')}</span>
        </div>
      </div>
      <div className='articles-container'>
        {articles && articles.map((article, index) => {
          return (
            <div className='article-wrapper tl-mt20' key={index}>
              <div className='article-image'>
                <img src={article.image} height='200px' width='100%' />
              </div>
              <div className='tl-text16 tl-lh170 monstreat-heading tl-f600 tl-mt8 article-heading'>{article.name.toUpperCase()}</div>
              <div className='tl-text14 tl-69778E tl-lh1700 tl-f400 tl-fsn tl-mt8'>{article.shortDesc}</div>
              <div className='d-flex tl-mt8 justify-space-between tl-mb12'>
                <div className='link-cta tl-text16 gradient-text'>
                  <a href={article.href} target='_blank' rel='noreferrer'>
                    <span>Read Article</span>
                    <img src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/careers/know-more.png' />
                  </a>
                </div>
                <div className='tl-text14 tl-f400 tl-ml24'>{article.publishDate}</div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default FetauredArticles
