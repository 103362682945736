import React from 'react'

import Cta from './CTA'
import Tile from './Tile'

import '../../assets/stylesheets/components/shared/timeline.scss'

function Timeline ({
  heading,
  description,
  content,
  cta,
  id = ''
}) {
  const renderPhases = content.map((phase, i) => {
    return (
      <div className='connector-container' key={i}>
        {[2, 6].includes(i) && (
          <div className='left-connector-image'>
            <img height='600px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/left-connector-dark.png' />
          </div>
        )}
        <div className='container'>
          <div className='phase-wrapper'>
            <div className='content'>
              <Tile tile={phase} />
              <div className='connector' />
              <div className='dot-dash' />
            </div>
          </div>
        </div>
        {[0, 4].includes(i) && (
          <div className='right-connector-image'>
            <img height='600px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/left-connector-dark.png' />
          </div>
        )}
      </div>
    )
  })

  return (
    <div className='timeline-container section' id={id}>
      <div className='section-heading'>
        <span className='tl-f500 tl-001233 tl-lh140'>{heading}</span>
        <div className='section-description tl-69778E'>{description}</div>
      </div>
      <div className='timeline-wrapper'>
        {renderPhases}
      </div>
      {cta && <Cta cta={cta} />}
    </div>
  )
}
export default Timeline
