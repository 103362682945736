export default `{
    "tiles": {
        "heading": "Our consultants adapt existing management systems or even start 'from scratch'.",
        "content": [
            {
                "heading": "Consultancies",
                "contentValues": [
                    "Personal InfoSec consultants",
                    "Gap analysis",
                    "CISO as a service / external ISO",
                    "Internal audits",
                    "Audit support"
                ]
            },
            {
                "heading": "ISMS software",
                "contentValues": [
                    "Document guidance system",
                    "Document package (ISO & TISAX)",
                    "Asset and risk management",
                    "Vendor management",
                    "Reportings compliant with ISO 27001"
                ]
            },
            {
                "heading": "Cybersecurity features",
                "contentValues": [
                    "End point encryption",
                    "Managed firewall services",
                    "Mobile device management",
                    "Email security",
                    "Takeover of backup routines"
                ]
            }
        ],
        "requestADemo": {
            "text": "Free initial consultation"
        }
    }
}`
