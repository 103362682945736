import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import '../../assets/stylesheets/components/shared/modal.scss'

function Modal (props) {
  useEffect(() => {
    document.body.classList.add('overflow-y-hidden')
    return () => document.body.classList.remove('overflow-y-hidden')
  })
  const renderModal = (
    <div className='modal-container'>
      <div className='modal-content'>
        <div className='close' onClick={() => props.closeModal()}>x</div>
        {props.children}
      </div>
    </div>
  )
  return ReactDOM.createPortal(renderModal, document.getElementById('modal-root'))
}

export default Modal
