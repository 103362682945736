import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ContactUs from './ContactUs'
import Modal from './Modal'
import CTAButton from './CTAButton'

import '../../assets/stylesheets/components/shared/requestADemo.scss'

function RequestADemo ({
  centered = false,
  text,
  isEmailCollectionForm,
  buttonText,
  onClick = () => { }
}) {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation(['text'])
  const requestADemo = text || t('requestADemo')

  const handleClick = () => {
    setShowModal(true)
    onClick()
  }

  function onSubscriptionSuccessFromForm () {
    setShowModal(false)
  }

  return (
    <>
      <CTAButton centered={centered} text={requestADemo} onClick={handleClick} />
      {showModal && (
        <Modal closeModal={() => setShowModal(false)}>
          <ContactUs
            isEmailCollectionForm={isEmailCollectionForm}
            buttonText={buttonText}
            onSubscriptionSuccessFromForm={onSubscriptionSuccessFromForm}
          />
        </Modal>
      )}
    </>
  )
}
export default RequestADemo
