import React from 'react'
import { useTranslation } from 'react-i18next'
import Footer from './shared/Footer'
import PageHeader from './shared/PageHeader'
import Features from './shared/Features'
// import Tiles from './shared/Tiles'

function Industries () {
  const { t } = useTranslation(['pages'])
  const { pageHeader, features, features2 } = JSON.parse(t('industries'))
  return (
    <div className='industries-wrapper overflow-hidden'>
      <PageHeader
        imageData={pageHeader.imageData}
        requestADemo={pageHeader.requestADemo}
        hideBrands
        showImageDataContainer
        heading={pageHeader.heading}
        description={pageHeader.description}
        cta={pageHeader.cta}
        smallHeadingAndDesc
      />
      <Features
        heading={features.heading}
        content={features.content}
        cta={features.cta}
        squaresWithoutColor
      />
      {/* <Tiles
        heading={tiles.heading}
        content={tiles.content}
        rows='three'
        customHeading
        subHeading={tiles.subHeading}
      /> */}
      <Features
        heading={features2.heading}
        content={features2.content}
        cta={features2.cta}
        squaresWithoutColor
      />
      <Footer />
    </div>
  )
}
export default Industries
