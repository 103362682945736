import React from 'react'
import { useTranslation } from 'react-i18next'

import Cta from './CTA'
import '../../assets/stylesheets/components/shared/automateCompliance.scss'

function AutomateCompliance () {
  const { t } = useTranslation(['common'])
  const { heading, image, cta } = JSON.parse(t('automateCompliance'))
  return (
    <div className='section'>
      <div className='automate-compliance container'>
        <div className='content-wrapper'>
          <div className='tl-text32 tl-f500 tl-fsn tl-lh162 tl-mb32 monstreat-heading'>
            {heading}
          </div>
          <Cta cta={cta} />
        </div>
        <div className='image-wrapper shadow-box'>
          <img height='400px' src={image.src} />
        </div>
      </div>
    </div>
  )
}
export default AutomateCompliance
