import React from 'react'
import { useTranslation } from 'react-i18next'
import Footer from './shared/Footer'
import ContactUs from './shared/ContactUs'
import PageHeader from './shared/PageHeader'
import Features from './shared/Features'
import Card from './shared/Card'
import Timeline from './shared/Timeline'
import '../assets/stylesheets/components/ISOCompliance.scss'

function ISOCompliance () {
  const { t } = useTranslation(['pages'])
  const { pageHeader, card, timeline, features } = JSON.parse(t('iso-compliance'))
  return (
    <div className='iso-compliance overflow-hidden'>
      <PageHeader
        heading={pageHeader.heading}
        description={pageHeader.description}
        cta={pageHeader.cta}
        hideBrands
      />
      <Card
        heading={card.heading}
        description={card.description}
        cta={card.cta}
      />
      <Timeline
        heading={timeline.heading}
        description={timeline.description}
        content={timeline.content}
        cta={timeline.cta}
      />
      <Features
        heading={features.heading}
        content={features.content}
        cta={features.cta}
        hideConnectorImage
      />
      <ContactUs />
      <Footer />
    </div>
  )
}
export default ISOCompliance
