import React from 'react'
import '../../assets/stylesheets/components/home/heroImage.scss'
import Header from '../shared/Header'
import { useTranslation } from 'react-i18next'
import BrandWrapper from '../shared/BrandWrapper'
import '@dotlottie/player-component'
// import Certifications from '../shared/Certifications'

import * as utils from '../../helpers/Utils'

function HeroImage (props) {
  const { t } = useTranslation(['heroImage'])

  function onRequestDemoCTAClick () {
    utils.sendConversionEvent('AW-10888242046/qKbYCL2R7r0DEP7G9cco')
    utils.openCalendlyView()
  }

  return (
    <div className='hero-image-container'>
      <div className='connector-image overflow-hidden'>
        <img height='720px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/left-connector-dark.png' />
      </div>
      <div className='hero-image-wrapper'>
        <div className='header-image-wrapper container'>
          <div className='header-wrapper' id='header'>
            <Header />
          </div>
          <div className='content-wrapper'>
            <div className='outer-wrapper'>
              <div className='left-wrapper'>
                <div className='connector-line' />
                <div className='d-flex flex-column'>
                  <span className='tl-text37 tl-mr8 tl-lh170 tl-fsn tl-fnormal tl-001233 sub-heading monstreat-heading'>{t('yourCyberSpace')}</span>
                  <span className='heading tl-001233'>{t('onAuto')}</span>
                </div>
                <div className='description tl-69778E'>
                  {t('heroDesc')}
                </div>
                <button className='cta-button' onClick={onRequestDemoCTAClick}>
                  <a>
                    <span>{t('requestADemo')}</span>
                  </a>
                </button>
                <button className='cta-button'>
                  <a href='#innovative-it-compliance'>
                    <span>{t('cta')}</span>
                  </a>
                </button>
              </div>
              <div className='right-container m-w-45'>
                <dotlottie-player
                  autoplay loop zmode='normal'
                  src='https://d11jcn2a09nppp.cloudfront.net/website/animations/home-page-animation.lottie'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='certification-service-wrapper tl-mt8 tl-mb40 container'>
        <Certifications />
      </div> */}
      <BrandWrapper />
    </div>
  )
}
export default HeroImage
