import React, { useEffect, useState } from 'react'
import '../../assets/stylesheets/subscribe/subscribe.scss'
import SubscribeForm from './SubscribeForm'
import CryptoJS from 'crypto-js'
import * as utils from '../../helpers/Utils'
import * as siteConstants from '../../constants/siteConstants'
import SubscriptionSuccessMsg from './SubscriptionSuccessMsg'
function Subscribe (props) {
  const { isEmailCollectionForm, buttonText, popupHeading, onSubscriptionSuccessFromForm, formLink } = props
  const [componentData, updateComponentData] = useState({
    heading: 'Get Your FREE List of Requirements',
    description: 'Easily Get Ready for Your ISO 27001 Audit. Used by 1000+ companies and counting.',
    buttonText: 'Download list of requirements',
    conversionEvent: 'AW-10888242046/uR1iCLap8r0DEP7G9cco'
  })

  useEffect(() => {
    const token = (utils.getParameterByName('token', null, true))
    if (token && siteConstants.SECRET_CONSTANT) {
      updateDynamicData(token)
    }
  }, [])

  function updateDynamicData (token) {
    let bytes, originalText
    try {
      bytes = CryptoJS.AES.decrypt(token, siteConstants.SECRET_CONSTANT)
      originalText = bytes.toString(CryptoJS.enc.Utf8)
      if (originalText && typeof (originalText) === 'string' && originalText.length > 0) {
        updateComponentData(JSON.parse(originalText))
      }
    } catch (e) {
      console.warn(e)
    }
  }

  function showModal () {
    onSubscriptionSuccess()
    const modal = document.getElementById('subscribe-modal')
    modal.style.display = 'block'
  }

  function hideModal () {
    const modal = document.getElementById('subscribe-modal')
    modal.style.display = 'none'
  }

  function onSubscriptionSuccess () {
    onSubscriptionSuccessFromForm && onSubscriptionSuccessFromForm()
  }

  return (
    <div className={isEmailCollectionForm ? 'modified-subscribe-container' : 'subscribe-container'}>
      {!isEmailCollectionForm &&
        <div className='nav-title logo-wrapper tl-001233 tl-text36 tl-f500 d-flex justify-center tl-mt40'>
          <img height='26px' className='tl-mr12' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/TrustSpace-logo.png' />TrustSpace
        </div>}
      <div className='main-container'>
        <div className='left-connector-image'>
          <img height='600px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/left-connector-dark.png' />
        </div>
        <div className='middle-wrapper'>
          {componentData.heading &&
            <div className='component-heading monstreat-heading tl-text40 tl-f500 tl-fsn tl-lh140 tl-001233 tc'>
              {popupHeading || componentData.heading}
            </div>}
          {componentData.description && !isEmailCollectionForm &&
            <div className='component-desc tl-text18 tl-lh170 tl-69778E tl-fsn tl-fnormal tl-69778E'>
              {componentData.description}
            </div>}
          <div className='subscribe-input-wrapper tl-mt20'>
            <SubscribeForm
              buttonText={buttonText || componentData.buttonText}
              showModal={showModal}
              hideModal={hideModal}
              conversionEvent={componentData.conversionEvent}
              formLink={formLink}
            />
          </div>
        </div>
      </div>
      <div id='subscribe-modal' className='modal'>
        <div className='modal-content'>
          <span className='close' onClick={() => hideModal()}>&times;</span>
          <SubscriptionSuccessMsg />
        </div>
      </div>
    </div>
  )
}

export default Subscribe
