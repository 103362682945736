import React, { useState } from 'react'
import '../../assets/stylesheets/components/faq/faq.scss'

function Faq (props) {
  const { faqList, heading } = props
  const [activeIndex, updateActiveIndex] = useState(0)

  return (
    <div className='faq-container tl-mb20' id='faq'>
      <div className='faq-outer-wrapper'>
        <div className='container'>
          <div className='tc section-heading w-100 tl-001233 monstreat-heading tl-f600 tl-lh170 tl-mb24'>{heading}</div>
          <div className='accordion'>
            {faqList?.map((element, index) => {
              return (
                <div key={index} className='accordion-item tl-mt20' onClick={() => updateActiveIndex(index)}>
                  <button id='accordion-button' aria-expanded={activeIndex === index}><span className='accordion-title monstreat-heading'>{element.label}</span><span className='icon' aria-hidden='true' /></button>
                  <div className='accordion-content'>
                    <p>{element.description}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq
