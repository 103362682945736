import React from 'react'
import '../../assets/stylesheets/components/home/features.scss'
import { useTranslation } from 'react-i18next'
import '@dotlottie/player-component'
import Cta from '../shared/CTA'
import * as utils from '../../helpers/Utils'

function Feature (props) {
  const { heading, content, cta } = props
  const { t } = useTranslation(['features'])

  function onRequestDemoCTAClick () {
    utils.sendConversionEvent('AW-10888242046/qKbYCL2R7r0DEP7G9cco')
    utils.openCalendlyView()
  }

  return (
    <div className='feature-container tl-p20'>
      <div className='feature-heading tl-mt40 tl-mb80 flex-center flex-wrap' id='innovative-it-compliance'>
        <span className='tl-mr4 tl-text40 tl-001233 tl-fnormal tl-lh140 monstreat-heading'>{t('takeALook')}</span>&nbsp;<span className='tl-f500 tl-text40 tl-001233 tl-lh140'>{t('distinctiveFeature')}</span>
      </div>
      {content &&
        <div className='container feature-tile-wrapper'>
          <span className='main-heading'>{heading}</span>
          <div className='tile-container-wrapper'>
            {content.map((item, i) => (
              <div className='tile-div' key={i}>
                <span className='tile-heading'>{item.heading}</span>
                {item?.contentValues.map((text, i) => (
                  <div key={i} className='tile-text-wrapper'>
                    <svg width='20' height='20' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M12 2.5C6.49 2.5 2 6.99 2 12.5C2 18.01 6.49 22.5 12 22.5C17.51 22.5 22 18.01 22 12.5C22 6.99 17.51 2.5 12 2.5ZM16.78 10.2L11.11 15.87C10.97 16.01 10.78 16.09 10.58 16.09C10.38 16.09 10.19 16.01 10.05 15.87L7.22 13.04C6.93 12.75 6.93 12.27 7.22 11.98C7.51 11.69 7.99 11.69 8.28 11.98L10.58 14.28L15.72 9.14C16.01 8.85 16.49 8.85 16.78 9.14C17.07 9.43 17.07 9.9 16.78 10.2Z' fill='url(#paint0_linear_5214_21706)' />
                      <defs>
                        <linearGradient id='paint0_linear_5214_21706' x1='24.4752' y1='-0.245099' x2='1.50176' y2='24.2513' gradientUnits='userSpaceOnUse'>
                          <stop stopColor='#00FEEF' />
                          <stop offset='1' stopColor='#0982F2' />
                        </linearGradient>
                      </defs>
                    </svg>
                    <span className='tile-text'>{text}</span>
                  </div>
                ))}
              </div>
            ))}
          </div>
          {cta && <Cta cta={{ ...cta, onClick: () => onRequestDemoCTAClick() }} />}
        </div>}
      <div className='tab-container flex-center justify-center  tl-mb80  flex-wrap'>
        <div className='left-tab w-45 tl-mr60'>
          <div className='tab-heading tl-text32 tl-f500 tl-fsn tl-lh162 tl-mb32 monstreat-heading'>
            {t('heading-1')}
          </div>
          <div className='tab-description tl-fsn tl-fnormal tl-text16 tl-lh162 tl-69778E'>
            {t('description-1')}
          </div>
          <a href='why-trustspace?customScroll=true&id=5-steps-process' className='d-flex tl-mt16 theme-color-gradient tl-text16'>{t('cta-1')}</a>
        </div>
        <div className='right-tab tab-1-right m-w-45 image-center'>
          <img className='feature-image' height='400px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/new-status-of-growth.png' />
        </div>
      </div>
      <div className='tab-container flex-center justify-center  tl-mb80 flex-wrap p-relative'>
        <div className='left-connector-image'>
          <img height='650px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/left-connector-dark.png' />
        </div>
        <div className='tab-2-left w-45 image-center'>
          <img className='feature-image' height='400px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001.png' />
        </div>
        <div className='right-tab m-w-45 tl-ml40'>
          <div className='tab-heading tl-text32 tl-f500 tl-fsn tl-lh162 tl-mb32 monstreat-heading'>
            {t('heading-2')}
          </div>
          <div className='tab-description tl-fsn tl-fnormal tl-text16 tl-lh162 tl-69778E'>
            {t('description-2')}
          </div>
          <a href='why-trustspace?customScroll=true&id=how-it-works' className='d-flex tl-mt16 theme-color-gradient tl-text16'>{t('cta-2')}</a>
        </div>
      </div>
      <div className='tab-container flex-center justify-center  tl-mb80 flex-wrap p-relative'>
        <div className='left-tab w-45 tl-mr60'>
          <div className='tab-heading tl-text32 tl-f500 tl-fsn tl-lh162 tl-mb32 monstreat-heading'>
            {t('heading-3')}
          </div>
          <div className='tab-description tl-fsn tl-fnormal tl-text16 tl-lh162 tl-69778E'>
            {t('description-3')}
          </div>
          <a href='#faq' className='d-flex tl-mt16 theme-color-gradient tl-text16'>{t('cta-2')}</a>
        </div>
        <div className='right-tab tab-3-right m-w-45 image-center'>
          <img className='feature-image' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/TS-approach-4.png' height='400px' />
        </div>
        <div className='right-connector-image'>
          <img height='700px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/left-connector-dark.png' />
        </div>
      </div>
    </div>
  )
}

export default Feature
