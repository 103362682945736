import React, { useState, useEffect } from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { useTranslation } from 'react-i18next'
import '../../assets/stylesheets/components/shared/contact.scss'
import { sendConversionEvent } from '../../helpers/Utils'

const CustomForm = ({ status, message, onValidated, buttonText, showModal, conversionEvent }) => {
  const { t } = useTranslation(['contact'])
  const [state, setState] = useState({
    username: '',
    userEmail: '',
    userQuery: '',
    userFone: '',
    userCompany: '',
    usernameError: false,
    userEmailError: false,
    userCompanyError: false,
    showLoader: false
  })

  const onUserEmailFocusOut = (event) => {
    setState({ ...state, userEmail: event.target.value, userEmailError: false })
  }

  function showModalInParent () {
    showModal()
  }

  const onUserPhoneFocusOut = (event) => {
    setState({ ...state, userFone: event.target.value })
  }

  const onUsernameFocusOut = (event) => {
    setState({ ...state, username: event.target.value, usernameError: false })
  }

  const onUserQueryFocusOut = (event) => {
    setState({ ...state, userQuery: event.target.value })
  }

  const onUserCompanyFocusOut = (event) => {
    setState({ ...state, userCompany: event.target.value, userCompanyError: false })
  }

  function getErrorMessage (message) {
    if (message && typeof (message) === 'string' && message.includes('is already subscribed to')) {
      return message.replace(/<a\b[^>]*>(.*?)<\/a>/i, '')
    }
    return message
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let usernameError = false
    let userEmailError = false
    let userCompanyError = false
    if (state.username && state.userCompany && state.userEmail && validateEmail(state.userEmail)) {
      setState({ ...state, showLoader: true })
      sendConversionEvent(conversionEvent)
      onValidated({
        MERGE0: state.userEmail,
        MERGE2: state.username,
        MERGE3: state.userQuery,
        MERGE1: state.userFone,
        MERGE4: state.userCompany
      })
    } else {
      if (!state.username) { usernameError = true }
      if (!state.userEmail || !validateEmail(state.userEmail)) { userEmailError = true }
      if (!state.userCompany) { userCompanyError = true }
      setState({ ...state, userEmailError, usernameError, userCompanyError })
    }
  }

  useEffect(() => {
    if (status === 'success') {
      setState({ ...state, showLoader: false })
      showModalInParent()
    }
    if (status === 'error') {
      setState({ ...state, showLoader: false })
    }
  }, [status])

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  return (
    <div className='form-wrapper'>
      <form
        className='mc__form'
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className='mc__field-container subscribe-container'>
          <div className='input-wrapper d-flex flex-wrap'>
            <div className='input-field tl-mr20 tl-mb20 '>
              <span className='fa fa-user theme-color-gradient tl-mr8' />
              <span><input
                placeholder={t('name')} onChange={onUsernameFocusOut}
                value={state.username}
                name='MERGE2'
                id='MERGE2'
                autoComplete='off'
                className='input-area tl-929DB0 tl-text12 tl-lh170' type='text'
                    />
              </span>
            </div>
            <div className={state.userEmailError ? 'input-field tl-mb20 error-border' : 'input-field tl-mb20'}>
              <span className='fa fa-envelope theme-color-gradient tl-mr8' />
              <span><input
                name='MERGE0'
                id='MERGE0'
                placeholder={t('email')} onChange={onUserEmailFocusOut}
                value={state.userEmail}
                autoComplete='off'
                className='input-area tl-929DB0 tl-text12 tl-lh170' type='text'
                    />
              </span>
            </div>
            <div className={state.userCompanyError ? 'input-field tl-mb20 error-border w-100' : 'input-field tl-mb20 w-100'}>
              <span className='fa fa-building-o theme-color-gradient tl-mr8' />
              <span><input
                name='MERGE4'
                id='MERGE4'
                placeholder={t('company')} onChange={onUserCompanyFocusOut}
                value={state.userCompany}
                autoComplete='off'
                className='input-area tl-929DB0 tl-text12 tl-lh170' type='text'
                    />
              </span>
            </div>
            <div className='input-field tl-mb20'>
              <span className='fa fa-mobile theme-color-gradient tl-mr8' />
              <span><input
                name='MERGE1'
                id='MERGE1'
                placeholder={t('phone')} onChange={onUserPhoneFocusOut}
                value={state.userFone}
                autoComplete='off'
                className='input-area tl-929DB0 tl-text12 tl-lh170' type='number'
                    />
              </span>
            </div>
            <div className='text-area-wrapper tl-mb12'>
              <div className='text-area-field'>
                <span className='fa fa-pencil-square-o theme-color-gradient tl-fnormal tl-ml8' />
                <span><textarea
                  name='MERGE3'
                  id='MERGE3'
                  type='text' placeholder={t('additionalInfo')} onChange={onUserQueryFocusOut}
                  value={state.userQuery}
                  autoComplete='off'
                  className='text-area-input tl-929DB0 tl-text12 tl-lh170'
                      />
                </span>
              </div>
            </div>
            <button onClick={(e) => handleSubmit(e)} className='request-button'>
              {state.showLoader && <span className='fa fa-spinner animated-spinner' />}
              {!state.showLoader && <span>{buttonText}</span>}
            </button>
          </div>
        </div>
        {status === 'error' && (
          <div
            className='mc__alert error-message'
            dangerouslySetInnerHTML={{ __html: getErrorMessage(message) }}
          />
        )}
      </form>
    </div>
  )
}

const SubscribeForm = props => {
  const url = props.formLink || 'https://trustspace.us20.list-manage.com/subscribe/post?u=647862e0a7f9df1a156351912&id=d4dff5a39f'

  return (

    <div className='mc__form-container'>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
            buttonText={props.buttonText}
            showModal={props.showModal}
            hideModal={props.hideModal}
            conversionEvent={props.conversionEvent}
          />
        )}
      />
    </div>

  )
}

export default SubscribeForm
