export default `{
        "heading": "Frequently Asked Questions",
        "faqList": [
            {
                "label": "What is ISO 27001 / Information Security? ",
                "description": "Information security describes the synergy between IT security, risk management, business continuity management and data protection. The ISO 27001 certificate is the international benchmark for information security and trusted by corporations, insurers and governments worldwide. "
            },
            {
                "label": "What are the benefits of the certificate? ",
                "description": "Among other things, the certificate, or the fulfillment of the standards it contains, can accelerate sales processes by months. After the significant increase in cybercrime during Covid-19 and stricter regulations from the EU, companies are now auditing their suppliers according to the standards of the ISO 27001 standard. Instead of reactively meeting and proving requirements in lengthy processes, the certificate proactively addresses requirements and short-cuts or eliminates due diligence processes.  "
            },
            {
                "label": "I don't need the ISO 27001/TISAX® certification - can TrustSpace still help me? ",
                "description": " Can you imagine not having access to your products or system environment? Whether you are seeking cyber security insurance, need to meet customer requirements beyond ISO 27001/TISAX®, or want to achieve a basic level of protection within your organization. With the help of our modular platform and our experts, we will create a customized solution for you."
            },
            {
                "label": "When is the right time for this project?  ",
                "description": "Yesterday - often companies put off the topic and risk devastating attacks. Due to the broad nature of the content and the preparation time of the project, it is recommended, as a rule of thumb, to start as soon as you have more than 10 employees and/or are targeting enterprise customers/public contracts. Whether you are aiming for ISO 27001 or TISAX®, or 'just' want to implement a resilient security management system, start on time."
            },
            {
                "label": "How long does it take to implement an ISMS and how much does it cost?  ",
                "description": "The duration depends on how many IT security measures and processes have been established within the company. With sufficient resources, one is usually audit-ready within three to five months. The costs are, just like an ISMS, very individual, - depending on the protection needs of your company and whether the entire IT security should be managed via TrustSpace or whether your company is only looking for a management platform with 'the bare essentials'. "
            },
            {
                "label": "I don't have personnel capacity for this project, can I still get the certificate? ",
                "description": "The implementation of an ISMS always involves an effort. Using former analog processes, implementation would not be possible without at least one part-time position and the consultation of a dedicated costly consultancy. However, our overarching goal with TrustSpace is compliance workflow automation. Using our API integrations and fully-integrated employee management system, we replace a lot of manual tasks, so we take a lot of the work off your hands. "
            },
            {
                "label": "What is the TISAX® certificate and when is it relevant for my organization? ",
                "description": "The TISAX® (Trusted Information Security Assessment Exchange) describes a test and exchange procedure that is intended to ensure information security in the automotive industry. During an assessment, conformity with the requirements of the VDA Information Security Assessment (VDA ISA) is checked. In principle, certification is relevant for every company involved in the planning and manufacturing process of automobile manufacturers."
            },
            {
                "label": "What are the differences between ISO 27001 and TISAX®? ",
                "description": " In general, the TISAX® catalog of requirements consists of three modules: information security, prototype protection and data protection. The module on information security is always the subject of the assessment and the other two modules are only used optionally. The information security requirements are derived from the ISO 27001 standard and are almost identical, with the exception of minimal differences in terms of the areas of application, the testing process and the qualification of the measures. Both certifications focus on the implementation of an information security management system and the adequate implementation of measures that ensure the protection of sensitive information."
            }
        ]
}`
