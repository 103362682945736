import whyTrustspace from './pages/de/why-trustspace'
import isoCompliance from './pages/de/iso-compliance'
import aboutUs from './pages/de/about-us'
import careers from './pages/de/careers'
import integrations from './pages/de/integrations'
import faqs from './pages/de/faq'
import cyberSecurity from './pages/de/cyber-security.json'
import header from './pages/de/header.json'
import helpCenter from './pages/de/help-center'
import partner from './pages/de/partner'
import feature from './pages/de/feature'
import industries from './pages/de/industries.json'

export default {
  pages: {
    'why-trustspace': whyTrustspace,
    'iso-compliance': isoCompliance,
    'about-us': aboutUs,
    'help-center': helpCenter,
    careers,
    integrations,
    faqs,
    'cyber-security': JSON.stringify(cyberSecurity),
    industries: JSON.stringify(industries),
    partner,
    feature
  },
  common: {
    automateCompliance: `{
      "heading": "Ready to automate compliance?",
      "image": {
          "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/careers/automate-compliance.png",
          "height": "400px"
      },
      "cta": {
        "Component": "RequestADemo"
      }
    }`,
    header: JSON.stringify(header)
  },
  text: {
    requestADemo: 'Demo vereinbaren',
    letsTalkCyberSecurity: 'Lassen Sie uns sprechen'
  },
  header: {
    trustLane: 'TrustSpace',
    product: 'Product',
    customer: 'Customer',
    resources: 'Resources',
    company: 'Company',
    signIn: 'Sign In',
    requestADemo: 'Demo vereinbaren',
    whyTrustspace: 'Warum TrustSpace',
    isoCompliance: 'ISO Compliance',
    aboutUs: 'Über uns',
    careers: 'Careers',
    integrations: 'Integrations'
  },
  heroImage: {
    yourCyberSpace: 'Ihre Sicherheit und Compliance',
    onAuto: '- auf Autopilot',
    heroDesc:
      'Unsere Kunden profitieren vom Zusammenspiel der beiden Welten, ISMS-Software und erfahrenen IT-Sicherheits & -Compliance Berater. Wir ermöglichen ihnen das effizienteste und effektivste ISMS auf dem Markt.',
    requestADemo: 'Kostenloses Erstgespräch buchen',
    cta: 'Mehr erfahren'
  },
  whyUs: {
    ourValues: 'ISO 27001/TISAX® Compliance',
    why: 'Vorsprung',
    trustLane: 'durch Compliance',
    heading1: 'Skaliere schneller',
    description1:
      'Mit einer Zertifizierung überspringen Sie Due Dilligence Prozesse von potenziellen Großkunden.',
    heading2: 'Skaliere sicherer​',
    description2:
      'Neue Vendoren und Mitarbeiter werden direkt in Ihr ISMS eingebunden.',
    heading3: 'Eine einzige Plattform​',
    description3: 'TrustSpace ist die einzige Plattform für IT Compliance und Security die Sie brauchen.',
    heading4: 'Starkes Netzwerk​',
    description4:
      'Profitieren Sie von unserem Netzwerk aus erfahrenen ISO-Auditoren und Beratern.'
  },
  footer: {
    company: 'Über uns',
    whoAreWe: 'Wer sind wir?',
    careers: 'Karriere',
    blog: 'Blog',
    press: 'Presse',
    product: 'Produkt',
    helpAndService: 'Hilfe & Service',
    contact: 'Kontakt',
    customer: 'Kunden',
    resources: 'Resources',
    privacyPolicy: 'Datenschutz',
    termsOfService: 'AGB',
    impressum: 'Impressum',
    legalNotice: 'Rechtliche Hinweise',
    termsAndCondition: 'Allgemeine Nutzungsbedingungen'
  },
  contact: {
    requestDemo: 'Demo vereinbaren',
    additionalInfo: 'Zusätzliche Informationen',
    name: 'Name',
    email: 'E-Mail',
    phone: 'Telefonnummer',
    componentSubHeading: 'Automatisieren Sie Ihre IT-Compliance',
    getStarted: 'Bereit?',
    company: 'Unternehmen',
    marketingPermission: 'Mit der Übermittlung meiner Daten stimme ich zu, Produkt- und Marketing E-Mails von TrustSpace zu erhalten. Sie könne Ihre Zustimmung jederzeit durch den entsprechenden Link in der Fußzeile unserer E-Mails widerrufen. Informationen zu unseren Datenschutzpraktiken finden Sie auf unserer Website.'
  },
  clientsFeedback: {
    feedbacksFrom: '',
    ourClients: 'Kunden & Partner',
    feedback: 'Feedback',
    mariosfeedback:
      'Die Plattform ist nicht nur für den Anwender selbst leicht zu bedienen, sondern auch für uns Auditoren. Alle Anforderungen werden strukturiert und effizient nachgewiesen, was meine Aufgabe als Auditor enorm beschleunigt und erleichtert.',
    bfmtAuditFeedback: 'Die BFMT-Gruppe legt großen Wert auf Qualität und Sicherheit und ist bestrebt, ihre Kunden bei der Erfüllung von Compliance-Anforderungen zu unterstützen. In enger Zusammenarbeit mit TrustSpace haben unsere gemeinsamen Kunden sowohl von der Expertise im Bereich der Informationssicherheitsstandards als auch von den innovativen Lösungen für die IT-Sicherheit profitiert.  Wir sind stolz auf die bisher erreichten Erfolge in unserer Partnerschaft und freuen uns darauf, auch in Zukunft gemeinsam unseren Kunden einen erheblichen Mehrwert zu bieten.',
    munichFeedback: 'TrustSpace ist unser ganzheitlicher Informationssicherheits-Partner und ermöglicht uns, dass unser ISMS mit unserem Unternehmen zusammen wächst - wir freuen uns auf die weitere Zusammenarbeit.',
    opwFeedback: 'Mit TrustSpace haben wir einen langfristigen Partner gefunden, der uns sehr effizient auf das TISAX®-Audit vorbereitet hat und damit einhergehende komplexe Themen wie Risiko- und Assetmanagement oder die Maßnahmensteuerung vorangetrieben hat.'
  },
  features: {
    'description-3': 'Mit unserer Plattform wird Informationssicherheit zu einem integrativen Bestandteil Ihres Unternehmens und ermöglicht Ihnen die kontinuierliche Einhaltung regulatorischer Anforderungen sowie das problemlose Bestehen wiederkehrender Audits. So erfüllen Sie nicht nur einmalig Kunden- und Zertifizierungsanforderungen sondern erhöhen die Resilienz Ihres Unternehmens langfristig.',
    'heading-3': 'Ganzheitliche Informationssicherheit',
    'description-2': 'Mit unserer Plattform lassen Sie endlose Excel-Tabellen und Dokumenten-Chaos hinter sich und erfüllen Kunden- und Zertifizierungsanforderungen mit Leichtigkeit. So durchlaufen Sie den Zertifizierungsprozess nach internationalen Standards (z.B. ISO 27001 oder TISAX) ohne die Bindung interner Ressourcen oder der kontinuierlichen Unterstützung von Beratungen und sichern Ihr Unternehmen ganzheitlich ab.',
    'heading-2': 'Mit TrustSpace zur ISO 27001 oder TISAX® Zertifizierung​',
    'description-1': 'Mit der ISO 27001 Zertifizierung weisen Sie mühelos einen weltweit anerkannten IT-Sicherheitsstandard nach, der mittlerweile von einer zunehmenden Anzahl an Unternehmen vorausgesetzt wird. Das TISAX® Zertifikat ist der geltende Standard für Informations- und Cybersicherheit in der Automobilindustrie. Mit einer Zertifizierung erschließen Sie einen Markt an potenziellen Kunden und Partnern.',
    'heading-1': 'Die ISO 27001/TISAX® als Türöffner',
    takeALook: 'Wir denken',
    distinctiveFeature: 'IT-Sicherheit neu',
    'cta-1': 'In 5 Schritten zum Zertifikat',
    'cta-2': 'Mehr erfahren'
  },
  featuredArticle: {
    heading: 'Alles rund um Informationssicherheit'
  }
}
