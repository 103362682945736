import React, { useState } from 'react'
import '../../assets/stylesheets/components/shared/featuresWithHeading.scss'
import Cta from './CTA'

function FeaturesWithHeading ({
  content,
  centerHeading = false,
  showBoxShadow = false,
  decreaseHeight
}) {
  const [activePartner, updateActivePartner] = useState(content[0])
  const [activeIndex, updateActiveIndex] = useState(0)
  const partnerLength = content && content.length

  function updateSelectedIndex (index) {
    const activeElement = content[index]
    if (activeElement) {
      updateActivePartner(activeElement)
      updateActiveIndex(index)
    }
  }

  function handleNextNavigation () {
    if (activeIndex < partnerLength - 1) {
      updateSelectedIndex(activeIndex + 1)
    }
    if (activeIndex === partnerLength - 1) {
      updateSelectedIndex(0)
    }
  }

  function handlePreviousNavigation () {
    if (activeIndex > 0) {
      updateSelectedIndex(activeIndex - 1)
    }
    if (activeIndex === 0) {
      updateSelectedIndex(partnerLength - 1)
    }
  }

  return (
    <div className='feature-with-heading-wrapper section'>
      <div className='background-container connector-container'>
        <div
          className={`tab-container flex-center justify-center ${decreaseHeight ? 'decrease-heigh' : ''} ${showBoxShadow ? 'box-with-shadow' : 'box-without-shadow'
            }`}
        >
          <div className='content-tab'>
            {/* <div>
              {activePartner.heading
                ? (
                  <div
                    className={`tl-text32 tl-f500 tl-fsn tl-lh162 tl-mb40 monstreat-heading ${centerHeading ? 'tc' : ''
                      }`}
                  >
                    {activePartner.heading}
                  </div>)
                : (
                  <div className={`flex-center tl-mb40 ${centerHeading ? 'tc' : ''}`}>
                    <img
                      height={activePartner.logoHeading.height ? activePartner.logoHeading.height : 'auto'}
                      src={activePartner.logoHeading.src}
                      alt={activePartner.lawpilot}
                    />
                  </div>
                  )}
            </div> */}
            {content && content.length > 1 &&
              <div className='navigation-container d-flex flex-center'>
                <span className='fa fa-angle-left flex-center navigation-icon' onClick={(e) => handlePreviousNavigation(e)} />
                <span className='fa fa-angle-right flex-center navigation-icon' onClick={(e) => handleNextNavigation()} />
              </div>}
            <div className='reverse-container'>
              <div className='d-flex flex-column justify-space-between'>
                {activePartner.description && (
                  <div className='desc tl-fsn tl-fnormal tl-text16 tl-lh162 tl-69778E'>
                    {activePartner.description}
                  </div>
                )}
                {activePartner.cta && <div className='tl-mt12 flex-center'><Cta cta={activePartner.cta} /></div>}
              </div>
              <div>
                {activePartner.image && (
                  <div className='image-wrapper tl-mb40'>
                    <img
                      className='image-tab'
                      height={activePartner.image.height ? activePartner.image.height : 'auto'}
                      src={activePartner.image.src}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturesWithHeading
