export function CertificateWrapper () {
  return [
    {
      name: 'MadeInGermanyLogo',
      image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/certificates/made-in-germany-certificate.jpg',
      height: '80px'
    },
    {
      name: 'AllianzLogo',
      image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/Logo_Allianz_fuer_Cyber-Sicherheit_Teilnehmer.jpg',
      height: '80px'
    },
    {
      name: 'MicrosoftLogo',
      image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/MS_Startups_Celebration_Badge_Light.png',
      height: '80px'
    },
    {
      name: 'LogoBVMW',
      image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/Logo-bvmw.png',
      height: '80px'
    },
    {
      name: 'BitkomLogo',
      image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/MIB_RGB_DE.png',
      height: '50px'
    }
  ]
}

export function serviceCertificates () {
  return [
    {
      name: 'ISO 27001 as a service'
    },
    {
      name: 'Services for TISAX®'
    },
    {
      name: 'C5 as a service'
    }
  ]
}
