import React from 'react'
import { useTranslation } from 'react-i18next'
import Footer from './shared/Footer'
import ContactUs from './shared/ContactUs'
import PageHeader from './shared/PageHeader'
import Features from './shared/Features'
// import Card from './shared/Card'
import Tiles from './shared/Tiles'
import Timeline from './shared/Timeline'
import Faq from './faq/Faq'

function WhyTrustSpace () {
  const { t } = useTranslation(['pages'])
  const { pageHeader, features, tiles, timeline, faq } = JSON.parse(t('why-trustspace'))
  return (
    <div className='why-trustspace overflow-hidden'>
      <PageHeader
        tiles={pageHeader.tiles}
        animationData={pageHeader.animationData}
        hideBrands
        showAnimationContainer
        showAssociations
      />
      <Faq
        heading={faq.heading}
        faqList={faq.faqList}
      />
      <Tiles
        heading={tiles.heading}
        content={tiles.content}
      />
      {/* <Card
        heading={card.heading}
        description={card.description}
        cta={card.cta}
      /> */}
      <Timeline
        heading={timeline.heading}
        description={timeline.description}
        content={timeline.content}
        cta={timeline.cta}
        id='5-steps-process'
      />
      <Features
        heading={features.heading}
        content={features.content}
        squaresWithoutColor
        showBoxShadow
        cta={features.cta}
        id='how-it-works'
      />
      <ContactUs />
      <Footer />
    </div>
  )
}
export default WhyTrustSpace
