import React from 'react'
import { useTranslation } from 'react-i18next'

import MailchimpForm from '../static-components/MailchimpForm'
import '../../assets/stylesheets/components/shared/contact.scss'

function ContactUs ({ heading = '', isEmailCollectionForm, buttonText = '', onSubscriptionSuccessFromForm }) {
  const { t } = useTranslation(['contact'])
  function onSubscriptionSuccess () {
    onSubscriptionSuccessFromForm && onSubscriptionSuccessFromForm()
  }
  return (
    <div className='contact-container flex-center tl-mt60 p-relative' id='contact-us'>
      <div className='left-connector-image'>
        <img height='600px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/left-connector-dark.png' />
      </div>
      <div className='container'>
        <div className='contact-wrapper'>
          {!isEmailCollectionForm &&
            <div className='component-heading tl-text18 tl-001233 tl-lh140 tl-fnormal tl-fsn tl-mb12'>
              {t('getStarted')}
            </div>}
          {!isEmailCollectionForm &&
            <div className='component-sub-heading tl-001233 tl-lh140 tl-f600 tl-text32 monstreat-heading tl-mb32'>
              {heading || t('componentSubHeading')}
            </div>}
          <MailchimpForm buttonText={buttonText} onSubscriptionSuccess={onSubscriptionSuccess} />
        </div>
      </div>
      <div className='right-connector-image'>
        <img height='350px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/left-connector-dark.png' />
      </div>
    </div>
  )
}

export default ContactUs
