import React from 'react'
import '../../assets/stylesheets/components/shared/footer.scss'
import LogoSection from '../home/LogoSection'
import { useTranslation } from 'react-i18next'
import * as utils from '../../helpers/Utils'

function Footer (props) {
  const { t } = useTranslation(['footer'])

  function onRequestDemoCTAClick () {
    utils.sendConversionEvent('AW-10888242046/qKbYCL2R7r0DEP7G9cco')
    utils.openCalendlyView()
  }

  return (
    <footer className='footer-container'>
      <div className='footer-color-container'>
        <div className='footer-wrapper-main container'>
          <div className='footer-wrapper tl-mt12'>
            <span className='d-flex'>
              <a href=''><img height='20px' className='tl-mr12' src='https://i0.wp.com/blog.trustspace.io/wp-content/uploads/2022/03/TS-Text-Logo.png?w=1568&ssl=1' /></a>
            </span>
            <span className='tl-text14 tl-mb4 tl-lh162 tl-fsn tl-fnormal tl-mt4 tl-808080'>Habersaathstraße 58 10115 Berlin</span>
            <span className='tl-text14 tl-mb4 tl-lh162 tl-fsn tl-fnormal tl-mt4 tl-808080'>info@trustspace.io</span>
          </div>
          <div className='footer-wrapper'>
            <span className='tl-text14 tl-lh170 tl-fsn tl-f500 tl-mb12 tl-54595f'>{t('company')}</span>
            <a href='/about-us' className='tl-text14 tl-mt4 tl-text14 tl-mb4 tl-lh162 tl-fsn tl-fnormal tl-808080'>{t('whoAreWe')}</a>
            <a href='/careers' className='tl-text14 tl-mt4 tl-text14 tl-mb4 tl-lh162 tl-fsn tl-fnormal tl-808080'>{t('careers')}</a>
            <a href='https://blog.trustspace.io/' target='_blank' rel='noreferrer' className='tl-text14 tl-mt4 tl-text14 tl-mb4 tl-lh162 tl-fsn tl-fnormal tl-808080'>{t('blog')}</a>
            <a href='' className='tl-text14 tl-mt4 tl-text14 tl-mb4 tl-lh162 tl-fsn tl-fnormal tl-808080'>{t('press')}</a>
            <a href='https://de.linkedin.com/company/trustlanegermany' target='_blank' className='service tl-mt4 fa fa-linkedin theme-color-gradient tl-text22' rel='noreferrer' />
          </div>
          <div className='footer-wrapper'>
            <span className='tl-text14 tl-lh170 tl-fsn tl-f500 tl-mb12 tl-54595f'>{t('product')}</span>
            <a href='/why-trustspace?customScroll=true&id=5-steps-process' className='tl-text14 tl-mt4 tl-mb4 tl-lh162 tl-fsn tl-fnormal tl-808080'>ISO 27001</a>
            <a href='/why-trustspace?customScroll=true&id=5-steps-process' className='tl-text14 tl-mt4 tl-mb4 tl-lh162 tl-fsn tl-fnormal tl-808080'>Services for TISAX®</a>
            <a href='/why-trustspace?customScroll=true&id=5-steps-process' className='tl-text14 tl-mt4 tl-mb4 tl-lh162 tl-fsn tl-fnormal tl-808080'>C5</a>
          </div>
          <div className='footer-wrapper'>
            <span className='tl-text14 tl-lh170 tl-fsn tl-f500 tl-mb12 tl-54595f'>{t('helpAndService')}</span>
            <a href='/#faq' className='tl-text14 tl-mt4 tl-mb4 tl-lh162 tl-fsn tl-fnormal tl-808080'>FAQ</a>
            <p onClick={onRequestDemoCTAClick} className='tl-text14 tl-mt4 tl-mb4 tl-lh162 tl-fsn tl-fnormal tl-808080 cursor-pointer'>{t('contact')}</p>
            <a href='' className='tl-text14 tl-mt4 tl-mb4 tl-lh162 tl-fsn tl-fnormal tl-808080'>Newsletter</a>
          </div>
          <div className='footer-wrapper'>
            <span className='tl-text14 tl-lh170 tl-fsn tl-f500 tl-mb12 tl-54595f'>{t('legalNotice')}</span>
            <a href='/legal-terms' className='tl-text14 tl-mt4 tl-mb4 tl-lh162 tl-fsn tl-fnormal tl-808080'>{t('termsAndCondition')}</a>
            <a href='/privacy-policy' className='tl-text14 tl-mt4 tl-mb4 tl-lh162 tl-fsn tl-fnormal tl-808080'>{t('privacyPolicy')}</a>
            <a href='' className='tl-text14 tl-mt4 tl-mb4 tl-lh162 tl-fsn tl-fnormal tl-808080'>Cookies</a>
            <a href='/impressum' className='tl-text14 tl-mt4 tl-mb4 tl-lh162 tl-fsn tl-fnormal tl-808080'>{t('impressum')}</a>
          </div>
        </div>
      </div>
      <div className='footer-logo-wrapper'>
        <LogoSection />
      </div>
      <div className='footer-sub-wrapper'>
        <span className='tl-f600 tl-text20 tl-001233'>
          <img height='20px' className='tl-mr12' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/TrustSpace-logo.png' />TrustSpace
        </span>
        <span className='tl-text16 tl-69778E tl-ml16 tl-text14 tl-lh162 tl-fsn tl-fnormal'>© 2022 TrustSpace. All Rights Reserved.</span>
      </div>
    </footer>
  )
}
export default Footer
