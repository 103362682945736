export default `{
    "pageHeader": {
        "heading": "ISO 27001-Compliance",
        "description": "TrustSpace automatisiert die Vorbereitung auf das ISO 27001 Audit und den kontinuierlichen Erhalt des Standards.",
        "cta": {
            "Component": "RequestADemo",
            "props": {
                "text": "Vereinbaren Sie eine Demo"
            }
        }
    },
    "tiles": {
        "heading": "Eine all-In-one Plattform mit dem Ziel, Cyber Security zu entmystifizieren",
        "content": [
            {
                "heading": "Set-Up",
                "description": "Verbinden Sie ihre Cloud Tools mit unserer Plattform",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/connect.png"
                }
            },
            {
                "heading": "Monitoring",
                "description": "Unserer Plattform passt sich dem Bedarf Ihres Sicherheitskonzeptes an.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/scan-and-monitor.png"
                }
            },
            {
                "heading": "Audit-Vorbereitung",
                "description": "Mithilfe unseres Partner Netzwerkes erhalten Sie Ihr Zertifikat in wenigen Monaten.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/get-ready-for-pass.png"
                }
            },
            {
                "heading": "Compliance",
                "description": "Compliance ist kein einmaliges Projekt sondern ein kontinuierliches Ziel",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/continually-maintain.png"
                }
            }
        ]
    },
    "card": {
        "heading": "ISO 27001: Ein weltweit anerkannter Standard",
        "description": "Die ISO 27001 Norm gilt als internationale Benchmark um den Sicherheitsstand in einem Unternehmen zu bewerten. Ein zertifiziertes ISMS bestätigt IT-Compliance gegenüber Kunden, Partnern und weiteren Stakeholdern.",
        "cta": {
            "Component": "subscribeDemo",
            "props": {
                "text": "Download Checkliste",
                "isEmailCollectionForm": "1",
                "buttonText": "Download Checkliste",
                "popupHeading":"Get Your FREE List of Requirements",
                "formLink": "https://trustspace.us20.list-manage.com/subscribe/post?u=647862e0a7f9df1a156351912&id=1674791dd1"
            }
        }
    },
    "timeline": {
        "heading": "In 5 Schritten zum Zertifikat",
        "description": "Im Durchschnitt benötigen unsere Kunden 12 Wochen für die Einführung eines ISMS",
        "content": [
            {
                "boldHeading": "Phase 1",
                "heading": "Roadmap-Erstellung",
                "description": "Basierend auf einer individuellen Risikobewertung entwickeln wir eine Roadmap zu Ihrer ISO 27001 oder TISAX® Zertifizierung.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/phase-1.png"
                }
            },
            {
                "boldHeading": "Phase 2",
                "heading": "Set-up der Plattform",
                "description": "Wir verknüpfen unseren Security Engine mit Ihrer Systemumgebung und errichten die Grundlage für Ihr ISMS.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/phase-2.png"
                }
            },
            {
                "boldHeading": "Phase 3",
                "heading": "Interne Vorbereitung",
                "description": "Unsere vorgefertigten Richtlinien verteilen Sie mittels unseres Dokumentenmangements automatisiert an alle MitarbeiterInnen.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/pase-3.png"
                }
            },
            {
                "boldHeading": "Phase 4",
                "heading": "ISO-Audit / TISAX®-Assessment",
                "description": "Vermittlung an einen Auditor aus unserem Netzwerk - wir garantieren das ISO 27001/TISAX® Zertifikat.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/phase-4.png"
                }
            },
            {
                "boldHeading": "Phase 5",
                "heading": "Kontinuierliche Compliance",
                "description": "Mit dem TrustSpace-Security-Engine verfällt Ihr Sicherheitsstandard nicht, sondern verbessert sich sogar kontinuierlich.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/phase-5.png"
                }
            }
        ],
        "cta": {
            "Component": "RequestADemo",
            "props": {
                "text": "Get Started Today",
                "centered": true
            }
        }
    },
    "features": {
        "heading": "Wie funktioniert TrustSpace?",
        "content": [
            {
                "heading": "Intuitives Erfüllen formaler Anforderungen",
                "description": "Wir überladen Sie nicht mit Checklisten und halbfertigen Richtlinien. Mit unserer Plattform erfüllen Sie Anforderungen intuitiv und binden Ihre Mitarbeiter problemlos mit ein, vom Verteilen und Sammeln notwendiger Richtlinien bis hin zum automatisierten Zugriffsmanagement bieten wir Ihnen eine End-to-End Lösung.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/how-it-works.png",
                    "height": "600px"
                },
                "list": {
                    "heading": "Dokumenten-Management",
                    "items": [
                        "Zugriff auf alle notwendigen Richtlinien, die von Auditoren erstellt wurden",
                        "Automatisiertes Bearbeiten, Verteilen und Einsammeln notwendiger Richtlinien, alles ISO-/TISAX®-konform"
                    ]
                }
            },
            {
                "heading": "Weitreichende Automatisierungen",
                "description": "Mit TrustSpace stellen Sie sicher, dass alle Vendoren und Integrationen in Ihre Sicherheitsarchitektur integriert sind und der ISO 27001-Standard eingehalten ist.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/scan-secure.png",
                    "height": "600px"
                },
                "list": {
                    "heading": "Automatisiertes Vendoren-Management",
                    "items": [
                        "Verbinden Sie alle cloud-basierten Tools wie AWS, die Google Cloud oder Office 365 mit einem Klick",
                        "Compliance-Reports werden kontinuierlich erstellt und Unregelmäßigkeiten werden fürhzeitig erkannt"
                    ]
                }
            },
            {
                "heading": "Eine ganzheitliche IT-Sicherheitslösung",
                "description": "Mithilfe von direkten Integrationen und einer umfangreichen Datenbank verbessern wir die initiale Bewertung und kontinuierliche Behandlung potenzieller Risikoszenarien.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/access-and-manage.png",
                    "height": "600px"
                },
                "list": {
                    "heading": "Ihr Asset-Management",
                    "items": [
                        "Verwalten Sie alle Dienstleister und einhergehende Risiken - von der Reinigungsfirma bis hin zu Personio",
                        "Erstellen Sie automatisierte Compliance Reports für Kunden & Auditoren"
                    ]
                }
            },
            {
                "heading": "Designed für Start-ups & Mittelständler",
                "description": "Cybersecurity und Informationssicherheit ist ein kontinuierlicher Prozess, kein in sich geschlossenes Projekt. Und mithilfe unseres umfangreichen Compliance-Cockpits behalten Sie immer den Überblick, ein Tool für Ihre gesamte IT-Sicherheit.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/stay-compliant.png",
                    "height": "600px"
                },
                "list": {
                    "heading": "Ihr Compliance-Cockpit",
                    "items": [
                        "Tagesaktuelle Insights zum aktuellen Compliance-Status",
                        "Erhalten Sie konkrete Handlungs- und Verbesserungsempfehlungen"
                    ]
                }
            }
        ],
        "cta": {
            "Component": "RequestADemo",
            "props": {
                "centered": true
            }
        }
    },
    "reviews": {
        "heading": "What customers say about us",
        "content": [
            {
                "name": "Maximilian",
                "designation": "CEO",
                "feedback": "After carefully searching which company could assist us best with achieving ISO 27001, we took a leap of faith with TrustSpace. Looking back, this was the best decision we made.",
                "image": {
                    "src": "https://i.ibb.co/XscnDn8/Vector.png"
                }
            },
            {
                "name": "Charlotte",
                "designation": "Security Officer",
                "feedback": "We reached out to TrustSpace after opting for a specialized consulting firm with which we failed our ISO 27001 audit. TrustSpace guaranteed us the certification and that's exactly what we obtained 3 months later.",
                "image": {
                    "src": "https://i.ibb.co/XscnDn8/Vector.png"
                }
            },
            {
                "name": "Jonas",
                "designation": "CTO",
                "feedback": "We tried to implement an ISMS ourselves following the ISO 27001 requirements. TrustSpace succeeded where we failed miserably. A+ team, I can only recommend working with them.",
                "image": {
                    "src": "https://i.ibb.co/XscnDn8/Vector.png"
                }
            }
        ]
    }
}`
