export default `{
    "pageHeader": {
        "heading": "Wir sind TrustSpace",
        "description": "Wir denken Cybersecurity und Informationssicherheit neu und verbinden in unserem Ansatz Software und menschliche Expertise in einer Plattform."
    },
    "features": {
        "content": [
            {
                "heading": "Die Gründungsgeschichte",
                "description1": "Noch während des Studiums in Barcelona kam Julius zusammen mit einem seiner Professoren die Idee für das, was heute TrustSpace ist. Noch bevor die erste Excel-Mappe erstellt war oder Julius überhaupt die Feinheiten der ISO 27001 Zertifizierung kannte, erkannte Masih das ganze Potenzial der Idee Informationssicherheit-as-a- Service. Begeistert zog Masih prompt für einige Monate ebenfalls in die Studenten-WG zu Julius nach Barcelona, um neben seiner Rolle als Informationssicherheits-Berater die notwendige Expertise in die ersten Brainstorming- und Late- Night-Work-Sessions einfließen zu lassen, und um letztendlich aus der einst verrückten Idee Wirklichkeit werden zu lassen. Ein Jahr fast-forward sitzen Masih und Julius mittlerweile nicht mehr unter der katalanischen Sonne, sondern im schönen Berlin und helfen Start-ups wie Mittelständlern ein sicheres Informationssicherheits-Managementsystem zu implementieren und verbinden mit TrustSpace das Beste aus den Welten Compliance-Monitoring, Cybersecurity und ISO 27001-Beratung.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/founders.png"
                }
            }
        ]
    },
    "foundersFeatures": {
        "heading": "Die Gründungsgeschichte",
        "content": [
            {
                "description1": "Noch während des Studiums in Barcelona kam Julius zusammen mit einem seiner Professoren die Idee für das, was heute TrustSpace ist. Noch bevor die erste Excel-Mappe erstellt war oder Julius überhaupt die Feinheiten der ISO 27001 Zertifizierung kannte, erkannte Masih das ganze Potenzial der Idee Informationssicherheit-as-a- Service.",
                "description2": "Begeistert zog Masih prompt für einige Monate ebenfalls in die Studenten-WG zu Julius nach Barcelona, um neben seiner Rolle als Informationssicherheits-Berater die notwendige Expertise in die ersten Brainstorming- und Late- Night-Work-Sessions einfließen zu lassen, und um letztendlich aus der einst verrückten Idee Wirklichkeit werden zu lassen.",
                "description3": "Ein Jahr fast-forward sitzen Masih und Julius mittlerweile nicht mehr unter der katalanischen Sonne, sondern im schönen Berlin und helfen Start-ups wie Mittelständlern ein sicheres Informationssicherheits-Managementsystem zu implementieren und verbinden mit TrustSpace das Beste aus den Welten Compliance-Monitoring, Cybersecurity und ISO 27001-Beratung.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/founders.png"
                }
            },
            {
                "heading": "Mission und Ansatz",
                "description1": "Unsere Mission ist es, ganzheitliche Informationssicherheit auch für KMU zugänglich zu machen. Bis dato ein Unterfangen, das für die meisten KMU nicht zu bewältigen war, ohne die kostspielige Unterstützung von ISO-Beratern oder internen Experten. Unsere Security Engine TrustSpaceOS ermöglicht es Unternehmen ein skalierbares Sicherheitssystem zu implementieren. Dadurch schützen sich Unternehmen nicht nur selbst, sondern werden gleichzeitig zum attraktiven Partner für große Unternehmen. Diese verlangen mittlerweile von ihren Partnern, Vendoren und Zulieferern, dass umfangreiche Sicherheitsstandards eingehalten werden, oft in Form der ISO 27001 Zertifizierung. Anstatt, dass sich Unternehmen selbst mit der Auswahl und dem Management notwendiger Sicherheitsmaßnahmen quälen, oder kostspieligen Support einkaufen, können wir innerhalb weniger Tage einen individuellen Sicherheitsstandard einführen."
            }
        ]
    },
    "imageCard": {
        "heading": "Unser Office",
        "image": {
            "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/why-us/our-location.png",
            "height": "450px"
        },
        "footer": "Berlin, Germany"
    },
    "card": {
        "heading": "Werde Teil unseres Teams",
        "description": "Wir suchen immer nach Unterstützung und freuen uns auf Deine Bewerbung in den Bereichen Business Development & Growth, Sales & Marketing, Software Development & Product und Talent & Culture.",
        "cta": {
            "text": "Offene Stellen",
            "link": "/careers"
        }
    },
    "reviews": {
        "heading": "What employees say about us",
        "content": [
            {
                "name": "Ashutosh",
                "designation": "Engineering",
                "feedback": "At TrustSpace, I get to work with an empowering team of young people who share the same values of growth and respect. I also have ownership of my work and can contribute to some of the decisions we make.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/ashutosh.jpeg"
                }
            },
            {
                "name": "Ankit",
                "designation": "Engineering",
                "feedback": "I feel aligned with TrustSpace mission to create and optimise IT compliance automation platform for our clients. As an engineer I get to lead the development effort at TrustSpace while working with a wonderful team of engineers.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/ankit-pandey.png"
                }
            }
        ]
    },
    "teamcard": {
        "heading": "Experten, die uns unterstützen",
        "content": [
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Julius%20Gerhard.jpg",
                    "height": "80%"
                },
                "imageCard": {
                    "name": "Julius Gerhard",
                    "position": "Founder",
                    "link": "https://www.linkedin.com/in/juliusgerhard/"
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Masih-Sediquian.png",
                    "height": "90%"
                },
                "imageCard": {
                    "name": "Masih Sediquian",
                    "position": "Founder",
                    "link": "https://www.linkedin.com/in/masih-sediquian-646830225/"
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Tutku.jpeg",
                    "height": "115%"
                },
                "imageCard": {
                    "name": "Tutku Yurdagül",
                    "position": "Accounting & Sales",
                    "link": ""
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Intissar.png",
                    "height": "90%"
                },
                "imageCard": {
                    "name": "Intissar Loudhabachi",
                    "position": "Business Development ",
                    "link": ""
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/userImagePlaceHolder.png",
                    "height": "100%"
                },
                "imageCard": {
                    "name": "Aleeza Joe Schreiner",
                    "position": "Cyber Security Analyst",
                    "link": ""
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/stefania.jpg",
                    "height": "80%"
                },
                "imageCard": {
                    "name": "Stefania Vetere",
                    "position": "Junior Consultant",
                    "link": ""
                }   
            },            
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/userImagePlaceHolder.png",
                    "height": "100%"
                },
                "imageCard": {
                    "name": "Felix Mosler",
                    "position": "Head of Information Security",
                    "link": ""
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Phuc.jpg",
                    "height": "80%"
                },
                "imageCard": {
                    "name": "Truong Hoang Phuc",
                    "position": "Business Development",
                    "link": ""
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Charlotte-Vith.jpg",
                    "height": "100%"
                },
                "imageCard": {
                    "name": "Charlotte Vith",
                    "position": "Business Development",
                    "link": "https://www.linkedin.com/in/charlotte-vith-9aa7191b5/"
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Julius%20Siebert.png",
                    "height": "140%"
                },
                "imageCard": {
                    "name": "Julius Siebert",
                    "position": "Sales Manager",
                    "link": "https://www.linkedin.com/in/julius-siebert-6ab0b322a/"
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Ankit-Gaur.png",
                    "height": "95%"
                },
                "imageCard": {
                    "name": "Ankit Gaur",
                    "position": "Head of Tech",
                    "link": "https://www.linkedin.com/in/ankit-kumar-gaur-822a798a/"
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Ashutosh-Tripathi.jpg",
                    "height": "85%"
                },
                "imageCard": {
                    "name": "Ashutosh Tripathi",
                    "position": "Developer",
                    "link": "https://www.linkedin.com/in/ashutosh2915/"
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Hamid-new.jpeg",
                    "height": "80%"
                },
                "imageCard": {
                    "name": "Hamid Ali",
                    "position": "Developer",
                    "link": "https://www.linkedin.com/in/profile-hamidali/"
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Akash.jpeg",
                    "height": "100%"
                },
                "imageCard": {
                    "name": "Akash Kumar",
                    "position": "Developer",
                    "link": "https://www.linkedin.com/in/developer-akash/"
                }   
            },
            {
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/maharshi.png",
                    "height": "100%"
                },
                "imageCard": {
                    "name": "Maharishi Shah",
                    "position": "Developer",
                    "link": "https://www.linkedin.com/in/maharshi-shah1996ms/"
                }   
            },
            {
                "imageCard": {
                    "name": "Du",
                    "position": "Offene Stellen",
                    "ctaText": "Karriere",
                    "cta": "https://www.trustspace.io/careers"
                }   
            }
        ],
        "contentTeam": [
            {
                "imageCard": {
                    "name": "Mario Arndt",
                    "position": "Geschäftsführer der DEUDAT GmbH & ISO 27001 Lead Auditor",
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/Clients/Mario-DEUDAT.png"
                }   
            },
            {
                "imageCard": {
                    "name": "Robert Jeggle",
                    "position": "Co-Founder von AQUATY & Swiss CPA",
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/team/Robert-Menzel.jpg"
                }   
            },
            {
                "imageCard": {
                    "name": "Benjamin Bachmann",
                    "position": "Vice President Group Information Security Ströer SE",
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/lxzswe.png"
                }   
            },
            {
                "imageCard": {
                    "name": "Tobias Kraus",
                    "position": "Head of Compliance & IT Assurance bei der BFMT Group",
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/tobias.png"
                }   
            }
        ]
    }
}`
