export default `{
    "tiles": {
        "heading": "Unsere Berater adaptieren bestehende Managementsysteme oder fangen auch „auf der grünen Wiese“ an.",
        "content": [
            {
                "heading": "Berater",
                "contentValues": [
                    "Persönlicher InfoSec-Berater",
                    "GAP-Analyse",
                    "CISO-as-a-Service / externer ISB",
                    "Interne Audits",
                    "Audit-Begleitung"
                ]
            },
            {
                "heading": "ISMS Software",
                "contentValues": [
                    "Dokumentenlenkungssystem",
                    "Dokumentenpaket (ISO & TISAX)",
                    "Asset- und Risikomanagement",
                    "Lieferantenmanagement",
                    "ISO 27001 konforme Reportings"
                ]
            },
            {
                "heading": "Cybersecurity Features",
                "contentValues": [
                    "End-Point-Verschlüsselung",
                    "Managed Firewall Services",
                    "Mobile Device Management",
                    "E-Mail Security",
                    "Übernahme der Backup-Routinen"
                ]
            }
        ],
        "requestADemo": {
            "text": "Kostenloses Beratungsgespräch"
        }
    }
}`
