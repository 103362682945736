export default `{
    "pageHeader": {
        "heading": "Always on the safe side with TrustSpace",
        "description": "Whether costs are covered by insurance after a cyberattack depends on whether all necessary cybersecurity measures were activated at the time of the attack. If this was not the case, costs are not covered, even if a dedicated cybersecurity insurance policy was concluded.",
        "imageData": {
            "heading": "Your cybersecurity",
            "subHeading": "- One platform, many options",
            "description": "We work with leading cybersecurity providers to offer our customers state-of-the-art cybersecurity measures.",
            "image": {
                "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/TS-approach-4.png",
                "height": "400px"
            }
        },
        "requestADemo": {
            "props": {
                "text": "Kostenloses Erstgespräch buchen"
            }
        },
        "cta": {
            "text": "Learn more"
        }
    },
    "tiles": {
        "heading": "The cornerstone of your cybersecruity",
        "content": [
            {
                "heading": "Cloud security",
                "description": "Monitor your multi-cloud environments to identify and remediate security risks, reduce your attack surface, and ensure compliance.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/3rdparty-monitoring.png"
                },
                "priceCta": {
                    "text": "17 EUR/month",
                    "link": ""
                }
            },
            {
                "heading": "Endpoint protection",
                "description": "With Intercept X, you can detect malicious encryption processes (ransomware attacks) and stop them before they can spread across the network.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/automated-reporting.png"
                },
                "priceCta": {
                    "text": "From 6,70 EUR per month/user",
                    "link": ""
                }
            },
            {
                "heading": "Email security",
                "description": "Cloud email security protects your employees and important information from malware, phishing, and attempts to impersonate false identities.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/endpoint-security.png"
                },
                "priceCta": {
                    "text": "From 2 EUR per month/user",
                    "link": ""
                }
            }
        ]
    },
    "tiles2": {
        "heading": "Activation within 48h",
        "content": [
            {
                "heading": "Determining the scope of application",
                "description": "In a 15-minute call, we work with you to determine what actions are needed and what you already meet.",
                "num": "1"
            },
            {
                "heading": "Activation call (1.5h)",
                "description": "During the activation call, we introduce you to your new IT security measures and activate them together with you.",
                "num": "2"
            },
            {
                "heading": "Terminal activation",
                "description": "All employees receive the link to download the Sophos agent as a follow-up to the activation call. After successful installation, all devices are secured.",
                "num": "3"
            }
        ]
    },
    "card": {
        "heading1": "And it is not more effort. Within 48h we can secure your infrastructure.",
        "heading2": "Book your free 15-minute initial consultation now.",
        "heading3": "We look forward to meeting you.",
        "cta": {
          "text": "Let's talk",
          "Component": "SubscribeDemo"
        }
      }
}`
