export default `{
    "pageHeader": {
        "heading": "Streamline ISO 27001 Compliance",
        "description": "TrustSpace streamlines the process of getting ISO 27001 certified from start to finish",
        "cta": {
            "Component": "RequestADemo"
        }
    },
    "tiles": {
        "heading": "A powerful all-in-one platform that is easy to use",
        "content": [
            {
                "heading": "Connect",
                "description": "Your tech stack using our integrations.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/connect.png"
                }
            },
            {
                "heading": "Scan, Improve, Monitor",
                "description": "Your cybersecurity eco system.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/scan-and-monitor.png"
                }
            },
            {
                "heading": "Get ready for and pass",
                "description": "Your ISO 27001 audit and future re-audits.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/get-ready-for-pass.png"
                }
            },
            {
                "heading": "Continually maintain",
                "description": "Your ISO compliance years after years.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/continually-maintain.png"
                }
            }
        ]
    },
    "card": {
        "heading": "ISO 27001: An Internationally Recognized Standard",
        "description": "Often required internationally, the ISO 27001 standard specifies the requirements for establishing, implementing, maintaining and continually improving an Information Security Management System (ISMS).",
        "cta": {
            "Component": "subscribeDemo",
            "props": {
                "text": "Download List of Requirements",
                "isEmailCollectionForm": "1",
                "buttonText": "Download List of Requirements",
                "popupHeading":"Get Your FREE List of Requirements",
                "formLink": "https://trustspace.us20.list-manage.com/subscribe/post?u=647862e0a7f9df1a156351912&id=1674791dd1"
            }
        }
    },
    "timeline": {
        "heading": "Our 5 steps process to get you certified",
        "description": "Our customers achieve the certification within 3 to 4 months on average",
        "content": [
            {
                "boldHeading": "Phase 1",
                "heading": "Individual Roadmap",
                "description": "Based on the risk assessment and stock taking we develop a dedicated and tailored roadmap to ISO 27001/TISAX®. ",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/phase-1.png"
                }
            },
            {
                "boldHeading": "Phase 2",
                "heading": "Info Sec Integration",
                "description": "Integration of required security measures and set-up of your turnkey IT security platform.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/phase-2.png"
                }
            },
            {
                "boldHeading": "Phase 3",
                "heading": "Platform Hand-Over",
                "description": "Kick-off and introduction of platform to administrators and employees to self-manage IT security holistically.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/pase-3.png"
                }
            },
            {
                "boldHeading": "Phase 4",
                "heading": "ISO audit / TISAX® assessment",
                "description": "Dedicated preparation phase for ISO 27001 audit/TISAX® assessment and continuous support during audit.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/phase-4.png"
                }
            },
            {
                "boldHeading": "Phase 5",
                "heading": "Continuous Support",
                "description": "Continuous support for annual re-audits, following integrations, and platform extensions.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/phase-5.png"
                }
            }
        ],
        "cta": {
            "Component": "RequestADemo",
            "props": {
                "text": "Get Started Today",
                "centered": true
            }
        }
    },
    "features": {
        "heading": "How It Works",
        "content": [
            {
                "heading": "Build your ISMS",
                "description": "TrustSpace helps you design an ISMS that not only meets the ISO 27001/TISAX® requirements but also aligns with your organization’s needs. Browse our policy’s library, select & implement the essential ones for your organization, and publish them to your employees – all in one place and at a record time.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/how-it-works.png",
                    "height": "600px"
                },
                "list": {
                    "heading": "Key features",
                    "items": [
                        "Access 50+ policies developed by our ISO/TISAX® experts and recommended by our partner auditors.",
                        "Submit evidence to ISO 27001/TISAX® auditors easily"
                    ]
                }
            },
            {
                "heading": "Scan and secure your tech stack",
                "description": "TrustSpace connects with, monitors, and suggests avenues to make your cloud infrastructure compliant. And don’t worry, we only scan through “read-only” access - your data is yours, and only yours.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/scan-secure.png",
                    "height": "600px"
                },
                "list": {
                    "heading": "Key features",
                    "items": [
                        "Monitor over 120+ cloud services including AWS, Google Cloud, and JIRA.",
                        "Implement measures to reduce vulnerabilities."
                    ]
                }
            },
            {
                "heading": "Assess and manage risks",
                "description": "TrustSpace integrates with over 100+ well-known vendors. We fetch their security data on your behalf and provide you with detailed risk reports and tailored solutions to mitigate those risks.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/access-and-manage.png",
                    "height": "600px"
                },
                "list": {
                    "heading": "Key features",
                    "items": [
                        "Perform vendor risk analysis and manage your 3rd parties.",
                        "Collect vendor security certifications & reports to meet ISO 27001/TISAX® requirements."
                    ]
                }
            },
            {
                "heading": "Stay compliant with automated evidence collection",
                "description": "Obtaining the ISO 27001/TISAX® certification is one thing, but to keep it is another. Stay secure year-round 24/7 with real time alerts on non-conformities throughout your tech stack, detected automatically. Our automations will collect the evidence on your behalf while you focus on what matters most: building a successful company.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/stay-compliant.png",
                    "height": "600px"
                },
                "list": {
                    "heading": "Key features",
                    "items": [
                        "Automated evidence collection from 80+ integrations.",
                        "Submit evidence to ISO 27001/TISAX® auditors easily"
                    ]
                }
            }
        ],
        "cta": {
            "Component": "RequestADemo",
            "props": {
                "centered": true
            }
        }
    },
    "reviews": {
        "heading": "What customers say about us",
        "content": [
            {
                "name": "Maximilian",
                "designation": "CEO",
                "feedback": "After carefully searching which company could assist us best with achieving ISO 27001, we took a leap of faith with TrustSpace. Looking back, this was the best decision we made.",
                "image": {
                    "src": "https://i.ibb.co/XscnDn8/Vector.png"
                }
            },
            {
                "name": "Charlotte",
                "designation": "Security Officer",
                "feedback": "We reached out to TrustSpace after opting for a specialized consulting firm with which we failed our ISO 27001 audit. TrustSpace guaranteed us the certification and that's exactly what we obtained 3 months later.",
                "image": {
                    "src": "https://i.ibb.co/XscnDn8/Vector.png"
                }
            },
            {
                "name": "Jonas",
                "designation": "CTO",
                "feedback": "We tried to implement an ISMS ourselves following the ISO 27001 requirements. TrustSpace succeeded where we failed miserably. A+ team, I can only recommend working with them.",
                "image": {
                    "src": "https://i.ibb.co/XscnDn8/Vector.png"
                }
            }
        ]
    }
}`
