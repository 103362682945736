import React from 'react'
import { brandsData } from '../../helpers/BrandWrapper'
import '../../assets/stylesheets/components/shared/brandWrapper.scss'
import ImageSlider from './ImageSlider'
function BrandWrapper (props) {
  return (
    <div className='brands-div'>
      <ImageSlider images={brandsData} slidesToShow={5} />
    </div>
  )
}

export default BrandWrapper
