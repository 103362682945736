import React from 'react'
import HeroImage from './HeroImage'
// import WhyTrustLane from './WhyTrustLane'
import Feature from './Features'
import Clients from './Clients'
import Footer from '../shared/Footer'
import ContactUs from '../shared/ContactUs'
import Faq from '../faq/Faq'
import { useTranslation } from 'react-i18next'
import FetauredArticles from './FeaturedArticles'
import { FeaturedArticleList } from '../../helpers/FeaturedArticles'

function Wrapper () {
  const { t } = useTranslation(['pages'])
  const { faqList, heading } = JSON.parse(t('faqs'))
  const { tiles } = JSON.parse(t('feature'))

  return (
    <div className='dashboard-wrapper overflow-hidden'>
      <HeroImage />
      {/* <WhyTrustLane /> */}
      <Feature heading={tiles.heading} content={tiles.content} cta={tiles.requestADemo} />
      <Clients />
      <FetauredArticles articleList={FeaturedArticleList} />
      <Faq faqList={faqList} heading={heading} />
      <ContactUs />
      <Footer />
    </div>
  )
}
export default Wrapper
