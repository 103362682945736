export default `{
    "pageHeader": {
        "heading": "Unsere Partner",
        "description": "Ein starkes Unternehmen braucht starke Partner: TrustSpace und unsere Services profitieren vom Know-How und der Unterstützung erfahrener Unternehmen aus den Bereichen Tech & Informationssicherheit."
    },
    "features": {
        "content": [
            {
                "heading": "DEUDAT",
                "description": "Die DEUDAT GmbH ist ein inhabergeführtes Unternehmen aus dem Bereich Datenschutz und Informationssicherheit, welches Unternehmen bei der Einführung, Umsetzung und Aufrechterhaltung eines angemessenen Datenschutzniveaus unterstützt. So beraten die Experten und Rechtanwälte Organisationen unabhängig von Branche, Größe oder Ausrichtung. Mit der DEUDAT an unserer Seite profitieren wir als junges Unternehmen selbst, und letztendlich unsere Kunden, täglich von geballtem Wissen und Expertise aus über 25 Jahren Erfahrung im Bereich Informationssicherheit. Das Team aus Beratern und Rechtsanwälten betreut kleine als auch große international ausgerichtete Mittelständler bis hin zu großen Playern aus dem Bereich Kritische Infrastruktur.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/Logo-Deudat.webp",
                    "height": "200px"
                }
            },
            {
                "heading": "Lawpilots",
                "description": "Lawpilots bieten Online-Schulungen in den Rechtsgebieten Datenschutz, Compliance, Arbeitsschutz und Informationssicherheit an. Die Schulungen sind dabei in über 30 Sprach- und Länderversionen verfügbar und bereitet Ihre Mitarbeiter optimal auf die Herausforderungen der Digitalisierung vor. Durch das Online-Format lassen sich die Schulungen dabei nicht nur flexibel in den Arbeitsalltag integrieren, die Lerninhalte werden zudem mit interessanten Beispielen, Gamification, Experteninterviews und Infografiken angereichert. So stellen wir in Kooperation mit den Lawpilots sicher, dass Ihre Mitarbeiter optimal in Compliance-Themen geschult sind.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/Logo-lawpilots.png",
                    "height": "75px"
                }
            },
            {
                "heading": "Techminers",
                "description": "TechMiners ist ein Anbieter von Tech Due Dilligences. Um heutzutage Tech Transaktionen durchzuführen, muss bei allen Parteien ein klares Verständnis der zugrundeliegenden Technologie vorhanden sein, inklusive relevanter Informationssicherheits-Faktoren. Das ermöglicht TechMiners durch proprietäre Software und der Expertise von ehemaligen CTOs mit zusammen über 50 Jahren Erfahrung.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/Techminers-Logo.webp",
                    "height": "55px"
                }
            }
        ]
    }
}`
