import React from 'react'
import { useTranslation } from 'react-i18next'
import Footer from './shared/Footer'
import PageHeader from './shared/PageHeader'
import Tabs from './shared/Tabs'
// import AutomateCompliance from './shared/AutomateCompliance'
import '../assets/stylesheets/components/Careers.scss'

function Careers () {
  const { t } = useTranslation(['pages'])
  const { pageHeader, tabs } = JSON.parse(t('careers'))
  return (
    <div className='careers overflow-hidden'>
      <PageHeader
        heading={pageHeader.heading}
        description={pageHeader.description}
        hideBrands={1}
      />
      <Tabs
        heading={tabs.heading}
        content={tabs.content}
      />
      {/* <AutomateCompliance /> */}
      <Footer />
    </div>
  )
}
export default Careers
