import React, { useState } from 'react'
import Slider from 'react-slick'
import '../../assets/stylesheets/components/shared/imageSlider.scss'

const NextArrow = ({ onClick, updateIconView }) => {
  return (
    <div className={`nextArrow navigation-icon ${updateIconView ? 'nextArrow update-view' : ''}`} onClick={onClick}>
      <span className='fa fa-angle-right' />
    </div>
  )
}

const PrevArrow = ({ onClick, updateIconView }) => {
  return (
    <div className={`prevArrow navigation-icon ${updateIconView ? 'prevArrow update-view' : ''}`} onClick={onClick}>
      <span className='fa fa-angle-left' />
    </div>
  )
}

const ImageSlider = ({ images, slidesToShow = 3, updateIconView }) => {
  const [imageIndex, setImageIndex] = useState(0)

  const settings = {
    centerMode: true,
    infinite: true,
    dots: false,
    speed: 300,
    slidesToShow,
    centerPadding: '10',
    swipeToSlide: true,
    focusOnSelect: true,
    nextArrow: <NextArrow onClick updateIconView={updateIconView} />,
    prevArrow: <PrevArrow onClick updateIconView={updateIconView} />,
    beforeChange: (current, next) => setImageIndex(next),
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1490,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const templateImages = images.map((item, idx) => {
    if (item !== null) {
      return (
        <div
          key={idx}
          className={idx === imageIndex ? 'activeSlide padding' : `slide ${(item.imageCard || item.libraryLogoCard) ? 'no-blur' : ''}`}
        >
          <div className={`slideWrapper ${item.imageCard ? 'updateHeight' : ''}`}>
            {item.image && <img src={item.image} height={item.height} width='auto' />}
            {item.imageCard &&
              <div className='teamcard-wrapper'>
                <div className='teamcard-image'>
                  <img src={item?.imageCard.src} height='100%' width='auto' />
                  <div className='teamcard-name-pos-wrapper'>
                    <span className='name'>{item?.imageCard?.name}</span>
                    <span className='position'>{item?.imageCard?.position}</span>
                  </div>
                </div>
              </div>}
            {item.libraryLogoCard &&
              <div className='library-card-logo-wrapper'>
                <img src={item.libraryLogoCard?.image.src} height='100%' width='auto' />
                <span className='name'>{item.libraryLogoCard?.name}</span>
              </div>}
          </div>
        </div>
      )
    }
    return null
  })

  return <Slider {...settings}>{templateImages}</Slider>
}

export default ImageSlider
