import i18next from '../i18nextConf'

export const clientData = () => {
  return [
    {
      clientName: 'Mario Arndt​',
      designation: 'ISO 27001 Lead Auditor, DEUDAT GmbH​',
      height: 'auto',
      width: '180px',
      borderRadius: '50%',
      clientImage:
        'https://d11jcn2a09nppp.cloudfront.net/website/static-images/Clients/Mario-DEUDAT.png',
      feedback: i18next.t('clientsFeedback:mariosfeedback')
    },
    {
      clientName: 'BFMT Audit GmbH',
      designation: 'Audit Tax Advisory​',
      height: 'auto',
      width: '180px',
      borderRadius: '0%',
      clientImage:
        'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/bfmt.png',
      feedback: i18next.t('clientsFeedback:bfmtAuditFeedback')
    },
    {
      clientName: 'Georg Graf',
      designation: 'Geschäftsführer, Munich Electrification​',
      height: 'auto',
      width: '180px',
      borderRadius: '0%',
      clientImage:
        'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/ME.png',
      feedback: i18next.t('clientsFeedback:munichFeedback'),
      link: 'https://www.munichelectrification.com/'
    },
    {
      clientName: 'Michael Hager',
      designation: 'Projektleiter, Oberndorfer Präzisions-Werk GmbH & Co. KG​',
      height: 'auto',
      width: '180px',
      borderRadius: '0%',
      clientImage:
        'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/opw.png',
      feedback: i18next.t('clientsFeedback:opwFeedback'),
      link: 'https://opw.de/'
    }
  ]
}
