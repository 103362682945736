import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Modal from './Modal'
import CTAButton from './CTAButton'
import Subscribe from '../Subscribe/Subscribe'
import '../../assets/stylesheets/components/shared/requestADemo.scss'
import SubscriptionSuccessMsg from '../Subscribe/SubscriptionSuccessMsg'
function SubscribeDemo ({
  centered = false,
  text,
  isEmailCollectionForm,
  buttonText,
  popupHeading,
  formLink,
  onClick = () => { }
}) {
  const [showModal, setShowModal] = useState(false)
  const [showSuccessModal, setSuccessModal] = useState(false)
  const { t } = useTranslation(['text'])
  const requestADemo = text || t('requestADemo')

  const handleClick = () => {
    setShowModal(true)
    onClick()
  }

  function onSubscriptionSuccessFromForm () {
    setShowModal(false)
    setSuccessModal(true)
  }

  return (
    <>
      <CTAButton centered={centered} text={requestADemo} onClick={handleClick} />
      {showModal && (
        <Modal closeModal={() => setShowModal(false)}>
          <Subscribe
            isEmailCollectionForm={isEmailCollectionForm}
            buttonText={buttonText}
            popupHeading={popupHeading}
            onSubscriptionSuccessFromForm={onSubscriptionSuccessFromForm}
            formLink={formLink}
          />
        </Modal>
      )}
      {showSuccessModal && (
        <Modal closeModal={() => setSuccessModal(false)}>
          <SubscriptionSuccessMsg />
        </Modal>
      )}
    </>
  )
}
export default SubscribeDemo
