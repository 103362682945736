import React from 'react'
import '../../assets/stylesheets/components/shared/tiles.scss'
import Tile from './Tile'

function Tiles ({
  heading,
  content,
  rows = 'three',
  arrow,
  subHeading = ''
}) {
  const renderTiles = content.map((tile, i) => (
    <Tile tile={tile} classname={rows} key={i} />
  ))

  return (
    <div className='tiles-container section connector-container'>
      <div className='container arrow-wrapper'>
        {subHeading &&
          <div className='tc tl-mb32'>
            <span className='tl-text14 tl-fsn tl-fnormal tl-lh170 tl-69778E'>{subHeading}</span>
          </div>}
        <div className='tc'>
          <span className='tl-f500 tl-001233 tl-lh140 section-heading'>{heading}</span>
        </div>
        {arrow && <span className='arrow' />}
        <div className='tiles-wrapper'>
          {renderTiles}
        </div>
      </div>
      <div className='right-connector-image'>
        <img height='800px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/left-connector-dark.png' />
      </div>
    </div>
  )
}
export default Tiles
