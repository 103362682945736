import React from 'react'

import Cta from './CTA'

import '../../assets/stylesheets/components/shared/features.scss'

function Feature ({
  heading,
  subHeading,
  content,
  cta,
  hideConnectorImage = false,
  squaresWithoutColor = false,
  showBoxShadow = false,
  showImageBoxShadow = false,
  centerHeading = false,
  noSectionHeadingHeight = false,
  id = '',
  updateWidth
}) {
  const onlyChild = content.length === 1
  const renderContent = content.map((e, i) => {
    return (
      <div className='background-container connector-container' key={i}>
        {!hideConnectorImage && i !== 0 && i % 2 === 1 && (
          <div className='left-connector-image'>
            <img height='700px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/left-connector-dark.png' />
          </div>
        )}
        <div className={squaresWithoutColor ? 'container-without-color' : 'container'}>
          <div className={`tab-container flex-center justify-center ${showBoxShadow ? 'box-with-shadow' : 'box-without-shadow'} ${updateWidth ? 'w-90' : ''}`}>
            <div className='content-tab'>
              {e.heading &&
                <div className={`tab-heading tl-text32 tl-f500 tl-fsn tl-lh162 tl-mb32 monstreat-heading ${centerHeading ? 'tc' : ''}`}>
                  {e.heading}
                </div>}
              {e.description && (
                <div className='tl-fsn tl-fnormal tl-text16 tl-lh162 tl-69778E'>
                  {e.description}
                </div>
              )}
              {e.description1 && (
                <div className={`tl-fsn tl-fnormal ${updateWidth ? 'tl-text13' : 'tl-text16'} tl-lh162 tl-69778E`}>
                  {e.description1}
                </div>
              )}
              {e.description2 && (
                <div className='tl-fsn tl-fnormal tl-text16 tl-69778E'>
                  {e.description2}
                </div>
              )}
              {e.description3 && (
                <div className='tl-fsn tl-fnormal tl-text16 tl-69778E'>
                  {e.description3}
                </div>
              )}
              {e.list && (
                <div className='tl-mt32 list'>
                  <div className='tl-text24 tl-f500'>{e.list.heading}</div>
                  <ul>
                    {e.list.items.map((item, i) => <li key={i} className='list-item tl-fsn tl-fnormal tl-text16 tl-lh162 tl-69778E'>{item}</li>)}
                  </ul>
                </div>
              )}
              {e.cta && <Cta cta={e.cta} />}
            </div>
            {e.image &&
              <div className={`image-tab ${showImageBoxShadow ? 'image-box-shadow' : ''}`}>
                <img height={e.image.height ? e.image.height : 'auto'} src={e.image.src} className={`${updateWidth ? 'border-50' : ''}`} />
              </div>}
          </div>
        </div>
        {!hideConnectorImage && (onlyChild || (i !== 0 && i % 2 === 0)) && (
          <div className='right-connector-image'>
            <img height='700px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/left-connector-dark.png' />
          </div>
        )}
      </div>
    )
  })

  return (
    <div className='features-container section' id={id}>
      {heading && (
        <div className='features-heading flex-center flex-wrap'>
          <span className={`tl-f500 tl-001233 tl-lh140 ${noSectionHeadingHeight ? 'section-heading no-margin-bottom' : 'section-heading'}`}>{heading}</span>
          {subHeading && (
            <>&nbsp; &#8211; &nbsp;<span className='tl-mr4 tl-text40 tl-001233 tl-fnormal tl-lh140 monstreat-heading'>{subHeading}</span></>
          )}
        </div>
      )}
      {renderContent}
      {cta && <Cta cta={cta} />}
    </div>
  )
}

export default Feature
