import whyTrustspace from './pages/en/why-trustspace'
import isoCompliance from './pages/en/iso-compliance'
import aboutUs from './pages/en/about-us'
import careers from './pages/en/careers'
import integrations from './pages/en/integrations'
import faqs from './pages/en/faq'
import cyberSecurity from './pages/en/cyber-security.json'
import header from './pages/en/header.json'
import helpCenter from './pages/en/help-center'
import partner from './pages/en/partner'
import feature from './pages/en/feature'
import industries from './pages/en/industries.json'

export default {
  pages: {
    'why-trustspace': whyTrustspace,
    'iso-compliance': isoCompliance,
    'about-us': aboutUs,
    'help-center': helpCenter,
    careers,
    integrations,
    faqs,
    industries: JSON.stringify(industries),
    'cyber-security': JSON.stringify(cyberSecurity),
    partner,
    feature
  },
  common: {
    automateCompliance: `{
      "heading": "Ready to automate compliance?",
      "image": {
          "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/careers/automate-compliance.png"
      },
      "cta": {
        "Component": "RequestADemo"
      }
    }`,
    header: JSON.stringify(header)
  },
  text: {
    requestADemo: 'Request a Demo',
    letsTalkCyberSecurity: 'Let\'s talk cyber security'
  },
  header: {
    trustLane: 'TrustSpace',
    product: 'Product',
    customer: 'Customer',
    resources: 'Resources',
    company: 'Company',
    signIn: 'Sign In',
    requestADemo: 'Request Demo',
    whyTrustspace: 'Why Trustspace',
    isoCompliance: 'ISO Compliance',
    aboutUs: 'About Us',
    careers: 'Careers',
    integrations: 'Integrations'
  },
  heroImage: {
    yourCyberSpace: 'Your Cybersecurity',
    onAuto: '- on autopilot',
    heroDesc:
      'Our customers benefit from the interaction of the two worlds, ISMS software and experienced IT security & compliance consultants. We enable you to have the most efficient and effective ISMS on the market.',
    requestADemo: 'Get started today',
    cta: 'Learn more'
  },
  whyUs: {
    ourValues: 'ISO 27001/TISAX® Compliance',
    why: 'Build trust and ',
    trustLane: 'scale your business',
    heading1: 'Sales Cycles',
    description1:
      'Skip month-long reviews of Excel spreadsheets by IT & Legal.',
    heading2: 'One platform',
    description2: '24/7 compliance through automated monitoring and reporting.',
    heading3: 'Scale securely',
    description3:
      'New vendors and employees are directly integrated into your ISMS.',
    heading4: 'Auditors Network',
    description4: 'Benefit from our network of partner auditors.'
  },
  footer: {
    company: 'Company',
    whoAreWe: 'who are we',
    careers: 'Careers',
    blog: 'Blog',
    press: 'Press',
    product: 'Product',
    helpAndService: 'Help & Service',
    contact: 'Contact',
    customer: 'Customer',
    resources: 'Resources',
    privacyPolicy: 'Privacy Policy',
    termsOfService: 'Terms of Service',
    impressum: 'Impression',
    legalNotice: 'Legal Notice',
    termsAndCondition: 'Terms and Conditions'
  },
  contact: {
    requestDemo: 'Request Demo',
    additionalInfo: 'Additional Information',
    name: 'Name',
    email: 'Work Email',
    phone: 'Phone Number',
    componentSubHeading: 'Automate your IT compliance',
    getStarted: 'Ready?',
    company: 'Company',
    marketingPermission: 'By submitting my information, I agree to receive product and marketing emails from TrustSpace. You can withdraw your consent at any time using the relevant link in the footer of our emails. You can find information about our privacy practices on our website.'
  },
  clientsFeedback: {
    feedbacksFrom: ' ',
    ourClients: 'Customers & Partners',
    feedback: 'Feedback',
    mariosfeedback:
      'I was surprised. Finally, there was no random bunch of documents, screenshots and service providers pretending to be an information security management system. The audit was quick and efficient - just as it should be.',
    bfmtAuditFeedback: 'The BFMT Group places great emphasis on quality and security and is committed to helping its clients meet compliance requirements. Working closely with TrustSpace, our joint clients have benefited from both expertise in information security standards and innovative IT security solutions.  We are proud of the successes achieved so far in our partnership and look forward to continuing to work together to deliver significant value to our clients in the future.',
    munichFeedback: 'TrustSpace ist unser ganzheitlicher Informationssicherheits-Partner und ermöglicht uns, dass unser ISMS mit unserem Unternehmen zusammen wächst - wir freuen uns auf die weitere Zusammenarbeit.',
    opwFeedback: 'Mit TrustSpace haben wir einen langfristigen Partner gefunden, der uns sehr effizient auf das TISAX®-Audit vorbereitet hat und damit einhergehende komplexe Themen wie Risiko- und Assetmanagement oder die Maßnahmensteuerung vorangetrieben hat.'
  },
  features: {
    'description-3':
      'Your ISMS scales with you. Changes and new requirements are seamlessly recorded and integrated. Whether it’s a new employee, service provider or product feature – TrustSpace offers a security standard for the entire company.',
    'heading-3': 'Holistic information security',
    'description-2':
      'The entire integration within your system environment enables us to monitor your IT security 24/7. Automated reporting allows you to easily comply with both legal and customer-specific requirements at any time.',
    'heading-2': 'Information security on autopilot',
    'description-1':
      'Start-ups and SMEs in particular have to meet strict security requirements due to the increasing risk of supply chain attacks. The implementation of international standards such as ISO 27001 or TISAX® with the help of classic IT consultancies bind time, human and financial resources.',
    'heading-1': 'New threats',
    takeALook: 'Innovative ',
    distinctiveFeature: 'IT-Compliance',
    'cta-1': '5 steps to get you certified',
    'cta-2': 'learn more'
  },
  featuredArticle: {
    heading: 'Learn more about information security & cybersecurity on our blog'
  }
}
