export default `{
    "pageHeader": {
        "heading": "Gemeinsam für eine sichere Zukunft",
        "description": "Gestalte mit uns den Wandel der IT-Sicherheit in Europa - Join TrustSpace"
    },
    "tabs": {
        "heading": "Offene Stellen",
        "content": [
            {
                "heading": "All Positions",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/careers/all-jobs.png"
                },
                "content": [
                    {
                        "heading": "Business Development Internship",
                        "description": "Berlin, Germany",
                        "linkCta": {
                            "link": "https://join.com/companies/trustlane/6799751-business-development-internship",
                            "text": "Read More"
                        }
                    },
                    {
                        "heading": "Sales Manager",
                        "description": "Berlin, Germany",
                        "linkCta": {
                            "link": "https://join.com/companies/trustlane/6799747-sales-manager",
                            "text": "Read More"
                        }
                    }
                ]
            },
            {
                "heading": "Sales",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/careers/sales.png"
                },
                "content": [
                    {
                        "heading": "Business Development Internship",
                        "description": "Berlin, Germany",
                        "linkCta": {
                            "link": "https://join.com/companies/trustlane/6799751-business-development-internship",
                            "text": "Read More"
                        }
                    },
                    {
                        "heading": "Sales Manager",
                        "description": "Berlin, Germany",
                        "linkCta": {
                            "link": "https://join.com/companies/trustlane/6799747-sales-manager",
                            "text": "Read More"
                        }
                    }
                  ]
            },
            {
                "heading": "Customer Success",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/careers/customer-success.png"
                },
                "content": []
            },
            {
                "heading": "Product",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/careers/product.png"
                },
                "content": []
            },
            {
                "heading": "Engineering",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/careers/engineering.png"
                },
                "content": []
            },
            {
                "heading": "Operations",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/careers/operations.png"
                },
                "content": []
            },
            {
                "heading": "Marketing",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/careers/marketing.png"
                },
                "content": []
            }
        ]
    }
}`
