export default `{
    "pageHeader": {
        "heading": "Ihre End-to-End Lösung",
        "description": "Unser Security Engine TrustSpaceOS zusammen mit unseren erfahrenen Beratern und  optional implementierbaren Sicherheitsmaßnahmen (Mobile Device Management, End Point & Server Security, etc.) sichert Sie bestmöglich ab.",
        "animationData": {
            "heading": "Security & Compliance",
            "subHeading": "- aus einer Hand",
            "description": "Egal ob Sie ein 15-köpfiges Startup oder ein internationaler Mittelständler sind: Informationssicherheits- und Zertifizierungsanforderungen betreffen viele Unternehmen – unabhängig von Branche und Größe.",
            "animationLink": "https://d11jcn2a09nppp.cloudfront.net/website/animations/cs-on-autopilot.lottie"
        },
        "tiles": {
            "heading": "Aktuelle Dynamiken",
            "subHeading": "Steigende Cyberattacken diktieren striktere Sicherheitsanforderungen über Industrien hinweg.",
            "content": [
                {
                    "heading": "€220 Mrd.",
                    "description": "Kosten durch Cyberkriminalität in 2021 (€ 103 Mrd. in 2019)"
                },
                {
                    "heading": "358%",
                    "description": "Steigerung von Cyberattacken zwischen 2019 und 2020"
                },
                {
                    "heading": "88%",
                    "description": "aller deutschen Unternehmen wurden Opfer von Cyberattacken in 2020 / 2021"
                }
            ]
        }
    },
    "card": {
        "heading": "Vertrauen muss man sich verdienen",
        "description": "Mit unserem Security Engine TrustSpaceOS erfüllen Sie kundenspezifische und regulatorische Anforderungen kontinuierlich. Anstatt vereinzelt und kundenspezifisch Mindestanforderungen nachzuweisen, ermöglichen wir Ihnen ein anerkanntes Sicherheitssystem zu implementieren - weil es um Ihre Sicherheit geht.",
        "cta": {
            "Component": "RequestADemo",
            "props": {
                "text": "Kontakt aufnehmen"
            }
        }
    },
    "tiles": {
        "heading": "Warum Gründer, Geschäftsführer und InfoSec Experten sich für TrustSpace entscheiden",
        "content": [
            {
                "heading": "Identifikation von spezifischen Unternehmensrisiken",
                "description": "Durch systematisches und kontinuierliches Scannen nach Risikofaktoren können Unternehmen potenzielle Bedrohungsszenarien frühzeitig erkennen und Risiken proaktiv adressieren.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/exclamation.svg"
                }
            },
            {
                "heading": "Proaktives Erfüllen von Industriestandards",
                "description": "Anstatt reaktiv Mindestanforderungen zu implementieren, werden mit TrustSpace geschäftsspezifische Sicherheitsstandards und regulatorische Änderungen proaktiv adressiert.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/check.svg"
                }
            },
            {
                "heading": "Senkung von Haftungs- und Geschäftsrisiken",
                "description": "Mit TrustSpace werden sowohl Haftungsrisiken für Führungskräfte, als auch unternehmensspezifische Risiken erheblich reduziert. So werden geringere Versicherungsprämien und eine höhere Kreditwürdigkeit erreicht.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/risk-mitigation.png"
                }
            },
            {
                "heading": "Einfachere Vertrauensbildung mit Prospects",
                "description": "Ganzheitliche IT-Sicherheitssysteme erhöhen das Vertrauen bei potenziellen Kunden und Partnern und beschleunigen Vertriebsprozesse durch den mühelosen und schnellen Nachweis von IT-Sicherheitsstandards.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/3rdparty-monitoring.png"
                }
            },
            {
                "heading": "Verbessertes Vertrauensverhältnis zu Kunden & Partnern",
                "description": "Ganzheitliche IT-Sicherheitssysteme und der damit verbundene adäquate Schutz der Daten Ihrer Partner und Kunden führen zu einem erhöhten Vertrauensniveau und einer Stärkung der Geschäftsbeziehungen.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/thumbs.svg"
                }
            },
            {
                "heading": "Optimierung umfangreicher Prozesse und einhergehende Kostensenkungen",
                "description": "Durch die Verwaltung der IT-Sicherheit mittels eines einzigen Tools mit umfassenden Workflow-Automatisierungen werden Wartungs- und Verbesserungsprozesse rationalisiert und Kosten gesenkt.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/star.svg"
                }
            }
        ]
    },
    "reviews": {
        "heading": "",
        "content": [
            {
                "name": "Maximilian",
                "designation": "CEO",
                "feedback": "TrustSpace spielt eine instrumentale Rolle in Bezug auf unsere ISO 27001-Konformität. Die kontinuierliche Aufrechterhaltung unseres ISMS ist einfach und ohne externe Berater möglich.",
                "image": {
                    "src": "https://i.ibb.co/XscnDn8/Vector.png"
                }
            },
            {
                "name": "Carolina",
                "designation": "Security Engineer",
                "feedback": "Mit der Hilfe von TrustSpace konnten wir die ISO 27001 in nur vier Monaten erlangen. Neben der Compliance-Software haben wir vor allem von den Experten und Auditoren profitieren können.",
                "image": {
                    "src": "https://i.ibb.co/XscnDn8/Vector.png"
                }
            },
            {
                "name": "Markus",
                "designation": "Security Engineer",
                "feedback": "Obwohl wir keine ISO 27001 anstreben, hilft uns die Plattform von TrustSpace stets einen hohen Sicherungsgrad unserer Systeme zu gewährleisten.",
                "image": {
                    "src": "https://i.ibb.co/XscnDn8/Vector.png"
                }
            }
        ]
    },
    "timeline": {
        "heading": "In 5 Schritten zum Zertifikat",
        "description": "Im Durchschnitt benötigen unsere Kunden 12 Wochen für die Einführung eines ISMS",
        "content": [
            {
                "boldHeading": "Phase 1",
                "heading": "Roadmap-Erstellung",
                "description": "Basierend auf einer individuellen Risikobewertung entwickeln wir eine Roadmap zu Ihrer ISO 27001 oder TISAX® Zertifizierung.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/phase-1.png"
                }
            },
            {
                "boldHeading": "Phase 2",
                "heading": "Set-up der Plattform",
                "description": "Wir verknüpfen unseren Security Engine mit Ihrer Systemumgebung und errichten die Grundlage für Ihr ISMS.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/phase-2.png"
                }
            },
            {
                "boldHeading": "Phase 3",
                "heading": "Interne Vorbereitung",
                "description": "Unsere vorgefertigten Richtlinien verteilen Sie mittels unseres Dokumentenmangements automatisiert an alle MitarbeiterInnen.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/pase-3.png"
                }
            },
            {
                "boldHeading": "Phase 4",
                "heading": "ISO-Audit / TISAX®-Assessment",
                "description": "Vermittlung an einen Auditor aus unserem Netzwerk - wir garantieren das ISO 27001/TISAX® Zertifikat.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/phase-4.png"
                }
            },
            {
                "boldHeading": "Phase 5",
                "heading": "Kontinuierliche Compliance",
                "description": "Mit dem TrustSpace-Security-Engine verfällt Ihr Sicherheitsstandard nicht, sondern verbessert sich sogar kontinuierlich.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/phase-5.png"
                }
            }
        ],
        "cta": {
            "Component": "RequestADemo",
            "props": {
                "text": "Kostenloses Beratungsgespräch",
                "centered": true
            }
        }
    },
    "features": {
        "heading": "Wie funktioniert TrustSpace?",
        "content": [
            {
                "heading": "Intuitives Erfüllen formaler Anforderungen",
                "description": "Wir überladen Sie nicht mit Checklisten und halbfertigen Richtlinien. Mit unserer Plattform erfüllen Sie Anforderungen intuitiv und binden Ihre Mitarbeiter problemlos mit ein, vom Verteilen und Sammeln notwendiger Richtlinien bis hin zum automatisierten Zugriffsmanagement bieten wir Ihnen eine End-to-End Lösung.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/how-it-works.png",
                    "height": "450px"
                },
                "list": {
                    "heading": "Dokumenten-Management",
                    "items": [
                        "Zugriff auf alle notwendigen Richtlinien, die von Auditoren erstellt wurden",
                        "Automatisiertes Bearbeiten, Verteilen und Einsammeln notwendiger Richtlinien, alles ISO-/TISAX®-konform"
                    ]
                }
            },
            {
                "heading": "Weitreichende Automatisierungen",
                "description": "Mit TrustSpace stellen Sie sicher, dass alle Vendoren und Integrationen in Ihre Sicherheitsarchitektur integriert sind und der ISO 27001-Standard eingehalten ist.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/scan-secure.png",
                    "height": "450px"
                },
                "list": {
                    "heading": "Automatisiertes Vendoren-Management",
                    "items": [
                        "Verbinden Sie alle cloud-basierten Tools wie AWS, die Google Cloud oder Office 365 mit einem Klick",
                        "Compliance-Reports werden kontinuierlich erstellt und Unregelmäßigkeiten werden fürhzeitig erkannt"
                    ]
                }
            },
            {
                "heading": "Eine ganzheitliche IT-Sicherheitslösung",
                "description": "Mithilfe von direkten Integrationen und einer umfangreichen Datenbank verbessern wir die initiale Bewertung und kontinuierliche Behandlung potenzieller Risikoszenarien.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/access-and-manage.png",
                    "height": "450px"
                },
                "list": {
                    "heading": "Asset-Management",
                    "items": [
                        "Verwalten Sie alle Dienstleister und einhergehende Risiken - von der Reinigungsfirma bis hin zu Personio",
                        "Erstellen Sie automatisierte Compliance Reports für Kunden & Auditoren"
                    ]
                }
            },
            {
                "heading": "Designed für Start-ups & Mittelständler",
                "description": "Cybersecurity und Informationssicherheit ist ein kontinuierlicher Prozess, kein in sich geschlossenes Projekt. Und mithilfe unseres umfangreichen Compliance-Cockpits behalten Sie immer den Überblick, ein Tool für Ihre gesamte IT-Sicherheit.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/ISO-27001-PAGE/stay-compliant.png",
                    "height": "450px"
                },
                "list": {
                    "heading": "Ihr Compliance-Cockpit",
                    "items": [
                        "Tagesaktuelle Insights zum aktuellen Compliance-Status",
                        "Erhalten Sie konkrete Handlungs- und Verbesserungsempfehlungen"
                    ]
                }
            }
        ],
        "backLogData": [
            {
                "heading": "Cybersecurity mit TrustSpace",
                "description": "Zu einem ganzheitlichen Informationssicherheits-Managementsystem gehören eine Vielzahl an Security-Maßnahmen. Neben unserer Compliance-Plattform können wir für Sie auch umfanreiche Security-Maßnahmen ausrollen.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/TS-approach-4.png",
                    "height": "450px"
                },
                "list": {
                    "heading": "Zentrale Maßnahmen",
                    "items": [
                        "Backup-Routinen",
                        "Email-Security",
                        "Managed Threat Response",
                        "Endpoint und Server Security",
                        "Mobile Device Management",
                        "Geräterverschlüsselung",
                        "Etablierung Patch Management"
                    ]
                }
            }
        ],
        "cta": {
            "text": "Erfahren Sie Mehr",
            "link": "/cyber-security",
            "centered":true
        }
    },
    "faq": {
        "heading": "Viele Unternehmen müssen IT-Sicherheitsmaßnahmen ausweiten",
        "faqList": [
            {
                "label": "Druck aus der Privatwirtschaft",
                "description": "Große Unternehmen verschärfen die Anforderungen an die IT-Sicherheit, die ihre Zulieferer erfüllen müssen (z. B. verlangt VW von allen Zulieferern bis Ende 2023 eine TISAX-Zertifizierung)."
            },
            {
                "label": "Druck von Regulierungsbehörden",
                "description": "(Inter-) nationale Regulierungsbehörden bringen umfassende IT-Sicherheitsanforderungen auf den Weg."
            },
            {
                "label": "Langwierige Zertifizierungsprozesse",
                "description": "Der traditionelle Weg, um eine Zertifizierung nach internationalen Standards zu erreichen, ist geprägt von manuellen Aufgaben, endlosen Excel-Files und eine Vielzahl an kostspieligen Beraterstunden. "
            }
        ]
    }
}`
