import React from 'react'
import { useTranslation } from 'react-i18next'
import Footer from '../shared/Footer'
import PageHeader from '../shared/PageHeader'
import Card from '../shared/Card'
import AutomateCompliance from '../shared/AutomateCompliance'
import IntegratedLibraryList from '../Integrations/IntegratedLibraryList'
import Tiles from '../shared/Tiles'

function Integrations () {
  const { t } = useTranslation(['pages'])
  const { pageHeader, integratedLibraryList, card, tiles } = JSON.parse(t('integrations'))
  return (
    <div className='integrations overflow-hidden'>
      <PageHeader
        hideBrands
        imageData={pageHeader.imageData}
        showImageDataContainer
        smallHeadingAndDesc
      />
      <Tiles
        heading={tiles.heading}
        content={tiles.content}
      />
      <IntegratedLibraryList
        heading={integratedLibraryList.heading}
        content={integratedLibraryList.content}
        cta={integratedLibraryList.cta}
      />
      <Card
        heading={card.heading}
        description={card.description}
        cta={card.cta}
      />
      <AutomateCompliance />
      <Footer />
    </div>
  )
}
export default Integrations
