import React from 'react'
import { CertificateWrapper } from '../../helpers/CertificateWrapper'

function LogoSection () {
  const certificates = CertificateWrapper()
  return (
    <div className='logo-wrapper justify-center'>
      {certificates && certificates.map((ele, index) => {
        return (
          <span className='d-flex tl-ml16 tl-mr16' key={index}>
            <img height={ele.height} src={ele.image} alt={ele.name} />
          </span>
        )
      })}
    </div>
  )
}

export default LogoSection
