import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Cta from './CTA'
import * as utils from '../../helpers/Utils'

import '../../assets/stylesheets/components/shared/header.scss'

function getDetailedNav (detailedNavs) {
  return (
    <div className='detail-nav-container'>
      {detailedNavs.map((nav, i) => {
        return (
          <div key={i} className='detail-nav-wrapper'>
            <span className='tl-text14 tl-69778E'>{nav.heading}</span>
            <div className='detail-nav-item-wrapper'>
              {nav.content.map((childNav, j) => {
                return (
                  <Link aria-disabled={childNav.disabled} key={j} className='detail-nav-item' to={childNav.href}>
                    <div className='detail-nav-item-title tl-mb12'>
                      <img height='20px' src={childNav.image.src} />
                      <span className='tl-text18'>{childNav.text}</span>
                    </div>
                    <span className='tl-text14 tl-69778E'>{childNav.description}</span>
                  </Link>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

function Header () {
  const { t } = useTranslation(['common'])
  const { menuList, brandName, image, cta } = JSON.parse(t('header'))
  const [savedLanguage, updateSavedlanguage] = useState('de')
  const [selectedNavIndex, _setSelectedNavIndex] = useState(null)
  const instance = useRef({
    isDetailNavDisplayed: false
  })

  const setSelectedNavIndex = (index) => {
    if (index === null) {
      removeStickyHeader()
      instance.current.isDetailNavDisplayed = false
    } else {
      addStickyHeader()
      instance.current.isDetailNavDisplayed = true
    }
    _setSelectedNavIndex(index)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    updateSavedlanguage(utils.CookiesHelper.getItem('defaultLanguage') || 'de')
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const addStickyHeader = () => {
    const header = document.getElementById('headerContainer')
    if (!header.classList.contains('sticky-header')) {
      header.classList.add('sticky-header')
    }
  }

  const removeStickyHeader = () => {
    const header = document.getElementById('headerContainer')
    if (header.classList.contains('sticky-header') && window.scrollY === 0) {
      header.classList.remove('sticky-header')
    }
  }

  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 0) {
      addStickyHeader()
    } else if (!instance.current.isDetailNavDisplayed) {
      removeStickyHeader()
    }
  }

  function updateLanguageInCookies (lang) {
    utils.CookiesHelper.setItem(
      'defaultLanguage',
      lang,
      604800,
      '/'
    )
  }

  function toggleSelectedNavIndex (i) {
    if (selectedNavIndex === i) _setSelectedNavIndex(null)
    else _setSelectedNavIndex(i)
  }

  const onMobileMenuClick = (event) => {
    if (event.target.checked) {
      addStickyHeader()
      instance.current.isDetailNavDisplayed = true
    } else {
      removeStickyHeader()
      instance.current.isDetailNavDisplayed = false
    }
  }

  const onLocaleChange = (locale) => {
    updateLanguageInCookies(locale)
    window.location.reload()
  }

  function getNavigations (device = 'desktop') {
    return menuList.map((menuItem, i) => {
      const active = selectedNavIndex === i ? 'active' : ''
      const linkProps = {
        className: `menu-item ${menuItem.class} ${active ? 'active' : ''}`
      }
      if (device === 'desktop') {
        linkProps.onMouseEnter = () => setSelectedNavIndex(i)
        linkProps.onMouseLeave = () => setSelectedNavIndex(null)
      } else {
        linkProps.onClick = () => toggleSelectedNavIndex(i)
      }
      const children = (
        <>
          <span className={(active && !menuItem.class) ? 'gradient-text' : ''}>{menuItem.text}</span>
          {menuItem.children && <span className={`arrow ${active ? 'arrow-up' : 'arrow-down'}`}>{'<'}</span>}
          {menuItem.children && active && (
            <div className='menu-item_detail-nav'>
              {device === 'desktop'
                ? (
                  <div className='container'>
                    {getDetailedNav(menuItem.children)}
                  </div>
                  )
                : getDetailedNav(menuItem.children)}
            </div>
          )}
        </>
      )
      return menuItem.children
        ? (
          <div
            key={i}
            href={menuItem.href}
            {...linkProps}
          >
            {children}
          </div>
          )
        : (
          <React.Fragment key={i}>
            {menuItem.newTab
              ? (
                <a
                  key={i}
                  target='_blank'
                  rel='noopener noreferrer'
                  href={menuItem.href}
                  {...linkProps}
                >
                  {children}
                </a>
                )
              : (
                <Link
                  key={i}
                  to={menuItem.href}
                  {...linkProps}
                >
                  {children}
                </Link>
                )}

          </React.Fragment>
          )
    })
  }

  function onRequestDemoCTAClick () {
    utils.sendConversionEvent('AW-10888242046/qKbYCL2R7r0DEP7G9cco')
    utils.openCalendlyView()
  }

  return (
    <div className='header-new-container' id='headerContainer'>
      <div className='container'>
        <div className='nav'>
          <input onClick={onMobileMenuClick} type='checkbox' id='nav-check' />
          <div className='nav-header'>
            <Link to='/' className='nav-title logo-wrapper'>
              <img height='22px' className='tl-mr12' src={image.src} />{brandName}
            </Link>
          </div>
          <div className='nav-btn'>
            <label htmlFor='nav-check'>
              <span />
              <span />
              <span />
            </label>
          </div>
          <div className='nav-links center-link'>
            {getNavigations()}
          </div>
          <div className='nav-links'>
            {/* <a href='#' rel='noreferrer'>{t('signIn')}</a> */}
            <Cta cta={{ ...cta, onClick: () => onRequestDemoCTAClick() }} />
            <div className='dropdown'>
              <div className='dropbtn cp d-flex language-selector'>
                <span className='gp-f400'> {savedLanguage.toUpperCase()} </span> <span className='fa fa-angle-down dropdown-arrow tl-ml4 tl-mt4 lang-dropdown-icon gp-text21' />
              </div>
              <div className='dropdown-content'>
                <a className='lang-wrapper gp-mt8 d-flex cp' onClick={() => onLocaleChange('de')}>
                  <span className='gp-ml12 gp-636363'>Deutsch</span>
                </a>
                <hr className='gp-mt12 gp-mb12' />
                <a className='lang-wrapper d-flex cp' onClick={() => onLocaleChange('en')}>
                  <span className='gp-ml12 gp-636363'>English</span>
                </a>
              </div>
            </div>
          </div>
          <div className='nav-links-mobile'>
            <div className='nav-links-mobile-wrapper container'>
              {getNavigations('mobile')}
            </div>
            <div className='container'>
              <div className='mobile-language-selector'>
                <span className='language-selector-tab'>
                  <a className={savedLanguage === 'de' ? 'selected-language-tab' : ''} onClick={() => onLocaleChange('de')}>DE</a>
                  <a className={savedLanguage === 'en' ? 'selected-language-tab' : ''} onClick={() => onLocaleChange('en')}>EN</a>
                </span>
              </div>
              <Cta cta={{ ...cta, onClick: () => onRequestDemoCTAClick(), props: { centered: true } }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Header
