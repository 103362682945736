import React from 'react'

import Header from './Header'
import BrandWrapper from './BrandWrapper'
import Cta from './CTA'
import '@dotlottie/player-component'

import '../../assets/stylesheets/components/shared/pageHeader.scss'
import * as utils from '../../helpers/Utils'
import LogoSection from '../home/LogoSection'

function PageHeader ({
  heading,
  description,
  cta,
  animationData,
  hideBrands = false,
  showAnimationContainer = false,
  showImageDataContainer = false,
  imageData,
  requestADemo,
  shortDesc = false,
  smallHeadingAndDesc = false,
  tiles,
  showAssociations = false
}) {
  function onRequestDemoCTAClick () {
    utils.sendConversionEvent('AW-10888242046/qKbYCL2R7r0DEP7G9cco')
    utils.openCalendlyView()
  }
  return (
    <div className='page-header-container connector-container'>
      <div className='left-connector-image overflow-hidden'>
        <img height='500px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/left-connector-dark.png' />
      </div>
      <div className='right-connector-image overflow-hidden'>
        <img height='350px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/left-connector-dark.png' />
      </div>
      <div className={`page-header-image-wrapper ${hideBrands ? 'brand-hidden' : ''}`}>
        <div className='container'>
          <div className='header-wrapper' id='header'>
            <Header />
          </div>
          {showAnimationContainer &&
            <div className='animation-container'>
              <div className='header-image-wrapper container'>
                <div className='outer-wrapper'>
                  <div className='left-wrapper'>
                    <div>
                      <p className='tl-text37 tl-mr8 tl-lh170 tl-fsn tl-fnormal tl-001233 sub-heading monstreat-heading'>{animationData.heading}</p><p className='tl-text28 tl-f500 tl-001233'>{animationData.subHeading}</p>
                    </div>
                    <div className='description tl-69778E'>
                      {animationData.description}
                    </div>
                  </div>
                  <div className='right-container m-w-45'>
                    <dotlottie-player
                      autoplay loop zmode='normal' class='dotlottie-player'
                      src={animationData.animationLink}
                    />
                  </div>
                </div>
              </div>
            </div>}
          {showAssociations &&
            <section className='association-wrapper'>
              <LogoSection />
            </section>}
          {showImageDataContainer &&
            <div className='animation-container'>
              <div className='header-image-wrapper container'>
                <div className='outer-wrapper'>
                  <div className='left-wrapper'>
                    <div>
                      <p className='tl-text32 tl-mr8 tl-lh170 tl-fsn tl-fnormal tl-001233 sub-heading monstreat-heading'>{imageData.heading}</p><p className='tl-text28 tl-f500 tl-001233'>{imageData.subHeading}</p>
                    </div>
                    <div className='description tl-69778E w-90'>
                      {imageData.description}
                    </div>
                    {requestADemo && <Cta cta={{ ...cta, onClick: () => onRequestDemoCTAClick() }} />}
                    {/* <button className='cta-button'>Mehr erfahren</button> */}
                  </div>
                  <div className='right-container no-margin'>
                    <img className='pageheader-image' src={imageData.image.src} height={imageData.image.height || '400px'} />
                  </div>
                </div>
              </div>
            </div>}
          {(heading || description || tiles) &&
            (
              <div className='content-wrapper'>
                <div className='connector-line' />
                {heading && (
                  <div>
                    <span className={`${smallHeadingAndDesc ? 'tl-text32 tl-f500' : 'heading tl-fnormal '} tl-lh170 tl-fsn tl-001233 monstreat-heading`}>{heading}</span>
                  </div>
                )}
                {tiles && (
                  <div>
                    <span className='section-heading w-100 tl-mb8 tl-f500 tl-lh170 tl-fsn tl-001233'>{tiles.heading}</span>
                  </div>
                )}

                {description && (
                  <div className={`description tl-69778E ${shortDesc ? 'short-description' : ''} ${smallHeadingAndDesc ? 'small-desc' : ''}`}>
                    {description}
                  </div>
                )}

                {tiles && (
                  <div className='description tl-69778E small-desc'>
                    {tiles.subHeading}
                  </div>
                )}
                {tiles && tiles.content &&
                  <div className='page-header-tiles-wrapper'>
                    {tiles.content.map((item, i) => (
                      <div key={i} className='page-header-tile-container shadow-box'>
                        <span className='gradient-text tl-text22 w-100 tl-f500'>{item.heading}</span>
                        <span className='tl-mt12 tl-text14 tl-fsn tl-fnormal tl-lh170 tl-69778E'>{item.description}</span>
                      </div>
                    ))}
                  </div>}
                {cta && <Cta cta={{ ...cta, onClick: () => onRequestDemoCTAClick() }} />}
              </div>
            )}
        </div>
      </div>
      {!hideBrands && <BrandWrapper />}
    </div>
  )
}
export default PageHeader
