import React from 'react'
import ImageSlider from './ImageSlider'
import '../../assets/stylesheets/components/shared/contentTeamCard.scss'

function ContentTeamCard ({ contentTeam }) {
  return (
    <div className='content-team-slider-wrapper'>
      <ImageSlider images={contentTeam} slidesToShow={3} updateIconView />
    </div>
  )
}

export default ContentTeamCard
