export default `{
    "pageHeader": {
        "imageData": {
            "heading": "Our holistic IT security solution",
            "subHeading": "- One platform, many options",
            "description": "The extensive features and integrations of our platform provide you with a true end-to-end solution.",
            "image": {
                "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/careers/automate-compliance.png",
                "height": "400px"
            }
        }
    },
    "integratedLibraryList": {
        "heading": "Our integrations",
        "content": [
            {
                "libraryLogoCard": {
                    "name": "Google Cloud",
                    "image": {
                        "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/integrations/Google-Cloud.png"
                    }
                }
            },
            {
                "libraryLogoCard": {
                    "name": "Amazon Web Services",
                    "image": {
                        "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/integrations/Amazon-web-services.png"
                    }
                }
            },
            {
                "libraryLogoCard": {
                    "name": "Microsoft Azure Cloud",
                    "image": {
                        "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/integrations/Microsoft-Azure-Cloud.png"
                    }
                }
            },
            {
                "libraryLogoCard": {
                    "name": "Google Cloud Identity",
                    "image": {
                        "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/integrations/Google-Cloud-Identity.png"
                    }
                }
            },
            {
                "libraryLogoCard": {
                    "name": "Okta",
                    "image": {
                        "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/integrations/Okta.png"
                    }
                }
            },
            {
                "libraryLogoCard": {
                    "name": "Azure Active Directory",
                    "image": {
                        "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/integrations/Azure-Active-Directory.png"
                    }
                }
            },
            {
                "libraryLogoCard": {
                    "name": "Asana",
                    "image": {
                        "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/integrations/Asana.png"
                    }
                }
            },
            {
                "libraryLogoCard": {
                    "name": "Jira",
                    "image": {
                        "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/integrations/Jira.png"
                    }
                }
            },
            {
                "libraryLogoCard": {
                    "name": "Trello",
                    "image": {
                        "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/integrations/Trello.png"
                    }
                }
            }
        ],
        "cta": {
            "text": "Get started today"
        }
    },
    "card": {
        "heading": "Cannot find one of your tools?",
        "description": "Request a new integration",
        "cta": {
            "Component": "RequestADemo",
            "props": {
                "text": "Contact Customer Service"
            }
        }
    },
    "tiles": {
        "heading": "Our features",
        "content": [
            {
                "heading": "Vendor monitoring",
                "description": "Through API integrations, all relevant cloud providers are monitored around the clock for compliance with the defined security status.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/exclamation.svg"
                }
            },
            {
                "heading": "Automated reporting",
                "description": "Create automated reports - for auditors, customers, and insurers.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/check.svg"
                }
            },
            {
                "heading": "Document management",
                "description": "All policies in one place - say goodbye to Excel spreadsheets, screenshots, and manual policy collection.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/risk-mitigation.png"
                }
            },
            {
                "heading": "Employee portal",
                "description": "Employees can meet your compliance requirements at any time through our automated onboarding.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/3rdparty-monitoring.png"
                }
            },
            {
                "heading": "Risk management",
                "description": "With the help of TrustSpace, you can simplify your risk management and implement targeted risk mitigation measures.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/thumbs.svg"
                }
            },
            {
                "heading": "Endpoint security",
                "description": "Information security also means IT security. Based on your existing security architecture, we complement your concept according to the current state of the art.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/star.svg"
                }
            }
        ]
    }
}`
