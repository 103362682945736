import React, { useState, useEffect } from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import { useTranslation } from 'react-i18next'
import '../../assets/stylesheets/components/shared/contact.scss'
import { sendConversionEvent } from '../../helpers/Utils'

const CustomForm = ({ status, message, onValidated, buttonText, onSubscriptionSuccess }) => {
  const { t } = useTranslation(['contact'])
  const [state, setState] = useState({
    username: '',
    userEmail: '',
    userQuery: '',
    userFone: '',
    userCompany: '',
    usernameError: false,
    userEmailError: false,
    userCompanyError: false,
    showLoader: false
  })

  const onUsernameFocusOut = (event) => {
    setState({ ...state, username: event.target.value, usernameError: false })
  }

  const onUserEmailFocusOut = (event) => {
    setState({ ...state, userEmail: event.target.value, userEmailError: false })
  }

  const onUserPhoneFocusOut = (event) => {
    setState({ ...state, userFone: event.target.value })
  }

  const onUserQueryFocusOut = (event) => {
    setState({ ...state, userQuery: event.target.value })
  }

  const onUserCompanyFocusOut = (event) => {
    setState({ ...state, userCompany: event.target.value })
  }

  function showModal () {
    onSubscriptionSuccess()
    const modal = document.getElementById('myModal')
    modal.style.display = 'block'
  }

  function hideModal () {
    const modal = document.getElementById('myModal')
    modal.style.display = 'none'
  }

  function onConsentClick () {
    hideModal()
    setState({ ...state, userEmail: '', userQuery: '', userEmailError: false, usernameError: false, username: '', userFone: '', userCompanyError: false, userCompany: '' })
  }

  function getErrorMessage (message) {
    if (message && typeof (message) === 'string' && message.includes('is already subscribed to')) {
      return message.replace(/<a\b[^>]*>(.*?)<\/a>/i, '')
    }
    return message
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let usernameError = false
    let userEmailError = false
    let userCompanyError = false
    if (state.username && state.userCompany && state.userEmail && validateEmail(state.userEmail)) {
      setState({ ...state, showLoader: true })
      sendConversionEvent('AW-10888242046/ZYOTCPOtuL0DEP7G9cco')
      // window.fbq('track', 'Contact')
      onValidated({
        MERGE0: state.userEmail,
        MERGE2: state.username,
        MERGE3: state.userQuery,
        MERGE1: state.userCompany,
        MERGE4: state.userFone
      })
    } else {
      if (!state.username) { usernameError = true }
      if (!state.userEmail || !validateEmail(state.userEmail)) { userEmailError = true }
      if (!state.userCompany) { userCompanyError = true }
      setState({ ...state, userEmailError, usernameError, userCompanyError })
    }
  }

  useEffect(() => {
    if (status === 'success') {
      setState({ ...state, showLoader: false })
      showModal()
    }
    if (status === 'error') {
      setState({ ...state, showLoader: false })
    }
  }, [status])

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  return (
    <div className='form-wrapper'>
      <form
        className='mc__form'
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className='mc__field-container'>
          <div className='input-wrapper d-flex flex-wrap justify-space-between'>
            <div className='input-field tl-mr20 tl-mb20 '>
              <span className='fa fa-user theme-color-gradient tl-mr8' />
              <span><input
                placeholder={t('name')} onChange={onUsernameFocusOut}
                value={state.username}
                name='MERGE2'
                id='MERGE2'
                autoComplete='off'
                className='input-area tl-929DB0 tl-text12 tl-lh170' type='text'
                    />
              </span>
            </div>
            <div className={state.userEmailError ? 'input-field tl-mb20 error-border' : 'input-field tl-mb20'}>
              <span className='fa fa-envelope theme-color-gradient tl-mr8' />
              <span><input
                name='MERGE0'
                id='MERGE0'
                placeholder={t('email')} onChange={onUserEmailFocusOut}
                value={state.userEmail}
                autoComplete='off'
                className='input-area tl-929DB0 tl-text12 tl-lh170' type='text'
                    />
              </span>
            </div>
            <div className={state.userCompanyError ? 'input-field tl-mb20 error-border w-100' : 'input-field tl-mb20 w-100'}>
              <span className='fa fa-building-o theme-color-gradient tl-mr8' />
              <span><input
                name='MERGE4'
                id='MERGE4'
                placeholder={t('company')} onChange={onUserCompanyFocusOut}
                value={state.userCompany}
                autoComplete='off'
                className='input-area tl-929DB0 tl-text12 tl-lh170' type='text'
                    />
              </span>
            </div>
            <div className='input-field tl-mb20 w-100'>
              <span className='fa fa-mobile theme-color-gradient tl-mr8' />
              <span><input
                name='MERGE1'
                id='MERGE1'
                placeholder={t('phone')} onChange={onUserPhoneFocusOut}
                value={state.userFone}
                autoComplete='off'
                className='input-area tl-929DB0 tl-text12 tl-lh170' type='text'
                    />
              </span>
            </div>
          </div>
          <div className='text-area-wrapper tl-mb12'>
            <div className='text-area-field'>
              <span className='fa fa-pencil-square-o theme-color-gradient tl-fnormal tl-ml8' />
              <span><textarea
                name='MERGE3'
                id='MERGE3'
                type='text' placeholder={t('additionalInfo')} onChange={onUserQueryFocusOut}
                value={state.userQuery}
                autoComplete='off'
                className='text-area-input tl-929DB0 tl-text12 tl-lh170'
                    />
              </span>
            </div>
          </div>
        </div>
        {status === 'error' && (
          <div
            className='mc__alert error-message'
            dangerouslySetInnerHTML={{ __html: getErrorMessage(message) }}
          />
        )}
      </form>
      <div className='tl marketing-permission theme-color-gradient tl-text14 tl-mb32'>
        * {t('marketingPermission')}
      </div>
      <div className='tc'>
        <button onClick={(e) => handleSubmit(e)} className='request-button'>
          {state.showLoader && <span className='fa fa-spinner animated-spinner' />}
          {!state.showLoader && <span>{buttonText || t('requestDemo')}</span>}
        </button>
      </div>
      <div id='myModal' className='modal'>
        <div className='modal-content'>
          <span className='close' onClick={() => onConsentClick()}>&times;</span>
          <div className='content-wrapper'>
            <div className='image-wrapper'>
              <img src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/Inbox.png' height='80px' />
            </div>
            <div className='modal-heading monstreat-heading tl-fsn tl-f600 tl-lh170 tl-text24 tl-001233 tl-mt20'>
              Vielen Dank für Ihre Nachricht!
            </div>
            <div className='modal-text tl-mt8'>
              <span className='tl-text14 tl-fsn tl-fnormal tl-lh170 tl-69778E'>Wir melden uns umgehend bei Ihnen.</span><span className=' tl-ml4 tl-text14 tl-fsn tl-fnormal tl-lh170 tl-166EFC'>{state.userEmail}</span>
            </div>
            <div className='button-container tl-mt20'>
              <button onClick={() => onConsentClick()} className='cta-button'>
                <span>Okay</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const MailchimpForm = props => {
  const url = 'https://trustspace.us20.list-manage.com/subscribe/post?u=647862e0a7f9df1a156351912&id=95d41caaf3'

  return (

    <div className='mc__form-container'>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
            buttonText={props.buttonText}
            onSubscriptionSuccess={props.onSubscriptionSuccess}
          />
        )}
      />
    </div>

  )
}

export default MailchimpForm
