export default `{
    "pageHeader": {
        "heading": "Mit TrustSpace immer auf der sicheren Seite",
        "description": "Dass nach einer Cyberattacke Kosten von Versicherungen übernommen werden steht und fällt damit, ob alle notwendigen Cybersicherheits-Maßnahmen zum Zeitpunkt der Attacke aktiviert waren. Wenn das nicht der Fall war, werden Kosten nicht übernommen, auch wenn eine dedizierte Cybersecurity-Versicherungspolice abgeschlossen wurde.",
        "imageData": {
            "heading": "Ihre Cybersecurity",
            "subHeading": "Eine Plattform, viele Möglichkeiten",
            "description": "Wir arbeiten mit führenden Cybersecurity-Providern zusammen, um unseren Kunden State-of-the-Art Cybersecurity Maßnahmen anbieten zu können.",
            "image": {
                "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/TS-approach-4.png",
                "height": "400px"
            }
        },
        "requestADemo": {
            "props": {
                "text": "Kostenloses Erstgespräch buchen"
            }
        },
        "cta": {
            "text": "Unverbindlich Angebot anfordern"
        }
    },
    "tiles": {
        "heading": "Kernpfeile Ihrer Cybersecurity",
        "content": [
            {
                "heading": "Cloud-Security",
                "description": "Überwachen Sie Ihre Multi-Cloud-Umgebungen, um Sicherheitsrisiken zu erkennen und zu beheben, Ihre Angriffsfläche zu reduzieren und Compliance zu gewährleisten.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/3rdparty-monitoring.png"
                },
                "priceCta": {
                    "text": "Ab 17 EUR monatl.",
                    "link": ""
                }
            },
            {
                "heading": "Endpoint-Protection",
                "description": "Mit Intercept X erkennen Sie schädliche Verschlüsselungsprozesse (Ransomware-Angriffe) und stoppen diese, bevor sie sich im Netzwerk ausbreiten können.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/automated-reporting.png"
                },
                "priceCta": {
                    "text": "Ab 6,70 EUR monatl./Nutzer",
                    "link": ""
                }
            },
            {
                "heading": "Email-Security",
                "description": "Cloud-Email-Sicherheit schützt Ihre Mitarbeiter und wichtige Informationen vor Malware, Phishing und dem Versuch falsche Identitäten vorzutäuschen.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/endpoint-security.png"
                },
                "priceCta": {
                    "text": "Ab 2 EUR monatl./Nutzer",
                    "link": ""
                }
            }
        ]
    },
    "tiles2": {
        "heading": "Aktivierung innerhalb 48h",
        "content": [
            {
                "heading": "Bestimmung des Anwendungsbereiches",
                "description": "In einem 15-Minuten Call bestimmen wir gemeinsam mit Ihnen, welche Maßnahmen notwendig sind, und was Sie bereits erfüllen.",
                "num": "1"
            },
            {
                "heading": "Aktivierungscall (1,5h)",
                "description": "Während des Aktivierungs-Calls führen wir Sie in Ihre neuen IT Sicherheits-Maßnahmen ein und aktivieren diese mit Ihnen zusammen.",
                "num": "2"
            },
            {
                "heading": "Endgerät Aktivierung",
                "description": "Alle Mitarbeiter erhalten im Nachgang des Aktivierungs-Calls den Link zum Download des Sophos-Agenten, nach erfolgreicher Installation sind alle Geräte gesichert.",
                "num": "3"
            }
        ]
    },
    "card": {
        "heading1": "Mehr Aufwand ist es nicht, innerhalb von 48h können wir Ihre Infrastruktur sichern.",
        "heading2": "Buchen Sie sich jetzt das kostenlose 15-minütige Erstgespräch.",
        "heading3": "Wir freuen uns auf Sie.",
        "cta": {
          "text": "Lassen Sie uns sprechen",
          "Component": "SubscribeDemo"
        }
      }
}`
