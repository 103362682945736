import React from 'react'
import { useTranslation } from 'react-i18next'
import Footer from './shared/Footer'
import ContactUs from './shared/ContactUs'
import PageHeader from './shared/PageHeader'
import Features from './shared/Features'
import Card from './shared/Card'
import Tiles from './shared/Tiles'

function CyberSecurity () {
  const { t } = useTranslation(['pages'])
  const { pageHeader, tiles, features, card, contactUs, whatYouShouldKnowCard } = JSON.parse(t('cyber-security'))
  return (
    <div className='cyber-security overflow-hidden'>
      <PageHeader
        heading={pageHeader.heading}
        description={pageHeader.description}
        cta={pageHeader.cta}
      />
      <Tiles
        heading={tiles.heading}
        content={tiles.content}
        rows='four'
      />
      <Card
        heading={whatYouShouldKnowCard.heading}
        description={whatYouShouldKnowCard.description}
        cta={whatYouShouldKnowCard.cta}
      />
      <Features
        heading={features.heading}
        content={features.content}
        cta={features.cta}
      />
      <Card
        heading={card.heading}
        description={card.description}
        cta={card.cta}
      />
      <ContactUs
        heading={contactUs.heading}
      />
      <Footer />
    </div>
  )
}
export default CyberSecurity
