import React from 'react'
import { useTranslation } from 'react-i18next'
import ContactUs from './shared/ContactUs'
import Footer from './shared/Footer'
import PageHeader from './shared/PageHeader'
import FeaturesWithHeading from './shared/FeaturesWithHeading'

function Partner () {
  const { t } = useTranslation(['pages'])
  const { pageHeader, features } = JSON.parse(t('partner'))
  return (
    <div className='overflow-hidden'>
      <PageHeader
        heading={pageHeader.heading}
        description={pageHeader.description}
        hideBrands={1}
      />
      <FeaturesWithHeading content={features.content} decreaseHeight centerHeading />
      <ContactUs />
      <Footer />
    </div>
  )
}

export default Partner
