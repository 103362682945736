export default `{
    "pageHeader": {
        "imageData": {
            "heading": "Unsere ganzheitliche IT-Sicherheitslösung",
            "subHeading": "- Eine Plattform, viele Möglichkeiten",
            "description": "Die umfangreichen Features und Integrationen unserer Plattform bieten Ihnen eine echte End-to-End-Lösung.",
            "image": {
                "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/careers/automate-compliance.png",
                "height": "400px"
            }
        }
    },
    "integratedLibraryList": {
        "heading": "Unsere Integrationen",
        "content": [
            {
                "libraryLogoCard": {
                    "name": "Google Cloud",
                    "image": {
                        "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/integrations/Google-Cloud.png"
                    }
                }
            },
            {
                "libraryLogoCard": {
                    "name": "Amazon Web Services",
                    "image": {
                        "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/integrations/Amazon-web-services.png"
                    }
                }
            },
            {
                "libraryLogoCard": {
                    "name": "Microsoft Azure Cloud",
                    "image": {
                        "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/integrations/Microsoft-Azure-Cloud.png"
                    }
                }
            },
            {
                "libraryLogoCard": {
                    "name": "Google Cloud Identity",
                    "image": {
                        "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/integrations/Google-Cloud-Identity.png"
                    }
                }
            },
            {
                "libraryLogoCard": {
                    "name": "Okta",
                    "image": {
                        "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/integrations/Okta.png"
                    }
                }
            },
            {
                "libraryLogoCard": {
                    "name": "Azure Active Directory",
                    "image": {
                        "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/integrations/Azure-Active-Directory.png"
                    }
                }
            },
            {
                "libraryLogoCard": {
                    "name": "Asana",
                    "image": {
                        "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/integrations/Asana.png"
                    }
                }
            },
            {
                "libraryLogoCard": {
                    "name": "Jira",
                    "image": {
                        "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/integrations/Jira.png"
                    }
                }
            },
            {
                "libraryLogoCard": {
                    "name": "Trello",
                    "image": {
                        "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/integrations/Trello.png"
                    }
                }
            }
        ],
        "cta": {
            "text": "Kostenloses Erstgespräch buchen"
        }
    },
    "card": {
        "heading": "Eine Ihnen wichtige Integration fehlt?",
        "description": "Auch das lösen wir, sprechen Sie uns gerne an.",
        "cta": {
            "Component": "RequestADemo",
            "props": {
                "text": "Kontaktieren Sie uns"
            }
        }
    },
    "tiles": {
        "heading": "Unsere Features",
        "content": [
            {
                "heading": "Vendoren-Monitoring",
                "description": "Durch API-Integrationen werden alle relevanten Cloud-Provider rund um die Uhr auf Einhaltung des definierten Sicherheitsstatus kontrolliert.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/exclamation.svg"
                }
            },
            {
                "heading": "Automatisiertes Reporting",
                "description": "Erstellen Sie automatisierte Reports – für Auditoren, Kunden und Versicherer.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/check.svg"
                }
            },
            {
                "heading": "Dokumentenmanagement",
                "description": "Alle Richtlinien an einem Ort – verabschieden Sie sich von Excel-Tabellen, Screenshots und dem manuellen Einsammeln von Richtlinien.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/risk-mitigation.png"
                }
            },
            {
                "heading": "Mitarbeiterportal",
                "description": "Mitarbeiter können Ihre Compliance-Anforderungen durch unser automatisiertes Onboarding jederzeit erfüllen.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/3rdparty-monitoring.png"
                }
            },
            {
                "heading": "Risikomanagement",
                "description": "Mithilfe von TrustSpace können Sie Ihr Risikomanagement vereinfachen und zielgerichtete Maßnahmen zur Risikominimierung implementieren.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/thumbs.svg"
                }
            },
            {
                "heading": "Endpoint-Security",
                "description": "Informationssicherheit bedeutet auch IT-Sicherheit. Basierend auf Ihrer bestehenden Sicherheitsarchitektur ergänzen wir Ihr Konzept nach dem aktuellen Stand der Technik.",
                "image": {
                    "src": "https://d11jcn2a09nppp.cloudfront.net/website/static-images/cyber-security/star.svg"
                }
            }
        ]
    }
}`
