import React from 'react'
import { useTranslation } from 'react-i18next'
import Footer from './shared/Footer'
import PageHeader from './shared/PageHeader'
import Tiles from './shared/Tiles'
import Card from './shared/Card'

function HelpCenter () {
  const { t } = useTranslation(['pages'])
  const { pageHeader, tiles, card, tiles2 } = JSON.parse(t('help-center'))
  return (
    <div className='help-center overflow-hidden'>
      <PageHeader
        imageData={pageHeader.imageData}
        requestADemo={pageHeader.requestADemo}
        hideBrands
        showImageDataContainer
        heading={pageHeader.heading}
        description={pageHeader.description}
        cta={pageHeader.cta}
        smallHeadingAndDesc
      />
      <Tiles
        heading={tiles.heading}
        content={tiles.content}
        rows='three'
      />
      <Tiles
        heading={tiles2.heading}
        content={tiles2.content}
        rows='three'
      />
      <Card
        heading={card.heading1}
        subHeading={card.heading2}
        subHeading2={card.heading3}
        description={card.description}
        cta={card.cta}
        noShadow
        cardHeading
      />
      <Footer />
    </div>
  )
}
export default HelpCenter
