export default `{
        "heading": "Frequently Asked Questions",
        "faqList": [
            {
                "label": "Was ist ISO 27001 / Informationssicherheit?",
                "description": "Informationssicherheit beschreibt das Zusammenspiel aus IT-Security, Risikomanagement, Business Continuity Management sowie Datenschutz. Das ISO 27001-Zertifikat ist die internationale Benchmark für Informationssicherheit und weltweit vertrauen ihr Konzerne, Versicherer und Regierungen."
            },
            {
                "label": "Was bringt mir das Zertifikat?",
                "description": "Unter anderem kann das Zertifikat, bzw. die Erfüllung der darin enthaltenen Standards, Sales-Prozesse um Monate verkürzen. Nach dem signifikanten Anstieg an Cyberkriminalität und anziehender Regularien seitens der EU, prüfen Unternehmen mittlerweile ihre Dienstleister nach den Maßstäben der ISO 27001 Norm. Anstatt in langwierigen Prozessen reaktiv Anforderungen zu erfüllen und nachzuweisen, adressieren Sie mit dem Zertifikat Anforderungen proaktiv und verkürzen bzw. eliminieren Due Diligence Prozesse."
            },
            {
                "label": "Ich brauche keine ISO 27001 oder TISAX® Zertifizierung - kann mir TrustSpace trotzdem helfen?",
                "description": "Können Sie es sich leisten keinen Zugriff mehr auf Ihre Produkte oder Systemumgebung zu haben? Egal ob Sie eine Cybersecurity-Versicherung anstreben, Kundenanforderungen abseits der ISO 27001/TISAX® erfüllen müssen oder ein grundsätzliches Schutzniveau innerhalb Ihres Unternehmens erreichen wollen. Mithilfe unserer modularen Plattform und unseren Experten erstellen wir Ihnen nach einer Gap-Analyse eine maßgeschneiderte Lösung."
            },
            {
                "label": "Wann ist der richtige Zeitpunkt für dieses Projekt?",
                "description": "Gestern – oftmals schieben Unternehmen das Thema vor sich her und riskieren verheerende Attacken. Aufgrund der inhaltlichen Breite und der Vorbereitungszeit des Projektes, empfiehlt es sich, als Rule of Thumb, anzufangen, sobald man mehr als 10 Mitarbeiter hat und/oder Enterprise-Kunden/öffentliche Aufträge anstrebt. Egal ob Sie die ISO 27001/TISAX® anstreben, oder „nur“ ein resilientes Sicherheits-Management-System implementieren wollen, beginnen Sie rechtzeitig mit der Umsetzung."
            },
            {
                "label": "Wie lange dauert die Implementierung eines ISMS und wieviel kostet es? ",
                "description": "Die Dauer hängt davon ab, wie viele IT-Sicherheits-Maßnahmen und zu dokumentierende Prozesse innerhalb des Unternehmens etabliert worden sind. Mit ausreichend Ressourcen ist man In der Regel innerhalb von drei bis fünf Monaten Audit-ready. Die Kosten sind, genau wie ein ISMS, sehr individuell, - abhängig vom Schutzbedarf Ihres Unternehmens und ob die gesamte IT-Security über TrustSpace gemanagt werden soll oder ob Ihr Unternehmen nur eine Managementplattform mit „dem Nötigsten“ sucht.  "
            },
            {
                "label": "Ich habe keine Personalkapazitäten für dieses Projekt, kann ich trotzdem das Zertifikat erhalten? ",
                "description": "Die Implementierung eines ISMS ist immer mit einem Aufwand verbunden. Mittels ehemaligen analogen Prozessen wäre die Implementierung ohne mindestens einer Teilzeitstelle und dem Konsultieren einer dedizierten kostspieligen Beratung nicht möglich. Unser Ziel mit TrustSpace ist aber die Compliance Workflow Automation. Mithilfe unserer API-Integrationen und unseres voll-integrierten Mitarbeitermanagement-Systems ersetzen wir eine Vielzahl von manuellen Aufgaben, sodass wir Ihnen einen Großteil der Aufgaben abnehmen. "
            },
            {
                "label": "Was ist das TISAX® Zertifikat und wann ist es für mein Unternehmen relevant?",
                "description": "Die TISAX® (Trusted Information Security Assessment Exchange) beschreibt ein Prüf- und Austauschverfahren, welches die Informationssicherheit in der Automobilindustrie sicherstellen soll. Bei einem Assessment wird die Konformität mit den Anforderungen des VDA Information Security Assessment (VDA ISA) überprüft. Eine Zertifizierung ist prinzipiell für jedes Unternehmen relevant, was am Planungs- und Herstellungsprozess von Automobilherstellern beteiligt ist."
            },
            {
                "label": "Wie unterscheiden sich die Zertifizierungen nach ISO 27001 und TISAX®? ",
                "description": "Generell besteht der Anforderungskatalog der TISAX® aus drei Modulen: Informationssicherheit, Prototypenschutz und Datenschutz. Wobei das Modul zur Informationssicherheit immer Gegenstand der Prüfung ist und die anderen beiden Module lediglich optional Anwendung finden. Die Anforderungen der Informationssicherheit leiten sich dabei von der ISO 27001-Norm ab und sind bis auf minimale Unterschiede in Bezug auf die Geltungsbereiche, den Prüfungsprozess und die Qualifizierung der Maßnahmen nahezu deckungsgleich. Im Mittelpunkt beider Zertifizierungen steht die Implementierung eines Informationssicherheits-Managementsystem und die adäquate Umsetzung von Maßnahmen, die den Schutz sensibler Informationen gewährleisten."
            }
        ]
}`
