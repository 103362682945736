export const brandsData = [
  {
    name: 'Senheiser',
    image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/Sennheiser.png',
    height: '30px',
    width: '138px'
  },
  {
    name: 'Hans Ziller',
    image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/Hans%20Ziller.png',
    height: '48px',
    width: '138px'
  },
  {
    name: 'Franken Guss',
    image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/Franken%20Guss%20-%20white%20background.jpg',
    height: '48px',
    width: '138px'
  },
  {
    name: 'ME',
    image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/ME.png',
    height: '48px',
    width: '138px'
  },
  {
    name: 'Deep Neuron',
    image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/deep-neuron-color.png',
    height: '24px',
    width: '138px'
  },
  {
    name: 'Deudat',
    image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/deudat-color.webp',
    height: '64px',
    width: '95px'
  },
  {
    name: 'GoGroup',
    image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/GG-color.png',
    height: '44px',
    width: '182px'
  },
  {
    name: 'Hype',
    image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/hype-color.png',
    height: '32px',
    width: '108px'
  },
  {
    name: 'TubeConnect​',
    image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/tube-color.png',
    height: '48px',
    width: '156px'
  },
  {
    name: 'Bonetti',
    image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/Bonetti.png',
    height: '60px',
    width: '166px'
  },
  {
    name: 'Eduneon',
    image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/eduneon-color.png',
    height: '48px',
    width: '162'
  },
  {
    name: 'Certivity',
    image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/Certivity.png',
    height: '40px',
    width: '138px'
  },
  {
    name: 'KSG',
    image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/KSG%20-%20white%20background.png',
    height: '48px',
    width: '138px'
  },
  {
    name: 'Pfinder',
    image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/Pfinder.png',
    height: '60px',
    width: '138px'
  },
  {
    name: 'Sauter',
    image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/Sauter%20-%20white%20Background.jpg',
    height: '80px',
    width: '138px'
  },

  {
    name: 'Nooxit',
    image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/partners/nooxit%20-%20white%20background.png',
    height: '30px',
    width: '138px'
  }
]
