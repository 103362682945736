import React from 'react'
import '../../assets/stylesheets/components/shared/teamCard.scss'

function TeamCard ({ content, heading, centerHeading = false, marginClass = '' }) {
  return (
    <div className={`team-wrapper ${marginClass || ''}`}>
      {heading &&
        <div className={`teamcard-heading ${centerHeading ? 'tc' : ''}`}>
          {heading}
        </div>}
      <div className='teamcard-container'>
        {content && content.map((ele, i) => (
          <div className='teamcard' key={i}>
            <div className='image'>
              {ele.image
                ? (
                  <img
                    src={ele.image.src} height={ele.image.height}
                    style={{
                      height: ele.image.height ? ele.image.height : 'auto',
                      minHeight: ele.image.height ? ele.image.height : '100%'
                    }}
                  />
                  )
                : (
                  <div className='image no-image'>
                    <span className='du'>Du</span>
                  </div>
                  )}
            </div>
            <div className='imagecard'>
              <span className='name'>{ele.imageCard.name}</span>
              <span className='position'>{ele.imageCard.position}</span>
              {/* {ele.imageCard.link &&
                <div>
                  <a href={ele.imageCard.link} target='_blank' className='service fa fa-linkedin theme-color-gradient tl-text22' rel='noreferrer' />
                </div>} */}
              {
                ele.imageCard.cta &&
                  <button className='cta-button'>
                    <a href={ele.imageCard.cta} target='_blank' rel='noreferrer'>{ele.imageCard.ctaText}</a>
                  </button>
              }
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default TeamCard
