export const FeaturedArticleList = (state) => {
  return [
    {
      name: 'Happy New ISO: ISO 27001:2022 – Alle Änderungen im Überblick',
      shortDesc: 'Es war lange überfällig, nun hat die internationale Organisation für Standardisierung (ISO) eine neue Version der ISO 27001 Norm veröffentlicht. Bis jetzt wurde zwar lediglich der Entwurf herausgegeben, die offizielle deutsche Publizierung wird jedoch jederzeit erwartet (Stand Mai 2022). ',
      image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/certificates/iso-2.png',
      publishDate: 'May 11, 2022',
      href: ' https://blog.trustspace.io/iso-27001-update-2022/'
    },
    {
      name: 'EIN GUIDE ZUR ISO 27001-ZERTIFIZIERUNG​',
      shortDesc: 'Daten- und Informationssicherheit sind nicht nur für die Erfüllung rechtlicher und regulatorischer Anforderungen wichtig, sondern ebenso essenziell für die Sicherheit und den Erfolg eines jeden Unternehmens. Der verantwortungsbewusste Umgang mit Informationen und der Schutz sensibler Daten ist ein zunehmend wichtiger Faktor für viele Auftraggeber und Kunden.',
      image: 'https://d11jcn2a09nppp.cloudfront.net/website/static-images/certificates/iso-3.png',
      publishDate: 'April 29, 2022',
      href: 'https://blog.trustspace.io/iso-27001-guide/'
    },
    {
      name: 'Die Rolle von OEM und Tier-1-Zulieferern',
      shortDesc: 'Ein Informationssicherheits-Managementsystem, kurz ISMS, ist eine Sammlung von Maßnahmen, Prozessen und Regeln welche Daten und Informationen in Organisationen systematisch schützen sollen. Welche Daten und Informationen wie zu schützen sind, variiert von Unternehmen zu Unternehmen.',
      image: 'https://i0.wp.com/blog.trustspace.io/wp-content/uploads/2022/10/Cybersec-Supply-Chain.png?w=1280&ssl=1',
      publishDate: 'October 31, 2022',
      href: 'https://blog.trustspace.io/die-bedeutung-von-cybersecurity-angesichts-globaler-schwachstellen-in-der-supply-chain-4-0-teil-3/'
    }
  ]
}
