import React from 'react'
import CTAButton from '../shared/CTAButton'
import '../../assets/stylesheets/components/integrations/IntegratedLibraryList.scss'
import * as utils from '../../helpers/Utils'
import ImageSlider from '../shared/ImageSlider'

function IntegratedLibraryList ({
  content,
  cta,
  heading
}) {
  function onRequestDemoCTAClick () {
    utils.sendConversionEvent('AW-10888242046/qKbYCL2R7r0DEP7G9cco')
    utils.openCalendlyView()
  }
  return (
    <div className='integrated-library-list-container'>
      <div className='integrated-library container'>
        <div className='heading-wrapper'>
          <div className='tl-text32 tl-f400'>{heading}</div>
        </div>
        <div className='library-tile-wrapper'>
          <ImageSlider images={content} slidesToShow={3} updateIconView />
        </div>
      </div>
      {cta && (
        <CTAButton
          link={cta.link}
          text={cta.text}
          centered
          onClick={onRequestDemoCTAClick}
        />
      )}
    </div>
  )
}
export default IntegratedLibraryList
