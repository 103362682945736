import React from 'react'
import '../../assets/stylesheets/static-components/privacy-policy.scss'
function PrivacyPolicy () {
  return (
    <div className='main-container'>
      <div className='main-container'>
        <div className='header-container tl-pt12'>
          <a href='https://trustspace.io/'><img height='38px' src='https://d11jcn2a09nppp.cloudfront.net/website/static-images/TS-Text-Logo.png' alt='TrustSpace' border='0' />
          </a>
        </div>
        <div className='floater-container'>
          <div className='trustspace-terms-section tc gbb gp-text18 fwl'>
            <div className='main-heading fsbr fwr gbs'>
              <h1>Privacy Policy</h1>
            </div>
            <div className='main-heading'>
              Last update: January 22nd, 2022
            </div>
            <div className='term-points-container tl'>
              <div className='term-content db gts gp-text18 fwl'>
                <h2>Datenschutzerklärung</h2>
              </div>
              <div className='term-content'>
                <p className='tl-929DB0'>Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
                <p className='tl-929DB0'>Trustlane Solutions GmbH </p>
                <p className='tl-929DB0'>Am Leinpfad 6 </p>
                <p className='tl-929DB0'>65594 Runkel </p>
                <p className='tl-mt20 tl-929DB0'>info@trustspace.io</p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Ihre Betroffenenrechte</h3>
                <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:</p>
                <ul>
                  <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO),</li>
                  <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),</li>
                  <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
                  <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),</li>
                  <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
                  <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>
                </ul>
                <p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.</p>
                <p>Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige Behörde.</p>
                <p>Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter:</p>
                <p>https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html.</p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Erfassung allgemeiner Informationen beim Besuch unserer Website Art und Zweck der Verarbeitung: </h3>
                <p>Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren oder anderweitig Informationen übermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse und ähnliches. </p>
                <p>Sie werden insbesondere zu folgenden Zwecken verarbeitet: </p>
                <ul>
                  <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website, </li>
                  <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
                  <li>Auswertung der Systemsicherheit und -stabilität sowie </li>
                  <li>zur Optimierung unserer Website. </li>
                </ul>
                <p>Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Informationen dieser Art werden von uns ggfs. anonymisiert statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren. </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Rechtsgrundlage und berechtigtes Interesse:  </h3>
                <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilität und Funktionalität unserer Website. </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Empfänger:  </h3>
                <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter tätig werden. </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Drittlandtransfer: </h3>
                <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen:</p>
                <p>Nein </p>
                <p>Folgende Datenschutzgarantien liegen vor:</p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Speicherdauer: </h3>
                <p>Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist für die Daten, die der Bereitstellung der Website dienen, grundsätzlich der Fall, wenn die jeweilige Sitzung beendet ist.Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens 14 Tagen der Fall. Eine darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer anonymisiert, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.  </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Bereitstellung vorgeschrieben oder erforderlich:  </h3>
                <p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein. Aus diesem Grund ist ein Widerspruch ausgeschlossen.</p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Cookies </h3>
                <p>Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrem Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Webseite besuchen.</p>
                <p>Sie können Sie einzelne Cookies oder den gesamten Cookie-Bestand löschen. Darüber hinaus erhalten Sie Informationen und Anleitungen, wie diese Cookies gelöscht oder deren Speicherung vorab blockiert werden können. Je nach Anbieter Ihres Browsers finden Sie die notwendigen Informationen unter den nachfolgenden Links: </p>
                <ul>
                  <li>Mozilla Firefox: https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen </li>
                  <li>Internet Explorer: https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies </li>
                  <li>Google Chrome: https://support.google.com/accounts/answer/61416?hl=de </li>
                  <li>Opera: http://www.opera.com/de/help </li>
                  <li>Safari: https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE  </li>
                </ul>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Speicherdauer und eingesetzte Cookies: </h3>
                <p>Soweit Sie uns durch Ihre Browsereinstellungen oder Zustimmung die Verwendung von Cookies erlauben, können folgende Cookies auf unseren Webseiten zum Einsatz kommen: </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Technisch notwendige Cookies </h3>
                <h4>Art und Zweck der Verarbeitung:</h4>
                <p>Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten. Einige Elemente unserer Internetseite erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert werden kann.  </p>
                <p>Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Websites für die Nutzer zu vereinfachen. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird. </p>
                <p>Für folgende Anwendungen benötigen wir Cookies: </p>
                <p>Übernahme von Spracheinstellungen </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Rechtsgrundlage und berechtigtes Interesse:</h3>
                <p>Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an einer nutzerfreundlichen Gestaltung unserer Website. </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Empfänger: </h3>
                <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Website als Auftragsverarbeiter tätig werden.  </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Drittlandtransfer: </h3>
                <p>Die erhobenen Daten werden ggfs. in folgende Drittländer übertragen:
                  <p>Nein </p>
                  <p>Folgende Datenschutzgarantien liegen vor: </p>
                </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Bereitstellung vorgeschrieben oder erforderlich: </h3>
                <p>Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne diese Daten ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein. </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Widerspruch </h3>
                <p>Lesen Sie dazu die Informationen über Ihr Widerspruchsrecht nach Art. 21 DSGVO weiter unten.  </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Technisch nicht notwendige Cookies </h3>
                <p>Des Weiteren setzen wir Cookies ein, um das Angebot auf unserer Website besser auf die Interessen unserer Besucher abzustimmen oder auf Basis statistischer Auswertungen allgemein zu verbessern. </p>
                <p>Welche Anbieter Cookies setzen, entnehmen Sie bitte den unten aufgeführten Informationen zu den eingesetzten Darstellungs-, Tracking-, Remarketing- und Webanalyse-Technologien. </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Rechtsgrundlage:  </h3>
                <p>Rechtsgrundlage für diese Verarbeitungen ist jeweils Ihre Einwilligung, Art. 6 Abs. 1 lit. a DSGVO. </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Empfänger: </h3>
                <p>Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Website als Auftragsverarbeiter tätig werden.Weitere Empfänger entnehmen Sie bitte den unten aufgeführten Informationen zu den eingesetzten Darstellungs-, Tracking-, Remarketing- und Webanalyse-Technologien.  </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Drittlandtransfer:  </h3>
                <p>Informationen hierzu entnehmen Sie bitte aus den Auflistungen der einzelnen Darstellungs-, Tracking-, Remarketing- und Webanalyse-Anbietern. </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Bereitstellung vorgeschrieben oder erforderlich:  </h3>
                <p>Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies betrachten. Webbrowser sind regelmäßig so eingestellt, dass sie Cookies akzeptieren. Im Allgemeinen können Sie die Verwendung von Cookies jederzeit über die Einstellungen Ihres Browsers deaktivieren (siehe Widerruf der Einwilligung). </p>
                <p>Bitte beachten Sie, dass einzelne Funktionen unserer Website möglicherweise nicht funktionieren, wenn Sie die Verwendung von Cookies deaktiviert haben. </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Widerruf der Einwilligung: </h3>
                <p>Sie können Ihre Einwilligung jederzeit über unser Cookie-Consent-Tool widerrufen.</p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Profiling:  </h3>
                <p>Inwiefern wir das Verhalten von Websitebesuchern mit pseudonymisierten Nutzerprofilen analysieren, entnehmen Sie bitte den unten aufgeführten Informationen zu den eingesetzten Darstellungs-, Tracking-, Remarketing- und Webanalyse-Technologien. </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Kontaktformular :  </h3>
                <h4>Art und Zweck der Verarbeitung: </h4>
                <p>Die von Ihnen eingegebenen Daten werden zum Zweck der individuellen Kommunikation mit Ihnen gespeichert. Hierfür ist die Angabe einer validen E-Mail-Adresse sowie Ihres Namens erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional. </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Rechtsgrundlage: </h3>
                <p>=Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt auf der Grundlage eines berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO). </p>
                <p>Durch Bereitstellung des Kontaktformulars möchten wir Ihnen eine unkomplizierte Kontaktaufnahme ermöglichen. Ihre gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert. </p>
                <p>Sofern Sie mit uns Kontakt aufnehmen, um ein Angebot zu erfragen, erfolgt die Verarbeitung der in das Kontaktformular eingegebenen Daten zur Durchführung vorvertraglicher Maßnahmen (Art. 6 Abs. 1 lit. b DSGVO). </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Empfänger:  </h3>
                <p>Empfänger der Daten sind ggf. Auftragsverarbeiter. </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Drittlandtransfer: </h3>
                <p>Folgende Datenschutzgarantien liegen vor:Auftragverarbeitungsvertrag </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Speicherdauer: </h3>
                <p>Daten werden spätestens 6 Monate nach Bearbeitung der Anfrage gelöscht. Sofern es zu einem Vertragsverhältnis kommt, unterliegen wir den gesetzlichen Aufbewahrungsfristen nach HGB und löschen Ihre Daten nach Ablauf dieser Fristen. </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Bereitstellung vorgeschrieben oder erforderlich: </h3>
                <p>Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig. Wir können Ihre Anfrage jedoch nur bearbeiten, sofern Sie uns Ihren Namen, Ihre E-Mail-Adresse und den Grund der Anfrage mitteilen.  </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Verwendung von Google Analytics  </h3>
                <p>Soweit Sie ihre Einwilligung gegeben haben, wird auf dieser Website Google Analytics eingesetzt, ein Webanalysedienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA (nachfolgend: „Google“). Google Analytics verwendet sog. „Cookies“, also Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Aufgrund der Aktivierung der IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
                  Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter https://www.google.com/analytics/terms/de.html und unter https://policies.google.com/?hl=de.
                </p>
                <p>Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen.
                  Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B. User-ID) oder Werbe-IDs verknüpften Daten werden nach 14 Monaten automatisch gelöscht. Die Löschung von Daten, deren Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im Monat.
                </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Widerruf der Einwilligung:  </h3>
                <p>Sie können das Tracking durch Google Analytics auf unserer Website unterbinden, indem Sie diesen Link anklicken. Dabei wird ein Opt-out-Cookie auf Ihrem Gerät installiert. Damit wird die Erfassung durch Google Analytics für diese Website und für diesen Browser zukünftig verhindert, solange das Cookie in Ihrem Browser installiert bleibt. </p>
                <p>Sie können darüber hinaus die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.</p>
                <p>Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: Browser Add On zur Deaktivierung von Google Analytics. </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Google AdWords  </h3>
                <p>Unsere Website nutzt das Google Conversion-Tracking. Betreibergesellschaft der Dienste von Google AdWords ist die Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Sind Sie über eine von Google geschaltete Anzeige auf unsere Webseite gelangt, wird von Google Adwords ein Cookie auf Ihrem Rechner gesetzt. Das Cookie für Conversion-Tracking wird gesetzt, wenn ein Nutzer auf eine von Google geschaltete Anzeige klickt. </p>
                <p>Besucht der Nutzer bestimmte Seiten unserer Website und das Cookie ist noch nicht abgelaufen, können wir und Google erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde. Jeder Google AdWords-Kunde erhält ein anderes Cookie. Cookies können somit nicht über die Websites von AdWords-Kunden nachverfolgt werden. Die mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu, Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich für Conversion-Tracking entschieden haben. Die Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen. </p>
                <p>Nähere Informationen über die Datenverarbeitung durch Google können Sie den Google-Datenschutzhinweisen entnehmen: https://policies.google.com/privacy. Dort können Sie im Datenschutzcenter auch Ihre persönlichen Datenschutz-Einstellungen verändern. </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Widerruf der Einwilligung:  </h3>
                <p>Vom Anbieter wird derzeit keine Möglichkeit für einen einfachen Opt-out oder ein Blockieren der Datenübertragung angeboten. Wenn Sie eine Nachverfolgung Ihrer Aktivitäten auf unserer Website verhindern wollen, widerrufen Sie bitte im Cookie-Consent-Tool Ihre Einwilligung für die entsprechende Cookie-Kategorie oder alle technisch nicht notwendigen Cookies und Datenübertragungen. In diesem Fall können Sie unsere Website jedoch ggfs. nicht oder nur eingeschränkt nutzen. </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>SSL-Verschlüsselung </h3>
                <p>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS. </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO Einzelfallbezogenes Widerspruchsrecht </h3>
                <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO. </p>
                <p>Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Empfänger eines Widerspruchs </h3>
                <p>info@trustspace.io </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Änderung unserer Datenschutzbestimmungen  </h3>
                <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung. </p>
              </div>
              <div className='term-content db gts gp-text18 fwl tl-mt20'>
                <h3>Fragen an den Datenschutzbeauftragten </h3>
                <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation: info@trustspace.io </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default PrivacyPolicy
