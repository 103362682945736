import React from 'react'

import CTAButton from './CTAButton'
import RequestADemo from './RequestADemo'
import SubscribeDemo from './SubscribeDemo'
function Cta ({ cta }) {
  if (cta.Component === 'RequestADemo') {
    const props = cta.props || {}
    return <RequestADemo {...props} />
  }
  if (cta.Component === 'subscribeDemo') {
    const props = cta.props || {}
    return <SubscribeDemo {...props} />
  }
  return <CTAButton {...cta} />
}

export default Cta
